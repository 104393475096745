import React, { useCallback, useEffect, useState } from "react";
import jQuery from "jquery";
import { DataGrid, GridToolbarContainer, GridToolbarQuickFilter } from "@mui/x-data-grid";
import { AJAX_ENDPOINT, ROLE_ADMIN, reclone } from "../commons/Constants";
import { useGlobalState } from "../store/state";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Button, useMediaQuery, useTheme } from "@mui/material";
import { AnsweredQuestionnaire } from "../datasource/AnsweredQuestionnaire";
import { async } from "q";
import { Questionaries } from "../datasource/Questionaries";
import moment from "moment";



const CustomToolbar = (props) => {
  return (
    <div style={{ padding: "10px" }}>
      <GridToolbarContainer sx={{ justifyContent: "space-between" }}>
        <GridToolbarQuickFilter placeholder="Cerca..." />
      </GridToolbarContainer>
    </div>
  );
};



function QuestionnaireList(props) {
  const [questionaries, setQuestionaries] = useState([]);
  const [combinedArray, setCombinedArray] = useState([]);
  const [allQuestionary, setAllQuestionary] = useState([]);
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState("");
  const [userSelected, setUserSelected] = useState(1);
  const [userAnswers, setUserAnswers] = useState([]);
  const [questionaryAndNumberUsers, setQuestionaryAndNumberUsers] = useState([]);
  const state = useGlobalState();
  let navigate = useNavigate();


  const apiCall = async () => {
    setLoading(true);
    //SE ADMIN PRENDO TUTTI I QUESTIONARI 
    if (state?.user().accessRole.includes("ADMIN")) {
      const questionari = await Questionaries.getAll(); // Ottieni tutti i questionari
      const questionariWithUsers = await Promise.all(questionari.map(async (questionario) => {
        const usersIds = await AnsweredQuestionnaire.getByIdQuestionary(questionario.id); // Ottieni gli ID utenti per il questionario corrente
        return {
          id: questionario.id,
          nomeQuestionario: questionario.nomeQuestionario,
          descrizioneTesto: questionario.descrizioneTesto,
          numUtenti: usersIds.length // Numero di utenti per il questionario corrente
        };
      }));
      console.log("questionariWithUsers", questionariWithUsers)
      setQuestionaries(questionariWithUsers);
      setLoading(false);

      // SE USER PRENDO I QUESTIONARI CHE HA RISPOSTO L'UTENTE
    } else {
      AnsweredQuestionnaire.get(state?.user().wpUserId)
        .then(async (data) => {
          const response = await Questionaries.getAll()

          const combinedArray = data.map(answeredQuestionnaire => {
            const matchingQuestionary = response.find(questionary => questionary.id === answeredQuestionnaire.wpIdQuestionary);
            return {
              id: answeredQuestionnaire.id,
              nomeQuestionario: matchingQuestionary.nomeQuestionario,
              descrizioneTesto: matchingQuestionary.descrizioneTesto,
              questionJson: answeredQuestionnaire.questionJson,
              dateTaken: moment(answeredQuestionnaire.dateTaken).format("DD/MM/YYYY HH:MM")
            };
          });
          setCombinedArray(combinedArray);
          setLoading(false);
        });
    }
  };


  const selezionaUtente = (answers) => {
    console.log("risposte ", answers.questionJson);
    const answersWithId = answers?.questionJson.map((answer, index) => ({
      ...answer,
      id: index + 1, // Aggiungi un id unico basato sull'indice della riga
    }));
    setUserAnswers(answersWithId);
    setUserSelected(2);
    setTitle(answers.nomeQuestionario);
  }

  const goBack = () => {
    setUserSelected(1);
  }

  useEffect(() => {
    apiCall();
  }, []);

  const theme = useTheme();
  const matches850 = useMediaQuery('(min-width:850px)');
  const matches500 = useMediaQuery('(max-width:500px)');
  const columnVisibilityModel = () => {
    if (!matches850) {
      return {
        descrizioneTesto: false,
        id: false
      };
    } else {
      return {
        descrizioneTesto: true,
        id: true
      }
    }
  }





  const gridAdminAndUser = () => {
    //SE ADMIN, ALLORA VEDE I QUESTIONARI COMPILATI DI TUTTI GLI UTENTI
    if (questionaries && state?.user().accessRole.includes("ADMIN")) {
      return (
        <>

          <h4>Lista questionari utenti compilati</h4>
          <div className="dataGridResponsive">
            <DataGrid
              onRowClick={(params) => {
                const selectedId = params.row.id;
                const title = params.row.nomeQuestionario;
                const numUtenti = params.row.numUtenti;
                if (numUtenti > 0) {
                  navigate(`/usersAnswered/${selectedId}/${title}`);
                } /* else {
                  navigate(`/questionnaire/list/${selectedId}`);
                }*/
              }}
              sortModel={[
                {
                  field: 'numUtenti',
                  sort: 'desc',
                },
              ]}
              getRowClassName={(params) => {
                const numUtenti = params.row.numUtenti;
                if (numUtenti > 0) {
                  return 'pointer-cursor';
                }

              }}
              loading={loading}
              rows={questionaries}
              columns={columns}
              pageSize={5}
              rowsPerPageOptions={[5]}
              slots={{ toolbar: CustomToolbar }}
              columnVisibilityModel={columnVisibilityModel()}
            />
          </div>
        </>
      );
      //ALTRIMENTI E' UN USER E VEDE I SUOI QUESTIONARI COMPILATI
    } else if (!state?.user().accessRole.includes("ADMIN") && userSelected === 1 && combinedArray.length > 0) {

      return (<>

        <h4>I tuoi questionari compilati</h4>
        <h6>Fai click su una riga per consultare</h6>
        <div className="dataGridResponsive">
          <DataGrid
            onRowClick={(params) => {
              const answerUser = params.row;
              selezionaUtente(answerUser);
            }}
            rows={combinedArray}
            columns={columsByUser}
            pageSize={5}
            loading={loading}
            rowsPerPageOptions={[5]}
            getRowClassName={(params) => {
              return 'pointer-cursor';
            }}
            columnVisibilityModel={columnVisibilityModel()}
          />
        </div>
      </>);
      //SE UTENTE USER CLICCA SU UN SUO QUESTIONARIO COMPILATO VISUALIZZA TABELLA CON LE RISPOSTE DATE
    } else if (!state?.user().accessRole.includes("ADMIN") && userSelected === 2) {
      return (
        <>
          <h4>Risposte al questionario: '{title}' </h4>
          <div className="dataGridOptionResponsive">
            <DataGrid
              rows={userAnswers}
              columns={columsAnswersUser}
              sortModel={[
                {
                  field: 'id',
                  sort: 'asc',
                },
              ]}
              pageSize={5}
              loading={loading}
              rowsPerPageOptions={[5]}
              columnVisibilityModel={columnVisibilityModel()} />


          </div>
          <div style={{ marginTop: "15px" }}>
            <Button
              variant="outlined"
              color="primary"
              className="btn btn-success"
              onClick={() => goBack()}
            >
              indietro
            </Button>
          </div>

        </>
      )//ALTRIMENTI USER NON HA QUESTIONARI COMPILATI E VISUALIZZA MESSAGGIO CHE LO INVITA A COMPILARNE UNO
    } else if (!state?.user().accessRole.includes("ADMIN") && combinedArray.length === 0) {
      return (
        <>
          <h4 className="userEmptyResponsive">{state?.user().nome}, non hai ancora compilato nessun questionario</h4>

          < Button
            variant="outlined"
            color="primary"
            className="btn-questionaries questionaries-button"
            onClick={() => navigate("/questionnaire")}
            style={{ backgroundColor: '#8376a6 !important', color: 'white' }}
          >
            compila questionario
          </Button>
        </>

      );
    }
  }

  const columns = [
    { field: "id", headerName: "ID", width: 70 },
    {
      field: "nomeQuestionario", headerName: "Nome Questionario", width: matches500 ? 190 : 270, renderCell: (params) => (
        <b>{params.row.nomeQuestionario}</b>)
    },
    { field: "descrizioneTesto", headerName: "Descrizione", width: 300 },
    { field: "numUtenti", headerName: "N. utenti", width: 130 },
  ];

  const columsByUser = [
    { field: "id", headerName: "ID", width: 70 },
    {
      field: "nomeQuestionario", headerName: "Nome Questionario", width: matches500 ? 170 : 270, renderCell: (params) => (
        <b>{params.row.nomeQuestionario}</b>)
    },
    { field: "descrizioneTesto", headerName: "Desrcizione", width: 270 },
    { field: "dateTaken", headerName: "Data", width: 150 },
  ];

  const columsAnswersUser = [
    { field: "id", headerName: "ID", width: 70 },
    {
      field: "questiontext", headerName: "Domanda", width: matches500 ? 170 : 300, renderCell: (params) => (
        <b>{params.row.questiontext}</b>)
    },
    { field: "answer", headerName: "Risposta", width: matches500 ? 120 : 300, },
  ];


  return (/* VISUALIZZO LE TABELLE DEI QUESTIONARI IN BASE AL TIPO RUOLO */
    <div /* style={{ height: "auto", width: "100%%", marginBottom: '80px' }} */>
      {gridAdminAndUser()}
    </div>
  )

}

export default QuestionnaireList;
