import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import React, { useEffect, useState } from "react";
import jQuery from "jquery";
import { AJAX_ENDPOINT, reclone } from "../commons/Constants";
import { merge, clone, union } from "lodash";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import it from "date-fns/locale/it";
import { DatePicker } from "@mui/x-date-pickers";
import Moment from "moment";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import "yup-phone-lite";
import { useGlobalState } from "../store/state";
import { Button, useMediaQuery, useTheme } from "@mui/material";
import { User } from "../datasource/User";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { Positions } from "../datasource/Positions";

function CVFormAnagrafica(props) {
  // const [count, setCount] = React.useState(0);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up(736));
  const [uinfo, setUInfo] = useState(null);

  const state = useGlobalState();

  const [positions, setPositions] = useState([]);

  const getPositions = async () => {
    Positions.getAll().then((resp) => setPositions(resp));
  };
  const validationSchema = Yup.object().shape({
    /*     nome: Yup.string().required("Il nome è richiesto"),
    cognome: Yup.string().required("Il cognome è richiesto"),
    cap: Yup.number()
      .required("Il CAP è richiesto")
      .typeError("Il CAP deve essere numerico"),
    citta: Yup.string().required("La città è richiesta"), */
    tel: Yup.string()
      .phone("IT", true, "${path} non è un numero di telefono valido")
      .required("Il tel è richiesto"),
    /*     email: Yup.string()
      .required("L'email è richiesta")
      .email("Email non valida"),
    nazionalita: Yup.string().required("La nazionalità è richiesta"),
    posizione: Yup.string().required("La posizione è richiesta"), */

    /*username: Yup.string()
      .required('Username is required')
      .min(6, 'Username must be at least 6 characters')
      .max(20, 'Username must not exceed 20 characters'),
    email: Yup.string()
      .required('Email is required')
      .email('Email is invalid'),
    password: Yup.string()
      .required('Password is required')
      .min(6, 'Password must be at least 6 characters')
      .max(40, 'Password must not exceed 40 characters'),
    confirmPassword: Yup.string()
      .required('Confirm Password is required')
      .oneOf([Yup.ref('password'), null], 'Confirm Password does not match'),
    acceptTerms: Yup.bool().oneOf([true], 'Accept Terms is required')*/
  });

  const {
    register,
    control,
    trigger,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "all",
    resolver: yupResolver(validationSchema),
  });

  const apiCall = () => {
    //  setCount(2);
    state.getUser();
  };

  useEffect(() => {
    setUInfo(reclone(state?.user()));
  }, [state.user.value]);

  const apiCallSave = async () => {
    //  setCount(2);
    try {
      Positions.get(uinfo.posizioneRicopertaId).then((pos) => {
        state.setPosizione(pos.nome);
      });
      /* const r = await User.putTelephone(uinfo.tel, uinfo.wpUserId); */
      state.getUser();
      props.onMessage("Salvataggio effettuato", "success");
    } catch (e) {
      console.error(e);
      props.onCardSkimPrev(document.querySelector("#skimprev-esplav"));
      props.onMessage("Errore nel salvataggio", "error");
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    trigger();
    handleSubmit(
      (data, evt) => {
        props.onCardSkim(e.target);
        apiCallSave();
      },
      (errors, evt) => {
        console.log(errors);
      }
    )();
  };
  console.log(matches);
  useEffect(() => {
    apiCall();
    getPositions();
  }, []);

  return (
    <div className="JDKReactApp-header visible">
      <section
        className={matches ? "formcontainer customscrollbar" : "formcontainer"}
      >
        <h3>Controlla le tue informazioni anagrafiche</h3>
        {uinfo ? (
          <Box
            component="form"
            sx={{
              "& .MuiTextField-root": { m: 1, width: "25ch" },
            }}
            autoComplete="off"
          >
            <TextField
              name="nome"
              label="Nome"
              InputProps={{
                readOnly: true,
              }}
              defaultValue={uinfo?.nome}
              {...register("nome")}
              error={errors.nome ? true : false}
              helperText={errors.nome?.message}
              onChange={(e) => {
                var o = clone(uinfo);
                merge(o, { nome: e.target.value });
                console.log(o);
                setUInfo(o);
              }}
            />

            <TextField
              name="cognome"
              label="Cognome"
              InputProps={{
                readOnly: true,
              }}
              defaultValue={uinfo?.cognome}
              {...register("cognome")}
              error={errors.cognome ? true : false}
              helperText={errors.cognome?.message}
              onChange={(e) => {
                var o = clone(uinfo);
                merge(o, { cognome: e.target.value });
                //console.log(o);
                setUInfo(o);
              }}
            />

            <TextField
              InputProps={{
                readOnly: true,
              }}
              name="cap"
              label="C.A.P"
              type="text"
              defaultValue={uinfo?.cap?.toString()}
              {...register("cap")}
              error={errors.cap ? true : false}
              helperText={errors.cap?.message}
              placeholder="00100"
              onChange={(e) => {
                var o = clone(uinfo);
                merge(o, { cap: e.target.value });
                //console.log(o);
                setUInfo(o);
              }}
            />

            <TextField
              InputProps={{
                readOnly: true,
              }}
              name="citta"
              label="Città"
              defaultValue={uinfo?.citta?.toString()}
              {...register("citta")}
              error={errors.citta ? true : false}
              helperText={errors.citta?.message}
              placeholder="Roma"
              onChange={(e) => {
                var o = clone(uinfo);
                merge(o, { citta: e.target.value });
                //console.log(o);
                setUInfo(o);
              }}
            />

            <TextField
              InputProps={{
                readOnly: true,
              }}
              name="tel"
              label="Telefono"
              defaultValue={uinfo?.tel?.toString()}
              {...register("tel")}
              error={errors.tel ? true : false}
              helperText={errors.tel?.message}
              placeholder=""
              onChange={(e) => {
                var o = clone(uinfo);
                merge(o, { tel: e.target.value });
                //console.log(o);
                setUInfo(o);
              }}
            />
            <TextField
              InputProps={{
                readOnly: true,
              }}
              name="email"
              label="Email"
              defaultValue={uinfo?.email?.toString()}
              {...register("email")}
              error={errors.email ? true : false}
              helperText={errors.email?.message}
              placeholder=""
              onChange={(e) => {
                var o = clone(uinfo);
                merge(o, { email: e.target.value });
                //console.log(o);
                setUInfo(o);
              }}
            />

            <LocalizationProvider locale={it} dateAdapter={AdapterMoment}>
              <DatePicker
                label="Data di nascita"
                name="data_nascita"
                readOnly
                maxDate={Moment(new Date())}
                onChange={(newValue) => {
                  var o = clone(uinfo);
                  merge(o, {
                    dataNasc: newValue.format("YYYY-MM-DD"),
                  });
                  //console.log(o);
                  setUInfo(o);
                }}
                value={Moment(uinfo?.dataNasc)}
                textField={(params) => (
                  <TextField {...params} helperText={null} />
                )}
              />
            </LocalizationProvider>

            <TextField
              InputProps={{
                readOnly: true,
              }}
              name="nazionalita"
              label="Nazionalità"
              defaultValue={uinfo?.nazionalita?.toString()}
              {...register("nazionalita")}
              error={errors.nazionalita ? true : false}
              helperText={errors.nazionalita?.message}
              placeholder="Italiana"
              onChange={(e) => {
                var o = clone(uinfo);
                merge(o, { nazionalita: e.target.value });
                //console.log(o);
                setUInfo(o);
              }}
            />

            <FormControl  className={"sexSelect"}>
              <InputLabel htmlFor="Posizione">Posizione ricoperta</InputLabel>
              <Select
                value={uinfo.posizioneRicopertaId}
                sx={{ minWidth: "300px" }}
                
                label="Posizione"
                readOnly
                inputProps={{
                  name: "posizione",
                  id: "posizione",
                }}
              >
                {positions?.map((el, i) => (
                  <MenuItem key={i} value={el.id}>
                    {el.nome}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl className={"sexSelect"} margin="normal">
              <InputLabel id="sesso_label">Sesso</InputLabel>
              <Select
                sx={{ minWidth: matches ? "100px": "300px" }}
                labelId="sesso_label"
                value={uinfo?.sesso}
                readOnly
                label="Sesso"
                onChange={(e) => {
                  var o = clone(uinfo);
                  merge(o, { sesso: e.target.value });
                  //console.log(o);
                  setUInfo(o);
                }}
              >
                <MenuItem value={"Maschile"}>Maschile</MenuItem>
                <MenuItem value={"Femminile"}>Femminile</MenuItem>
                <MenuItem value={"Altro"}>Altro</MenuItem>
              </Select>
            </FormControl>

            <nav>
              <Button
                id="skimnext-formanagrafica"
                variant="outlined"
                color="primary"
                disabled={
                  Object.keys(errors).length === 0 &&
                  errors.constructor === Object
                    ? false
                    : true
                }
                onClick={onSubmit}
              >
                Avanti
              </Button>
            </nav>
          </Box>
        ) : (
          <div>Carico...</div>
        )}
      </section>
    </div>
  );
}

export default CVFormAnagrafica;
