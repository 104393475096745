import axios from "axios";
import {
  AJAX_ENDPOINT,
  FAKE_USER_ID,
  getHeaders,
  reclone,
} from "../commons/Constants";

export class Roles {
  static async get(id) {
    const HEADERS = getHeaders();
    const resp = await axios.get(AJAX_ENDPOINT + "inet-jdk/role/get/" + id, {
      headers: HEADERS,
    });
    if (resp.data.sesso === "M") {
      resp.data.sesso = "Maschile";
    }
    return resp.data;
  }

  static async put(role) {
    const HEADERS = getHeaders();
    role = reclone(role);
    let resp;
    if (role.id < 1) {
      delete role.id;
      resp = await axios.post(AJAX_ENDPOINT + "inet-jdk/role/create", role, {
        headers: HEADERS,
      });
    } else {
      resp = await axios.put(
        AJAX_ENDPOINT + "inet-jdk/role/update/" + role.id,
        role,
        {
          headers: HEADERS,
        }
      );
    }
    return resp;
  }

  static async delete(id) {
    const HEADERS = getHeaders();
    const resp = await axios.delete(
      AJAX_ENDPOINT + "inet-jdk/role/deleteById/" + id,
      {
        headers: HEADERS,
      }
    );
    return resp;
  }
}
