import axios from "axios";
import {
  AJAX_ENDPOINT,
  FAKE_USER_ID,
  getHeaders,
  reclone,
} from "../commons/Constants";
import { clone } from "lodash";

export class CVInfoAgency {
  static async getInfoById(id) {
    const HEADERS = getHeaders();
    const resp = await axios.get(
      AJAX_ENDPOINT + "info-agencies/getById/" + id,
      {
        headers: HEADERS,
      }
    );
    if (resp.data.sesso === "M") {
      resp.data.sesso = "Maschile";
    }
    return resp.data;
  }

  /*   static async getByName(id) {
    const resp = await axios.get(AJAX_ENDPOINT + 'inet-jdk/info-agencies/by-nome/' + id);
    if (resp.data.sesso === "M") {
      resp.data.sesso = "Maschile";
    }
    return resp.data;
  }
 */
  static async put(infoAgency) {
    const HEADERS = getHeaders();
    if (infoAgency !== undefined) {
      infoAgency = reclone(infoAgency);
    }
    let resp;
    if (infoAgency.id) {
      resp = await axios.put(
        AJAX_ENDPOINT + "info-agencies/update/" + infoAgency.id,
        infoAgency,
        {
          headers: HEADERS,
        }
      );
    } else {
      delete infoAgency.id;
      resp = await axios.post(
        AJAX_ENDPOINT + "info-agencies/create",
        infoAgency,
        {
          headers: HEADERS,
        }
      );
      const responseData = resp.data;
      const idSin = responseData?.id;
      // Call getInfoById with the newly obtained id
      const newRecord = await this.getInfoById(idSin);
      console.log("idSin, newRecord", idSin, newRecord)
      return idSin;
    }
    return resp;
  }

  static async delete(id) {
    const HEADERS = getHeaders();
    const resp = await axios.delete(
      AJAX_ENDPOINT + "info-agencies/deleteById/" + id,
      {
        headers: HEADERS,
      }
    );
    return resp;
  }
}
