import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import React, { useEffect, useState } from "react";
import { AJAX_ENDPOINT, reclone } from "../commons/Constants";
import { merge, clone } from "lodash";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useGlobalState } from "../store/state";
import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  Slider,
  Typography,
} from "@mui/material";
import { User } from "../datasource/User";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import AvatarEditor from "react-avatar-editor";

function ModificaProfilo({ onMessage, setIsModified, tel, id, matches, citta, cap }) {
  const [loading, setLoading] = useState(false);
  const [avatar, setAvatar] = useState(null);
  const [editor, setEditor] = useState(null);
  const [scale, setScale] = useState(1);

  const state = useGlobalState();

  const validationSchema = yup.object({
    telefono: yup
      .string()
      .required("Il telefono è richiesto")
      .transform((value, originalValue) => {
        // Rimuovi il prefisso +39 se presente
        return originalValue.startsWith("+39")
          ? originalValue.substring(3)
          : originalValue;
      })
      .matches(/^[0-9]+$/, "Il numero di telefono deve contenere solo numeri"),

    cap: yup
      .string()
      .required("Il cap è richiesto")
      .matches(/^[0-9]+$/, 'Il cap deve contenere solo numeri'),

    citta: yup
      .string()
      .required("La citta è richiesta")
      .matches(/^[a-zA-Z\s]+$/, 'La città deve contenere solo lettere'),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setAvatar(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSave = () => {
    if (editor) {
      const canvas = editor.getImageScaledToCanvas();
      const dataURL = canvas.toDataURL();
      setScale(1);
      setAvatar(dataURL);
    }
  };

  const apiCallSave = async (data) => {
    console.log("data", data.citta);
    setLoading(true);
    try {
      if (avatar) {
        User.putTelephone(data.telefono, id);
        User.putCap(data.cap, id);
        User.putCity(data.citta, id);
        User.putAvatar(avatar, id).then((response) => {
          if (response.status === 200) {
            setLoading(false);
            setIsModified(false);
            onMessage("Salvataggio effettuato", "success");
          }
        });
      } else {
        const resp = await User.putTelephone(data.telefono, id);
        const respCap = await User.putCap(data.cap, id);
        const respCity = await User.putCity(data.citta, id);

        setLoading(false);
        setIsModified(false);
        if (resp.status === 200 && respCap.status === 200 && respCity.status === 200) {
          onMessage("Salvataggio effettuato", "success");
        }
      }
    } catch (e) {
      setLoading(false);
      console.error(e);
      onMessage("Errore nel salvataggio", "error");
    }
  };

  const handleCancel = () => {
    setIsModified(false);
  };

  const onSubmit = (data) => {
    apiCallSave(data);
  };

  /*   useEffect(() => {
    apiCall();
  }, []);
 */
  return (
    <Card style={{ width: "100%", marginBottom: "100px" }}>
      <CardContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3} direction="column" style={{ marginTop: "20px" }}>
            <Grid item xs={12}>
              <Controller
                name="telefono"
                control={control}
                defaultValue={tel || ""}
                render={({ field }) => (
                  <TextField style={{ width: "60%" }}
                    {...field}
                    label="Telefono"
                    variant="outlined"
                    fullWidth
                    error={!!errors.telefono}
                    helperText={errors.telefono?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="citta"
                control={control}
                defaultValue={citta || ""}
                render={({ field }) => (
                  <TextField style={{ width: "60%" }}
                    {...field}
                    label="citta"
                    variant="outlined"
                    fullWidth
                    error={!!errors.citta}
                    helperText={errors.citta?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="cap"
                control={control}
                defaultValue={cap || ""}
                render={({ field }) => (
                  <TextField style={{ width: "60%" }}
                    {...field}
                    label="cap"
                    variant="outlined"
                    fullWidth
                    error={!!errors.cap}
                    helperText={errors.cap?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <h5>Carica la tua immagine del profilo</h5>
              <input
                id="file-upload-label"
                type="file"
                accept="image/*"
                onChange={handleImageChange}
                style={{ display: "none" }}
              />
              <label htmlFor="file-upload-label">
                <Button variant="contained" component="span">
                  Carica
                </Button>
              </label>
              {avatar && (
                <div>
                  <p>Anteprima dell'immagine:</p>
                  <AvatarEditor
                    ref={(ref) => setEditor(ref)}
                    image={avatar}
                    width={matches ? 250 : 200}
                    height={matches ? 250 : 200}
                    border={matches ? 50 : 10}
                    borderRadius={150}
                    color={[255, 255, 255, 0.6]}
                    scale={scale}
                    rotate={0}
                  />
                  <Slider
                    sx={{ width: "90%" }}
                    value={scale}
                    min={1}
                    max={3}
                    step={0.1}
                    onChange={(e, value) => setScale(value)}
                  />
                  <p>Modifica l'immagine altrimenti clicca su salva</p>
                  <Button
                    className="btn-questionaries questionaries-button"
                    onClick={handleSave}
                  >
                    Modifica immagine
                  </Button>
                </div>
              )}
            </Grid>

            <Grid display={"flex"} justifyContent={"center"} item gap={"40px"}>
              <Button
                type="button"
                onClick={handleCancel}
                variant="outlined"
                color="primary"
                disabled={loading}
              >
                Annulla
              </Button>
              <Button
                className="btn-questionaries questionaries-button"
                type="submit"
              >
                {loading ? <CircularProgress size={28} /> : "Salva"}
              </Button>
            </Grid>
          </Grid>
        </form>
      </CardContent>
    </Card>
  );
}

export default ModificaProfilo;
