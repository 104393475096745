import React from "react";
import "./Cards.scss";
import {
  Grid,
  Card,
  CardContent,
  CardActions,
  Button,
  Typography,
  CardMedia,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import DescriptionIcon from "@mui/icons-material/Description";
import PersonIcon from "@mui/icons-material/Person";
import AssessmentIcon from "@mui/icons-material/Assessment";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { Link } from "react-router-dom";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useGlobalState } from "../../../store/state";
import { ROLE_ADMIN, ROLE_USER } from "../../../commons/Constants";

function Cards() {
  const state = useGlobalState();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up(500));
  console.log("utente", state.user()?.accessRole);
  const cardsInfo = [
    {
      title: "Il tuo profilo",
      link: "/user",
      Icon: HomeIcon,
      description: "Aggiorna le tue informazioni personali.",
      admin: false,
      user: true,
    },
    {
      title: "I tuoi documenti",
      link: "/docs/new",
      Icon: DescriptionIcon,
      description:
        "Visualizza e gestisci i tuoi documenti personali e lavorativi.",
      admin: false,
      user: true,
    },
    /* {
      title: "Le nostre news",
      link: "/news",
      Icon: AssessmentIcon,
      description:
        "Rimani informato con le ultime novità e aggiornamenti aziendali.",
      admin: false,
      user:true,
    }, */
    {
      title: "Aggiorna il tuo CV",
      link: "/cv",
      Icon: PersonIcon,
      description: "Mantieni il tuo CV aggiornato.",
      admin: false,
      user: true,
    },
    {
      title: "I tuoi documenti amministrativi",
      link: "/docs",
      Icon: DescriptionIcon,
      description: "Accedi a documenti e registri amministrativi.",
      admin: false,
      user: true,
    },
    {
      title: state?.user()?.accessRole.includes("ADMIN")
        ? "Questionari"
        : "I tuoi questionari",
      link: "/questionnaire",
      Icon: AssessmentIcon,
      description: state?.user()?.accessRole.includes("ADMIN")
        ? "Crea e visualizza questionari."
        : "Compila i tuoi questionari",
      both: true,
    },
    {
      title: "Risposte",
      link: "/questionnaire/list",
      Icon: AssessmentIcon,
      description: "Visualizza le risposte degli utenti.",
      admin: true,
      user: false,
    },
    {
      title: "Crea un utente",
      link: "/register",
      Icon: AssessmentIcon,
      description: "Registra un utente.",
      admin: true,
      user: false,
    },
    {
      title: "Pannello utenti",
      link: "/usersTable",
      Icon: AssessmentIcon,
      description: "Gestisci gli utenti.",
      admin: true,
      user: false,
    },
    {
      title: "Pannello posizioni",
      link: "/positionsTable",
      Icon: AssessmentIcon,
      description: "Gestisci le posizioni.",
      admin: true,
      user: false,
    },
    {
      //questa card è per gli admin e deve essere all'ultimo posto
      title: "Il tuo profilo",
      link: "/user",
      Icon: HomeIcon,
      description: "Aggiorna le tue informazioni personali.",
      admin: true,
      user: false,
    },
    {
      //questa card è solo per gli user
      title: "Le tue risposte",
      link: "/questionnaire/list",
      Icon: AssessmentIcon,
      description: "Visualizza le tue risposte ai questionari.",
      admin: false,
      user: true,
    },
    // Add more cards as needed
  ];

  const filteredCardsInfo = cardsInfo.filter((link) => {
    // mi filtro le card in base al ruolo dell'utente
    if (
      (state.user()?.accessRole.includes("ADMIN") && link.admin) ||
      link.both
    ) {
      return true;
    } else if (
      (!state.user()?.accessRole.includes("ADMIN") && link.user) ||
      link.both
    ) {
      return true;
    }
  });

  console.log("lista cards", filteredCardsInfo);

  const questForAdmin = (card) => {
    if (
      card.title === "Questionari" &&
      state.user()?.accessRole.includes("ADMIN")
    ) {
      state.setQuestForAdmin(false);
      return card.link;
    }
  };

  return (
    <div>
      <Grid
        container
        style={{
          width: "100%",
          gap: "27px",
          justifyContent: "center",
          marginBottom: "70px",
        }}
      >
        {" "}
        {/* Full width grid with custom gap */}
        {filteredCardsInfo.map((card, index) => (
          <Card
            sx={{ minWidth: matches ? 427 : "100%", maxWidth: 427 }}
            key={"card_" + index}
          >
            <CardMedia
              sx={{ height: 115 }}
              image={"/cards/" + card.title + ".png"}
              title={card.title}
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                {card.title}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {card.description} {/* Updated to show description */}
              </Typography>
            </CardContent>
            <CardActions className="btn-container">
              {/* Togliere la condizione quando sarranno pronti i componenti  */}
              {card.title === "I tuoi documenti" ||
              card.title === "I tuoi documenti amministrativi" ? (
                <Button
                  size="small"
                  variant="outlined"
                  color="primary"
                  component={Link}
                  className="btn-avanti"
                  to={card.link}
                  disabled={true}
                >
                  Coming Soon
                </Button>
              ) : (
                <Button
                  size="small"
                  variant="outlined"
                  color="primary"
                  component={Link}
                  className="btn-avanti"
                  to={
                    card.title === "Questionari"
                      ? questForAdmin(card)
                      : card.link
                  }
                >
                  Accedi
                </Button>
              )}
            </CardActions>
          </Card>
        ))}
      </Grid>
    </div>
  );
}

export default Cards;
