import React, { useEffect, useRef, useState } from "react";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import {
  Alert,
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  InputAdornment,
  Snackbar,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Clear } from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import { useNavigate } from "react-router-dom";
import { User } from "../datasource/User";
import moment from "moment";
import { Positions } from "../datasource/Positions";

const CustomToolbar = (props) => {
  return (
    <div style={{ padding: "10px" }}>
      <GridToolbarContainer sx={{ justifyContent: "space-between" }}>
        <GridToolbarQuickFilter placeholder="Cerca..." />
        <Button
          onClick={() => props.openModal(true)}
          className="btn-questionaries questionaries-button"
        >
          Aggiungi posizione
        </Button>
      </GridToolbarContainer>
    </div>
  );
};

export default function PositionsTable() {
  const [tableData, setTableData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [position, setPosition] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [severity, setSeverity] = useState("");

  const fetchData = async () => {
    setLoading(true);
    try {
      const resp = await Positions.getAll();
      setTableData(resp);
      setLoading(false);
    } catch (error) {
      console.error(error.message);
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setError(false);
  };

  const onMessage = (m, s) => {
    setSeverity(s);
    setError(true);
    setErrorMessage(m);
    setTimeout(() => {
      setError(false);
    }, 6000);
  };

  console.log(position, selectedId);

  const handleEditClick = (id, nome) => {
    // Azioni da eseguire quando si fa clic su Modifica
    console.log(`Modifica elemento con id: ${id}`);
    setSelectedId(id);
    setPosition(nome);
    setOpenCreateModal(true);
  };

  const handleDeleteClick = (id, position) => {
    // Azioni da eseguire quando si fa clic su Elimina
    setSelectedId(id);
    setPosition(position);
    setOpenModal(true);
  };

  const handleDeleteConfirm = () => {
    try {
      // Azioni da eseguire quando si conferma l'eliminazione
      Positions.delete(selectedId)
        .then((data) => {
          if (data) {
            fetchData();
            setPosition(null);
            setSelectedId(null);
            setOpenModal(false);
            onMessage("Utente eliminato con successo", "success");
          } else {
            onMessage("Qualcosa è andato storto", "error");
          }
        })
        .catch((err) => {
          onMessage(
            "La posizione non può essere eliminata, perchè attualmente utilizzata da una o più utenze",
            "info"
          );
          setOpenModal(false);
        });
    } catch (error) {
      onMessage(error.message, "error");
      setOpenModal(false);
    }
  };

  const handleDeleteCancel = () => {
    setSelectedId(null);
    setPosition(null);
    setOpenModal(false);
  };

  const handleCreateCancel = () => {
    setSelectedId(null);
    setPosition(null);
    setOpenCreateModal(false);
  };

  const handleCreateConfirm = () => {
    try {
      Positions.post(position).then(() => {
        onMessage("Posizione creata con successo", "success");
        setPosition(null);
        setSelectedId(null);
        setOpenCreateModal(false);
        fetchData();
      });
    } catch (error) {
      onMessage(error.message, "error");
    }
  };

  const handleModifyPosition = () => {
    try {
      Positions.put(position, selectedId).then(() => {
        onMessage("Posizione modificata con successo", "success");
        setPosition(null);
        setSelectedId(null);
        setOpenCreateModal(false);
        fetchData();
      });
    } catch (error) {
      onMessage(error.message, "error");
    }
  };

  const columns = [
    { headerName: "Id", field: "id", width: 70 },
    { headerName: "Nome", field: "nome", width: 200 },
    {
      headerName: " ",
      width: 130,
      filter: false,
      field: "actions",
      sortable: false,
      filterable: false,
      renderCell: (params) => (
        <ButtonGroup variant="outlined">
          <Button
            onClick={() => handleEditClick(params.row.id, params.row.nome)}
          >
            <EditIcon />
          </Button>
          <Button
            onClick={() => handleDeleteClick(params.row.id, params.row.nome)}
          >
            <DeleteIcon />
          </Button>
        </ButtonGroup>
      ),
    },
  ];
  const theme = useTheme();
  const matches500 = useMediaQuery('(max-width:500px)');


  return (
    <div style={{ height: 500, width: "100%" }}>
      <DataGrid
        slots={{ toolbar: CustomToolbar }}
        rows={tableData}
        columns={columns}
        disableColumnMenu
        columnVisibilityModel = {matches500 && {id:false}}
        disableRowSelectionOnClick
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 25,
            },
          },
        }}
        slotProps={{
          toolbar: {
            openModal: setOpenCreateModal,
          },
        }}
        keepNonExistentRowsSelected
        loading={loading}
      />
      <Dialog open={openModal} onClose={handleDeleteCancel}>
        <DialogTitle>Conferma eliminazione</DialogTitle>
        <DialogContent>
          Sei sicuro di voler eliminare <b>{position}</b> ?
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleDeleteCancel}
            variant="outlined"
            color="primary"
          >
            Annulla
          </Button>
          <Button
            onClick={handleDeleteConfirm}
            className="btn-questionaries questionaries-button"
          >
            Elimina
          </Button>
        </DialogActions>
      </Dialog>
      {/*       <Dialog open={openCreateModal} onClose={handleCreateCancel}>
        <DialogTitle>Crea posizione</DialogTitle>
        <DialogContent>
          <TextField 
          fullWidth
          margin="normal"
          label="Nuova posizione"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCreateCancel} color="primary">
            Annulla
          </Button>
          <Button onClick={handleCreateConfirm} color="primary">
            Crea
          </Button>
        </DialogActions>
      </Dialog> */}
      <Dialog open={openCreateModal} onClose={handleCreateCancel}>
        <DialogTitle>
          {selectedId ? "Modifica posizione" : "Crea posizione"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {selectedId
              ? "Qui è possibile modificare la posizione lavorativa. Inserire il nuovo nome.."
              : "Qui è possibile creare una nuova posizione lavorativa. Inserire il nome.."}
          </DialogContentText>
          <TextField
            autoFocus
            defaultValue={position || ""}
            required
            margin="dense"
            id="name"
            name="position"
            label="Nome posizione"
            type="text"
            fullWidth
            variant="standard"
            onChange={(e) => setPosition(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleCreateCancel}>
            Annulla
          </Button>
          <Button
            className="btn-questionaries questionaries-button"
            onClick={() =>
              selectedId ? handleModifyPosition() : handleCreateConfirm()
            }
            disabled={!position}
          >
            {selectedId ? "Modifica" : "Crea"}
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar open={error} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={severity} sx={{ width: "100%" }}>
          {errorMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}
