import React, { useEffect, useRef, useState } from "react";
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import {
    Alert,
    Button,
    ButtonGroup,
    Card,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    InputAdornment,
    Snackbar,
    TextField,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import { Clear } from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { User } from "../datasource/User";
import moment from "moment";
import { useGlobalState } from "../store/state";
import { ROLE_ADMIN, reclone } from "../commons/Constants";
import { QuestionOptions } from "../datasource/QuestionOptions";

const CustomToolbar = (props) => {

    const [openModal, setOpenModal] = useState(false);

    const handleDeleteClick = (options) => {
        console.log("domande da cancellare ", options);
        setOpenModal(true);
    };

    const handleDeleteCancel = () => {
        setOpenModal(false);
    };

    const handleDeleteConfirm = () => {
        try {
            QuestionOptions.deleteByListId(props.rowSelectionModel.join(",")).then(
                (data) => {
                    if (data) {
                        props.apiCall();
                        props.setRowSelectionModel([]);
                        props.onMessage(
                            "Domande selezionate eliminate con successo",
                            "success"
                        );
                    } else {
                        props.onMessage("Qualcosa è andato storto", "error");
                    }
                }
            );
        } catch (error) {
            props.onMessage(error.message, "error");
            console.log("errore eliminazione")
        }
        setOpenModal(false);
    };


    console.log("lista id utenti", props.rowSelectionModel.join(","));
    return (
        <div style={{ padding: "10px" }}>
            <GridToolbarContainer sx={{ justifyContent: "space-between" }}>
                <GridToolbarQuickFilter placeholder="Cerca..." />

                {props?.rowSelectionModel?.length > 0 && (
                    <Button
                        onClick={() => handleDeleteClick(props)}
                        variant="outlined"
                    >
                        Elimina domande selezionate
                    </Button>
                )}
            </GridToolbarContainer>

            <Dialog open={openModal} onClose={handleDeleteCancel}>
                <DialogTitle>Conferma eliminazione</DialogTitle>
                <DialogContent>
                    Sei sicuro di voler eliminare le domande selezionate?
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteCancel} color="primary">
                        Annulla
                    </Button>
                    <Button onClick={handleDeleteConfirm} className="btn-questionaries questionaries-button" style={{ backgroundColor: '#8376a6 !important', color: 'white' }}>
                        Elimina
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};



function OptionList(props) {
    const [options, setOptions] = useState([]);
    const state = useGlobalState();
    const { id } = useParams();
    const [loading, setLoading] = useState(false);
    const [selectedId, setSelectedId] = useState(id);
    const [openModal, setOpenModal] = useState(false);
    const [error, setError] = useState(false);
    const [severity, setSeverity] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [rowSelectionModel, setRowSelectionModel] = useState([]);

    console.log("id questionario: ", id);
    const navigate = useNavigate();


    const apiCall = async () => {
        setLoading(true);
        await state.getQuestionOpt(id);
        let questions = state.questionOpt()
        questions = reclone(questions);
        if (typeof questions === "string") {
            questions = JSON.parse(questions);
        }
        setOptions(questions);
        console.log("la lista domande del questionario sono: ", questions);
        setLoading(false);
    }

    const theme = useTheme();
    const matches1020 = useMediaQuery('(max-width:1020px)');
    const matches715 = useMediaQuery('(max-width:715px)');

    const columns = [
        { field: "orderNum", headerName: "Ordine", width: 90, },
        {
            field: "questiontext", headerName: "Domanda", width: matches1020 && !matches715 ? 350 :(matches715 && matches1020 ? 170 : 230), renderCell: (params) => (
                <b>{params.row.questiontext}
                </b>)
        },
        { field: "questionAnswers", headerName: "Risposte", width: 230, },
        { field: "questionType", headerName: "Tipo", width: 100 },
        { field: "questionGroup", headerName: "Competenze", width: 120, },
        {
            field: "modificaDomanda", headerName: "Modifica", width: 80, renderCell: (params) => (
                <IconButton onClick={() => editOptionQuestionarie(params.row)}>
                    <EditIcon />
                </IconButton>
            ),
        },
        {
            field: "eliminaDomanda", headerName: "Elimina", width: 80, renderCell: (params) => (
                <IconButton onClick={() => handleDeleteClick(params.row)}>
                    <DeleteIcon />
                </IconButton>
            ),
        }
    ];


    
    const columnVisibilityModel = () => {
        if (matches1020 && !matches715) {
          return {
            questionGroup: false,
            questionType: false,
            questionAnswers: false
          };
        } 
        
        if(matches715 && matches1020) {
          return {
            questionGroup: false,
            questionType: false,
            questionAnswers: false,
            orderNum: false,

          }
        }

        return {
            questionGroup: true,
            questionType: true,
            questionAnswers: true,
            orderNum: true,
            eliminaDomanda: true

          }
      }


    const editOptionQuestionarie = async (row) => {
        console.log("il questionario con le domande da modificare è: ", row.id);
        state.setOptionToModify(row);;
        navigate("/editOption/" + row.id);

    };

    const goToAggiungiDomanda = async () => {
        state.setQuestionLenght(options.length);
        navigate("/editOption/" + id);
    };

    const handleDeleteClick = (row) => {
        console.log("id domanda da cancellare ", row.id);
        setSelectedId(row);
        setOpenModal(true);
    };

    const handleDeleteCancel = () => {
        setOpenModal(false);
    };

    const handleDeleteConfirm = () => {
        apiCallDeleteOption();
        setOpenModal(false);
    };

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setError(false);
    };

    const onMessage = (m, s) => {
        setSeverity(s);
        setError(true);
        setErrorMessage(m);
        setTimeout(() => {
            setError(false);
        }, 6000);
    };

    const apiCallDeleteOption = async () => {
        console.log("cancella domanda ", selectedId.questiontext);
        try {
            const response = await QuestionOptions.delete(selectedId.id);
            console.log("response: " + response);

            if (response.status === 200) {
                refreshState();
            } else {
                onMessage("Errore eliminazione domanda", "error");
            }

        } catch (error) {
            console.error("errore elimina domanda " + error);
            onMessage("Errore eliminazione domanda", "error");
        }

    }

    const refreshState = async () => {
        console.log("refreshState");
        await state.getQuestionOpt(id);
        let questions = state.questionOpt()
        questions = reclone(questions);
        if (typeof questions === "string") {
            questions = JSON.parse(questions);
        }
        setOptions(questions);
        console.log("la lista domande del questionario sono: ", questions);
    };

    const backToQuestionaries = () => {
        state.setQuestionary(null);
        navigate("/questionnaire");
    }



    useEffect(() => {
        apiCall();
        state.setOptionToModify(null);
        state.setQuestionLenght(null);
    }, []);

    return (
        <div style={{marginBottom: '100px', }}>
{/*             <Card
                sx={{
                    padding: "127px",
                    paddingLeft: "242px",
                    paddingRight: "242px",
                    minWidth: 427,
                    maxWidth: "92vw",
                }}
            > */}
                <h4> Domande del questionario "{state.questionary().nomeQuestionario}"</h4>
                <div className="dataGridOptionResponsive" >
                    <DataGrid
                        slots={{ toolbar: CustomToolbar }}
                        columnVisibilityModel={columnVisibilityModel()}
                        rows={options}
                        columns={columns}
                        sortModel={[
                            {
                                field: 'orderNum',
                                sort: 'desc',
                            },
                        ]}
                        pageSize={5}
                        loading={loading}
                        rowsPerPageOptions={[5]}
                        checkboxSelection ={matches715 ? false : true}
                        rowSelectionModel={rowSelectionModel}
                        onRowSelectionModelChange={(newRowSelectionModel, details) => {
                            setRowSelectionModel(newRowSelectionModel);
                        }}
                        slotProps={{
                            toolbar: {
                                rowSelectionModel: rowSelectionModel,
                                setRowSelectionModel: setRowSelectionModel,
                                apiCall: apiCall,
                                onMessage: onMessage,
                            },

                        }}
                    >
                    </DataGrid>
                </div>
                <div style={{display: "flex", justifyContent: "space-evenly", marginTop: '15px'}}>
                <Button
                    variant="outlined"
                    color="primary"
                    className="btn btn-success"
                    onClick={() => backToQuestionaries()}
                >
                    Indietro
                </Button>
                &nbsp;
                <Button
                    variant="outlined"
                    color="primary"
                    className="btn btn-success"
                    onClick={() => navigate("/createQuestionnaires")}
                >
                    modifica nome
                </Button>
                &nbsp;
                <Button
                    className="btn-questionaries questionaries-button"
                    style={{ backgroundColor: '#8376a6 !important', color: 'white' }}

                    onClick={() => goToAggiungiDomanda()}
                >
                    aggiungi domanda
                </Button>
</div>


                <Dialog open={openModal} onClose={handleDeleteCancel}>
                    <DialogTitle>Conferma eliminazione</DialogTitle>
                    <DialogContent>
                        Sei sicuro di voler eliminare la domanda <b>'{selectedId.questiontext}'</b>?
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleDeleteCancel} color="primary">
                            Annulla
                        </Button>
                        <Button onClick={handleDeleteConfirm} className="btn-questionaries questionaries-button" style={{ backgroundColor: '#8376a6 !important', color: 'white' }}>
                            Elimina
                        </Button>
                    </DialogActions>
                </Dialog>
            {/* </Card> */}

            <Snackbar open={error} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={severity} sx={{ width: "100%" }}>
                    {errorMessage}
                </Alert>
            </Snackbar>
        </div>
    );
}

export default OptionList;