import axios from "axios";
import {
  AJAX_ENDPOINT,
  FAKE_USER_ID,
  getHeaders,
  reclone,
} from "../commons/Constants";

export class Questionaries {
  static async get(id) {
    const HEADERS = getHeaders();
    const resp = await axios.get(
      AJAX_ENDPOINT + "inet-jdk/questionary/get-questionaries/" + id,
      {
        headers: HEADERS,
      }
    );
    if (resp.data.sesso === "M") {
      resp.data.sesso = "Maschile";
    }
    return resp.data;
  }


  static async getAll(id) {
    const HEADERS = getHeaders();
    const resp = await axios.get(
      AJAX_ENDPOINT + "inet-jdk/questionary/all",
      {
        headers: HEADERS,
      }
    );
    if (resp.data.sesso === "M") {
      resp.data.sesso = "Maschile";
    }
    return resp.data;
  }

  static async put(questionaries) {
    const HEADERS = getHeaders();
    questionaries = reclone(questionaries);
    let resp;
    if (!questionaries.id) {
      delete questionaries.id;
      resp = await axios.post(
        AJAX_ENDPOINT + "inet-jdk/questionary/create",
        questionaries,
        {
          headers: HEADERS,
        }
      );
    } else {
      resp = await axios.put(
        AJAX_ENDPOINT + "inet-jdk/questionary/update/" + questionaries.id,
        questionaries,
        {
          headers: HEADERS,
        }
      );
    }
    return resp;
  }

  static async delete(id) {
    const HEADERS = getHeaders();
   console.log("delete metodo")
   const resp = await axios.delete(AJAX_ENDPOINT + 'inet-jdk/questionary/deleteById/'+ id,
    {
      headers: HEADERS,
    }
   );
   console.log("delete metodo fine")
   return resp;
  }

  static async byIdPosition(id){
    const HEADERS = getHeaders();
    const resp = await axios.get(AJAX_ENDPOINT + 'inet-jdk/questionary/get-questionaries/'+ id,
     {
       headers: HEADERS,
     }
    );
    return resp.data;
  }
}
