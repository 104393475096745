import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import {DatePicker}from "@mui/x-date-pickers";
import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import it from "date-fns/locale/it";
import { convertToHTML } from 'draft-convert';
import { LocalizationProvider } from '@mui/x-date-pickers'
import debounce from 'lodash.debounce';
import Moment from 'moment';
import React, { useCallback, useEffect, useRef, useState } from "react";
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormHelperText from '@mui/material/FormHelperText';
import Checkbox from '@mui/material/Checkbox';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
//import { registerLocale } from 'react-datepicker';
//registerLocale('it', it);

function CVUpdateProjectForm(props) {
  // const [count, setCount] = React.useState(0);
  const [data_inizio, setDataInizio] = useState(null);
  const [data_fine, setDataFine] = useState(null);
  const [lavoro_qui, setLavoroQui] = useState(false);
  const [progetto, setProgetto] = useState(null);
  const [cliente, setCliente] = useState(null);
  const [scopo, setScopo] = useState(null);
  const [tecnologie, setTecnologie] = useState(null);
  const [ruolo, setRuolo] = useState(null);

  const validationSchema = Yup.object().shape({
    progetto: Yup.string().required('Il nome del progetto è richiesto'),
    cliente: Yup.string().required('Il nome del cliente del progetto è richiesto'),
    tecnologie: Yup.string().required('Le tecnologie utilizzate sono richieste'),
    ruolo: Yup.string().required('Il ruolo è richiesto'),
    scopo: Yup.string().required('Lo scopo del progetto è richiesto')


    /*username: Yup.string()
      .required('Username is required')
      .min(6, 'Username must be at least 6 characters')
      .max(20, 'Username must not exceed 20 characters'),
    email: Yup.string()
      .required('Email is required')
      .email('Email is invalid'),
    password: Yup.string()
      .required('Password is required')
      .min(6, 'Password must be at least 6 characters')
      .max(40, 'Password must not exceed 40 characters'),
    confirmPassword: Yup.string()
      .required('Confirm Password is required')
      .oneOf([Yup.ref('password'), null], 'Confirm Password does not match'),
    acceptTerms: Yup.bool().oneOf([true], 'Accept Terms is required')*/
  });

  const {
    register,
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({
    mode:'all',
    resolver: yupResolver(validationSchema)
  });


  useEffect(() => {
   
    setDataInizio(props.o.data_inizio);
    setDataFine(props.o.data_fine);
    setLavoroQui(props.o.lavoro_qui);
    setRuolo(props.o.ruolo);
    setTecnologie(props.o.tecnologie);
    setScopo(props.o.scopo);
    setCliente(props.o.cliente);
    setProgetto(props.o.progetto);


    
  }, [props]);

  return (
      <Box id={'project-'+props.parent.id + '-' + props.i}>
      <FormControl sx={{ m: 3, p: 3, border: 1, broderRadius: 2 }} component="fieldset" variant="standard">
      <FormLabel component="legend">Progetto</FormLabel>
        <FormGroup> 

        {progetto!==null?
      <TextField
        required
        {...register('progetto')}
        error={errors.progetto ? true : false}
        helperText={errors.progetto?.message}
        onBlur={(e)=>props.onProgettoChange(props.i, e.target.value)}
        name="progetto"
        label="Progetto"
        defaultValue={progetto}
      />
:<div></div>}



{cliente!==null?
      <TextField
        required
        {...register('cliente')}
        error={errors.cliente ? true : false}
        helperText={errors.cliente?.message}
        onBlur={(e)=>props.onClienteChange(props.i, e.target.value)}
        name="cliente"
        label="Cliente"
        defaultValue={cliente}
      />
:<div></div>}


        <LocalizationProvider locale={it} dateAdapter={AdapterMoment}>
        <DatePicker
          views={["year", "month"]}
          label="Data di inizio"
          name="data_inizio"
          maxDate={Moment(data_fine,"YYYY-MM-DD").isValid() ? Moment(data_fine,"YYYY-MM-DD") : Moment(new Date())}
          onChange={(newValue) =>{
            props.onDataInizioChange(props.i, newValue);

          }}
          value={Moment(data_inizio)}
          textField={(params) => (
            <TextField {...params} helperText={null} />
          )}
        />

        {! (lavoro_qui ==="1" || lavoro_qui === 1 || lavoro_qui === true || lavoro_qui ==="true") ? (
          <DatePicker
            views={["year", "month"]}
            label="Data di fine"
            name="data_fine"
            minDate={Moment(data_inizio,"YYYY-MM-DD").isValid() ? Moment(data_inizio,"YYYY-MM-DD") : Moment(new Date())}
            maxDate={Moment(new Date())} 
             onChange={(newValue) =>{
              props.onDataFineChange(props.i, newValue);

            }}
            value={Moment(data_fine)}
            textField={(params) => (
              <TextField {...params} helperText={null} />
            )}
          />
          ) : (
          <div></div>
        )}
</LocalizationProvider>
{props.parent.lavoro_qui === "1" || props.parent.lavoro_qui === 1 || props.parent.lavoro_qui === true  ?
<FormControlLabel
          control={
            <Switch
              checked={  (lavoro_qui ==="1" || lavoro_qui === 1 || lavoro_qui === true || lavoro_qui ==="true") ? true : false}
              onChange={(e) => {
               setLavoroQui(e.target.checked);
               props.onLavoroQuiChange(props.i, e.target.checked);

              }}
            />
          }
          label="Attualmente lavoro a questo progetto"
        />
        :<div></div>}

{tecnologie!==null?
      <TextField
        required
        {...register('tecnologie')}
        error={errors.tecnologie ? true : false}
        helperText={errors.tecnologie?.message}
        onBlur={(e)=>props.onTecnologieChange(props.i, e.target.value)}
        name="tecnologie"
        label="Tecnologie utilizzate"
        placeholder="Java, Spring"
        defaultValue={tecnologie}
      />
:<div></div>}



{ruolo!==null?
      <TextField
        required
        {...register('ruolo')}
        error={errors.ruolo ? true : false}
        helperText={errors.ruolo?.message}
        onBlur={(e)=>props.onRuoloChange(props.i, e.target.value)}
        name="ruolo"
        placeholder="Frontend Developer"
        label="Ruolo"
        defaultValue={ruolo}
      />
:<div></div>}

{scopo!==null?
      <TextField
      multiline
        required
        {...register('scopo')}
        error={errors.scopo ? true : false}
        helperText={errors.scopo?.message}
        onBlur={(e)=>props.onScopoChange(props.i, e.target.value)}
        name="scopo"
        label="Scopo del progetto"
        placeholder="Descrivi brevemente lo scopo del progetto"
        defaultValue={scopo}
      />
:<div></div>}

<span className="project-action project-action-offset-left" title="Elimina definitiviamente" onClick={(e)=>{e.preventDefault(); e.stopPropagation(); props.onDeleteProjectAtIndex(props.i);}}><HighlightOffIcon/></span>
      </FormGroup>
      <span className="project-action project-action-offset-right" title="Aggiungi progetto" onClick={(e)=>{e.preventDefault(); e.stopPropagation(); props.onAddProjectAtIndex(props.i);}}><AddCircleIcon color="success"/></span>

      </FormControl>

      </Box>

    

    );
}

export default CVUpdateProjectForm;
