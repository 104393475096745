import React from "react";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import { Avatar, CardHeader } from "@mui/material";
import { Buffer } from 'buffer';
import { useGlobalState } from "../store/state";

const ProfileCard = ({
  imageSrc,
  nome,
  cognome,
  email,
  matches
}) => {
  /*   const [imageUrl, setImageUrl] = useState(null);
  useEffect(() => {
    const blob = new Blob([imageSrc], { type: 'image/png' });
    const url = URL.createObjectURL(blob);
    console.log(url);
    setImageUrl(url);

    // Pulire l'URL quando il componente viene smontato
    return () => {
      URL.revokeObjectURL(url);
    };
  }, [imageSrc]); */
  const state = useGlobalState()
  
  const cleanBase64String = imageSrc.replace(/^\"data:image\/(jpeg|png);base64,/, '');
    
    const convertBase64ToBlob = (base64) => {
      const binaryString = Buffer.from(base64, 'base64').toString('binary');
      const byteArray = new Uint8Array(binaryString.length);
      for (let i = 0; i < binaryString.length; i++) {
        byteArray[i] = binaryString.charCodeAt(i);
      }
      return new Blob([byteArray], { type: 'image/jpeg' });
    };
  
    const blob = convertBase64ToBlob(cleanBase64String);
    const imageUrl = URL.createObjectURL(blob);
  return (
    <>
      <Card sx={{ width: matches ? "400px" : "100%", padding: "15px" }}>
        <CardHeader
          avatar={
            <Avatar
              sx={{ width: 100, height: 100 }}
              alt={`${nome} ${cognome}`}
              src={imageUrl} 
            />
          }
          title={
            <Typography variant="h5" sx={{ marginBottom: "10px" }}>
              {nome} {cognome}
            </Typography>
          }
          subheader={<Typography>{email}</Typography>}
        />
      </Card>
    </>
  );
};

export default ProfileCard;
