import { useMediaQuery, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import ListSubheader from '@mui/material/ListSubheader';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from "@mui/material/TextField";
import React, { useEffect, useState } from "react";

//import { registerLocale } from 'react-datepicker';
//registerLocale('it', it);

function CVUpdateCompetenzeForm(props) {
  // const [count, setCount] = React.useState(0);
  const [competenza, setCompetenza] = useState(null);
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const [elab_liv, setElabLiv] = useState(null);
  const [comunicazione_liv, setComunicazioneLiv] = useState(null);
  const [creazione_liv, setCreazioneLiv] = useState(null);
  const [sicurezza_liv, setSicurezzaLiv] = useState(null);
  const [risoluzione_liv, setRisolzuioneLiv] = useState(null);
  const [certificazione, setCertificazione] = useState(null);



/*
  const [childKey, setChildKey] = useState(0);
  
  useEffect(() => {
    setChildKey(prev => prev + 1);
  },[props.key]); */

  useEffect(() => {
    setCompetenza(props.o.competenza);
   //console.log('useEffect',props.o);
    setElabLiv(props.o.elabLiv);
    setComunicazioneLiv(props.o.comunicazioneLiv);
    setCreazioneLiv(props.o.creazioneLiv);
    setSicurezzaLiv(props.o.sicurezzaLiv);
    setRisolzuioneLiv(props.o.risoluzioneLiv);
    setCertificazione(props.o.certificazione);
   
  }, [props]);

  return (
    <Box
      sx={{
        "& .MuiTextField-root": { m: 1, width: "25ch" },
      }}
      
      autoComplete="off"
    >
     
      {competenza!==null ?
      <TextField
        required
        onChange={(e)=>props.onCompetenzaChange(props.o.id, e.target.value)}
        name="competenza"
        label="Competenza"
        placeholder="Pacchetto Office"
        defaultValue={competenza}
      />
:<div></div>}

<br/>
<h5>Autovalutazione:</h5>
{elab_liv!==null ?
  <FormControl margin="normal" sx={matches ? { m: 1, minWidth: 380 } : {minWidth: "100%"}}>
        <InputLabel htmlFor="grouped-select">Elaborazione dele informazioni</InputLabel>
        <Select
          onChange={(e)=>props.onElabChange(props.o.id, e.target.value)}
        defaultValue={elab_liv} id="grouped-select" label="Elaborazione delle informazioni">
          <MenuItem value={'Base'}>Utente base</MenuItem>
          <MenuItem value={'Medio'}>Utente intermedio</MenuItem>
          <MenuItem value={'Alto'}>Utente avanzato</MenuItem>
        </Select>
      </FormControl>: <div></div> }
     <br/>
      {comunicazione_liv!==null ?

      <FormControl margin="normal" sx={matches ? { m: 1, minWidth: 380 } : {minWidth: "100%"}}>
        <InputLabel htmlFor="grouped-select2">Comunicazione</InputLabel>
        <Select 
          onChange={(e)=>props.onComunicazioneChange(props.o.id, e.target.value)}
        defaultValue={comunicazione_liv} id="grouped-select2" label="Comunicazione">
            <MenuItem value={'Base'}>Utente base</MenuItem>
          <MenuItem value={'Medio'}>Utente intermedio</MenuItem>
          <MenuItem value={'Alto'}>Utente avanzato</MenuItem>
        </Select>
      </FormControl>: <div></div> }
      <br/>
{creazione_liv!==null ?

      <FormControl margin="normal"  sx={matches ? { m: 1, minWidth: 380 } : {minWidth: "100%"}}>
        <InputLabel htmlFor="grouped-select3">Creazione di contenuti</InputLabel>
        <Select 
          onChange={(e)=>props.onCreazioneChange(props.o.id, e.target.value)}
        defaultValue={creazione_liv} id="grouped-select3" label="Creazione di contenuti">
            <MenuItem value={'Base'}>Utente base</MenuItem>
          <MenuItem value={'Medio'}>Utente intermedio</MenuItem>
          <MenuItem value={'Alto'}>Utente avanzato</MenuItem>
        </Select>
      </FormControl>: <div></div> }
<br/>
      {sicurezza_liv!==null ?

      <FormControl margin="normal" sx={matches ? { m: 1, minWidth: 380 } : {minWidth: "100%"}}>
        <InputLabel htmlFor="grouped-select4">Sicurezza</InputLabel>
        <Select   onChange={(e)=>props.onSicurezzaChange(props.o.id, e.target.value)}
        defaultValue={sicurezza_liv} id="grouped-select4" label="Sicruezza">
            <MenuItem value={'Base'}>Utente base</MenuItem>
          <MenuItem value={'Medio'}>Utente intermedio</MenuItem>
          <MenuItem value={'Alto'}>Utente avanzato</MenuItem>
        </Select>
      </FormControl>: <div></div> }
      <br/>

{risoluzione_liv!==null ?

<FormControl margin="normal" sx={matches ? { m: 1, minWidth: 380 } : {minWidth: "100%"}}>
        <InputLabel htmlFor="grouped-select5">Risoluzione di problemi</InputLabel>
        <Select         onChange={(e)=>props.onRisoluzioneChange(props.o.id, e.target.value)}
 defaultValue={risoluzione_liv} id="grouped-select5" label="Risuoluzione di problemi">
         <MenuItem value={'Base'}>Utente base</MenuItem>
          <MenuItem value={'Medio'}>Utente intermedio</MenuItem>
          <MenuItem value={'Alto'}>Utente avanzato</MenuItem>
        </Select>
      </FormControl>: <div></div> }


        { (certificazione !== null || props.o.dummy) ? (
          <div className="mui-rte-holder"><h6>Eventuale certificiazione conseguita:</h6>
         <Box sx={{ width: '75%' }}>
                <TextField
        required
        style ={{width: '100%'}}
        multiline
        onChange={(e)=>props.onCertificazioneChange(props.o.id, e.target.value, e.target.value)}
        name="dettagli"
        label="Inserisci qui il titolo dell'eventuale certificazione"
        defaultValue={certificazione}
      />
      </Box>
<br/>
          <br/>
          </div>
        ) : (
          <div></div>
        )}
    </Box>
  );
}

export default CVUpdateCompetenzeForm;
