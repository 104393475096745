import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import { FAKE_USER_ID, reclone } from "../commons/Constants";
import Box from "@mui/material/Box";
import { useGlobalState } from "../store/state";
import { Questionaries } from "../datasource/Questionaries";
import { useLocation, useNavigate } from "react-router-dom";
import { Positions } from "../datasource/Positions";

function CreateQuestionnaires(props) {
  const state = useGlobalState();
  const [questionary, setQuestionary] = useState([]);
  const [positions, setPositions] = useState([]);
  const [apiError, setApiError] = useState(false);
  const navigate = useNavigate();

  /*   const handleChange = (event) => {
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      setPosizioniScelte(
        posizioniScelte.length === positions.length
          ? []
          : positions.map((pos) => pos.id)
      );
      return;
    } else {
      setPosizioniScelte(event.target.value);
    }
  }; */

  const location = useLocation();
  const questionarytoModify = location?.state;
  console.log(
    "questionario che passo alla pagina di createQuestionaries è: " +
      questionarytoModify?.nomeQuestionario
  );

  const questionaryToEdit = state?.questionary();
  console.log(questionaryToEdit);
  const apiCall = async () => {
    await state.getQuestionaries();
    let questionaries = state.questionaries();
    questionaries = reclone(questionaries);
    setQuestionary(questionaries);
  };
  const fetchData = async () => {
    try {
      const resp = await Positions.getAll();
      setPositions(resp);
    } catch (error) {
      console.error(error.message);
    }
  };

  const apiCallSave = async () => {
    try {
      const questionaries = {
        wpUserId: state.userId(),
        id: questionaryToEdit?.id,
        nomeQuestionario: questionary.nomeQuestionario
          ? questionary.nomeQuestionario
          : questionaryToEdit.nomeQuestionario,
        descrizioneTesto: questionary.descrizioneTesto
          ? questionary.descrizioneTesto
          : questionaryToEdit?.descrizioneTesto || "",
        positionsId:
          questionary?.positionsId ?? questionaryToEdit?.positionsId ?? 1,
      };
      const response = await Questionaries.put(questionaries);
      console.log("create quest ", response);
      state.getQuestionaries();

      if (questionaryToEdit) {
        state.setQuestionary(questionaries);
        navigate(-1);
      } else {
        navigate("/cardQuestion/" + response.data.id);
      }
    } catch (e) {
      console.error(e);
      setApiError(true);
    }
  };

  useEffect(() => {
    apiCall();
    fetchData();
  }, []);

  return (
    <div>
      <h3>
        {questionaryToEdit
          ? "Modifica il questionario: " +
            "'" +
            questionaryToEdit.nomeQuestionario +
            "'"
          : "Crea un questionario"}{" "}
      </h3>
      <section className="formcontainer customscrollbar">
        <Box
          component="form"
          sx={{
            "& .MuiTextField-root": { m: 1, width: "25ch" },
          }}
          autoComplete="off"
        >
          <TextField
            key={questionary.nomeQuestionario}
            onChange={(e) => {
              questionary.nomeQuestionario = e.target.value;
              //questionary.touched = true
            }}
            name="nomeQuestionario"
            label="Nome questionario"
            defaultValue={
              questionaryToEdit
                ? questionaryToEdit.nomeQuestionario
                : questionary.nomeQuestionario
            }
          />
          <TextField
            key={questionary.descrizioneTesto}
            onChange={(e) => {
              questionary.descrizioneTesto = e.target.value;
              questionary.touched = true;
            }}
            name="descrizioneTesto"
            label="Descrizione testo"
            defaultValue={
              questionaryToEdit
                ? questionaryToEdit.descrizioneTesto
                : questionary.descrizioneTesto
            }
            helperText= "opzionale!"
          />
          {/*           <FormControl margin="dense"  fullWidth>
            <InputLabel id="demo-simple-select-label">
              A chi è indirizzato?
            </InputLabel>
            <Select
              multiple
              
              labelId="demo-simple-select-label"
              value={posizioniScelte}
              label="A chi è indirizzato?"
              input={<OutlinedInput label="A chi è indirizzato?" />}
              renderValue={(selected) =>
                selected
                  .map((value) => {
                    const selectedOption = positions.find(
                      (option) => option.id === value
                    );
                    return selectedOption ? selectedOption.nome : "";
                  })
                  .join(", ")
              }
              onChange={handleChange}
            >
              <MenuItem value="all">
                <Checkbox
                  checked={
                    positions.length > 0 &&
                    posizioniScelte.length === positions.length
                  }
                  indeterminate={
                    positions.length > 0 &&
                    posizioniScelte.length < positions.length
                  }
                />
                <ListItemText primary="Seleziona tutti" />
              </MenuItem>
              {positions?.map((ans, i) => (
                <MenuItem key={ans.id} value={ans.id}>
                  <Checkbox checked={posizioniScelte.indexOf(ans.id) > -1} />
                  <ListItemText primary={ans.nome} />
                </MenuItem>
              ))}
            </Select>
          </FormControl> */}
          <FormControl
            margin="normal"
            style={{ width: "60%" }}
            variant="outlined"
          >
            <InputLabel htmlFor="Posizione">A chi è riferito ?</InputLabel>
            <Select
              key={questionary.positionsId}
              label="Posizione"
              input={<OutlinedInput label="A chi è riferito ?" />}
              name="positionsId"
              defaultValue={
                questionaryToEdit ? questionaryToEdit.positionsId : 1
              }
              inputProps={{
                name: "posizione",
                id: "posizione",
              }}
              onChange={(e) => (questionary.positionsId = e.target.value)}
            >
              {positions?.map((el, i) => (
                <MenuItem key={i} value={el.id}>
                  {el.nome}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </section>
      <nav>
        <Button
          variant="outlined"
          color="primary"
          className="btn btn-success"
          onClick={
            questionaryToEdit
              ? () => navigate(-1)
              : () => navigate("/questionnaire")
          }
        >
          Indietro
        </Button>
        &nbsp;
        <Button
          className="btn-questionaries questionaries-button"
          style={{ backgroundColor: "#8376a6 !important", color: "white" }}
          variant="outlined"
          color="primary"
          onClick={(e) => {
            console.log(e);
            e.preventDefault();
            apiCallSave();
          }}
        >
          {questionaryToEdit ? "Modifica" : "Crea"}
        </Button>
      </nav>
    </div>
  );
}

export default CreateQuestionnaires;
