import { hookstate, useHookstate } from "@hookstate/core";
import { devtools } from "@hookstate/devtools";
import { AJAX_ENDPOINT, reclone } from "../commons/Constants";
import { User } from "../datasource/User";
import { EspLav } from "../datasource/EspLav";
import { CVCompetenzeDigitali } from "../datasource/CVCompetenzeDigitali";
import { CVIstruzioneData } from "../datasource/CVIstruzione";
import { CVLingueData } from "../datasource/CVLingue";
import { CVMadreLinguaData } from "../datasource/CVMadreLingua";
import { CVCompetenzeTecniche } from "../datasource/CVCompetenzeTecniche";
import { Roles } from "../datasource/Roles";
import { Questionaries } from "../datasource/Questionaries";
import { QuestionOptions } from "../datasource/QuestionOptions";
import { AnsweredQuestionnaire } from "../datasource/AnsweredQuestionnaire";
import { CVInfoAgency } from "../datasource/CVInfoAgency";

//store with hookstate
const initialState = hookstate(
  {
    loading: false,
    user: null,
    authenticated: false,
    userId: null,
    token: null,
    posizione: null,
    espLav: [],
    competenza: [],
    competenzaTxt: [],
    istruzione: [],
    lingue: [],
    madreLingua: [],
    role: null,
    questionaries: [],
    questionary: null,
    questionOpt: [],
    optionToModify: null,
    questionLenght: null,
    answeredQuestionnaire: [],
    titleQuestionary: "",
    answeredQuestionnaireById: null,
    usersAnsweredQuestionaryById:[],
    infoAgency: null,
    questForAdmin: false,
  },
  devtools({ key: "my-state-label" })
);

export const useGlobalState = () => {
  const state = useHookstate(initialState);
  return {
    state,
    loading: () => state.loading.value,
    user: () => state.user.value,
    authenticated: () => state.authenticated.value,
    userId: () => state.userId.value,
    token: () => state.token.value,
    posizione: () => state.posizione.value,
    espLav: () => state.espLav.value,
    competenza: () => state.competenza.value,
    competenzaTxt: () => state.competenzaTxt.value,
    istruzione: () => state.istruzione.value,
    lingue: () => state.lingue.value,
    madreLingua: () => state.madreLingua.value,
    role: () => state.role.value,
    questionaries: () => state.questionaries.value,
    questionary: () => state.questionary.value,
    optionToModify:() =>state.optionToModify.value,
    questionLenght:() =>state.questionLenght.value,
    questionOpt: () => state.questionOpt.value,
    answeredQuestionnaire: () => state.answeredQuestionnaire.value,
    answeredQuestionnaireById: () => state.answeredQuestionnaireById.value,
    usersAnsweredQuestionaryById: () => state.usersAnsweredQuestionaryById.value,
    titleQuestionary: () => state.titleQuestionary.value,
    infoAgency:() => state.infoAgency.value,
    questForAdmin:() => state.questForAdmin.value,
    setTitleQuestionary: (title) => {
      console.log("quest title: " + title);
      state.merge({ titleQuestionary: title });
    },
    setAuthenticated : (bol) => {
      console.log("boolean: " + bol);
      state.merge({ authenticated: bol });
    },
    setToken : (token) => {
      console.log("token: " + token);
      state.merge({ token: token});
    },
    setPosizione : (posizione) => {
      console.log("posizione: " + posizione);
      state.merge({ posizione: posizione});
    },
    setQuestionary : (Questionary) => {
      console.log("Questionary: ", Questionary);
      state.merge({ questionary: Questionary});
    },
    setOptionToModify : (OptionToModify) => {
      console.log("OptionToModify: ", OptionToModify);
      state.merge({ optionToModify: OptionToModify});
    },
    setQuestionLenght: (QuestionLenght) => {
      console.log("QuestionLenght: ", QuestionLenght);
      state.merge({ questionLenght: QuestionLenght});
    },
    setUserId : (userId) => {
      console.log("userId: " + userId);
      state.merge({ userId: userId});
    },
    setUser : (user) => {
      console.log("user: " + user);
      state.merge({ user: user});
    },
    getUser: async () => {
      state.loading.set(true);
      const u = await User.get(state.userId.value);
      state.merge({ user: u });
      state.loading.set(false);
    },
    logout: async () => {
      state.loading.set(true);
      const u = await User.logout(); 
      state.merge({authenticated: false});
      localStorage.removeItem("userId");
      localStorage.removeItem("auth_token");
      state.merge({ user: null });
      state.merge({ userId: null });
      state.merge({ token: null });
      state.loading.set(false);
      /* window.location.reload(); */
    },
    /* esperienze lavorative => inet-jdk/esplav/get-esplav/ */
    getEspLav: async () => {
      state.loading.set(true);
      const esplav = await EspLav.get(state.userId.value);
      state.merge({ espLav: esplav });
      state.loading.set(false);
    },
    getCompetenzeDigitali: async () => {
      state.loading.set(true);
      const comp = await CVCompetenzeDigitali.get(state.userId.value);
      state.merge({ competenza: comp });
      state.loading.set(false);
    },
    getCompetenzeTecniche: async () => {
      state.loading.set(true);
      const compTxt = await CVCompetenzeTecniche.get(state.userId.value);
      state.merge({ competenzaTxt: compTxt });
      state.loading.set(false);
    },
    getIstruzione: async () => {
      state.loading.set(true);
      const istr = await CVIstruzioneData.get(state.userId.value);
      state.merge({ istruzione: istr });
      state.loading.set(false);
    },
    getLingue: async () => {
      state.loading.set(true);
      const lingua = await CVLingueData.get(state.userId.value);
      state.merge({ lingue: lingua });
      state.loading.set(false);
    },
    getMadreLingua: async () => {
      state.loading.set(true);
      const madreLing = await CVMadreLinguaData.get(state.userId.value);
      state.merge({ madreLingua: madreLing });
      state.loading.set(false);
    },
    getRole: async () => {
      state.loading.set(true);
      const rol = await Roles.get(state.userId.value);
      state.merge({ role: rol });
      state.loading.set(false);
    },
    getQuestionaries: async (id) => {
      state.loading.set(true);
      const question = await Questionaries.get(state.userId.value);
      state.merge({ questionaries: question });
      //nome questionario
      state.merge({
        titleQuestionary: question.map((e) => e.nomeQuestionario),
      });

      state.loading.set(false);
    },
    getAllQuestionaries: async (id) => {
      state.loading.set(true);
      const question = await Questionaries.getAll();
      state.merge({ questionaries: question });
      //nome questionario
      state.merge({
        titleQuestionary: question.map((e) => e.nomeQuestionario),
      });

      state.loading.set(false);
    },
    getQuestionariesByPosition: async (id) => {
      state.loading.set(true);
      console.log("idPosizione", id)
      const question = await Questionaries.byIdPosition(id);
      state.merge({ questionaries: question });
      //nome questionario

      state.loading.set(false);
    },
    getQuestionOpt: async (id) => {
      state.loading.set(true);
      const questionO = await QuestionOptions.get(id);
      state.merge({ questionOpt: questionO });
      state.loading.set(false);
    },
    getAnsweredQuestionnaire: async (id) => {
      state.loading.set(true);
      const answeredQ = await AnsweredQuestionnaire.get(id);
      console.log("lista questionario risposte",answeredQ );
      state.merge({ answeredQuestionnaire: answeredQ });
      state.loading.set(false);
    },
    getAnsweredQuestionnaireById: async (id) => {
      state.loading.set(true);
      const answeredQById = await AnsweredQuestionnaire.getById(id);
      if (Array.isArray(answeredQById) && answeredQById.length > 0) {
        const respAnswered = answeredQById.filter((el) => el.wpUserId === state.userId.value);
        state.merge({ answeredQuestionnaireById: respAnswered[0] });
      } else {
        console.error("AnsweredQuestionnaire not an array");
      }
      state.loading.set(false);
    },

    getUsersAnsweredQuestionaryById: async (id) =>{
      state.loading.set(true);
      const usersAnsweredQById = await AnsweredQuestionnaire.getByIdQuestionary(id);
      if (Array.isArray(usersAnsweredQById) && usersAnsweredQById.length > 0) {        
        state.merge({ usersAnsweredQuestionaryById: usersAnsweredQById });
      } else {
        console.error("usersAnsweredQById not an array");
      }
      state.loading.set(false);
    },
    getInfoAgency: async () => {
      state.loading.set(true);
      const info = await CVInfoAgency.getInfoById(state.userId.value);
      state.merge({ infoAgency: info });
      state.loading.set(false);
    },
    setInfoAgency: async (info) => {
      state.loading.set(true);
      state.merge({ infoAgency: info });
      state.loading.set(false);
    },
    setQuestForAdmin : (bol) => {
      console.log("boolean: " + bol);
      state.merge({ questForAdmin: bol });
    },
  };
};
