import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  TextField,
  Button,
  Grid,
  Container,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Snackbar,
  Alert,
  Modal,
  Box,
  Backdrop,
  InputAdornment,
  IconButton,
  CircularProgress,
  OutlinedInput,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { User } from "../datasource/User";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import { useGlobalState } from "../store/state";
import { Positions } from "../datasource/Positions";
import { data } from "jquery";

const schema = yup.object({
  nome: yup.string().required("Il nome è obbligatorio"),
  cognome: yup.string().required("Il cognome è obbligatorio"),
  dataDiNascita: yup.date().required("La data di nascita è obbligatoria"),
  telefono: yup
    .string()
    .required("Il telefono è obbligatorio")
    .transform((value, originalValue) => {
      // Rimuovi il prefisso +39 se presente
      return originalValue.startsWith("+39")
        ? originalValue.substring(3)
        : originalValue;
    })
    .matches(/^\d+$/, "Il telefono può contenere solo numeri"),
  citta: yup.string().required("La città è obbligatoria"),
  cap: yup
    .string()
    .required("Il cap è obbligatorio")
    .matches(/^\d+$/, "Il cap può contenere solo numeri"),
  email: yup
    .string()
    .email("Inserisci un indirizzo email valido")
    .required("L'email è obbligatoria")
    .test(
      "email",
      "L'indirizzo email deve essere di tipo jdk o apprendo ",
      (value) => {
        if (!value) return true; // La validazione non è richiesta se l'input è vuoto
        const regex = /^[a-zA-Z0-9._%+-]+@(jdk\.it|apprendo\.tech)$/;
        return regex.test(value);
      }
    ),
  sesso: yup
    .string()
    .oneOf(
      ["Maschile", "Femminile", "Altro"],
      "Il sesso deve essere Maschio, Femmina o Altro"
    )
    .required("Il sesso è obbligatorio"),
  nazionalita: yup.string().required("La nazionalità è obbligatoria"),
  posizione: yup.string().required("La posizione ricoperta è obbligatoria"),
  password: yup
    .string()
    .required("La password è obbligatoria")
    .min(8, "La password deve essere di almeno 8 caratteri")
    .max(14, "La password deve contenere massimo 14 caratteri")
    .matches(
      /^(?=.*[A-Z])(?=.*[0-9])(?=.*[-_.#@*])\S+$/,
      "La password deve contenere almeno una lettera maiuscola, un numero e uno dei seguenti caratteri speciali: - _ . # @ *"
    ),
});
const RegisterPage = () => {
  const navigate = useNavigate();
  const state = useGlobalState();
  /* const [isModalOpen, setIsModalOpen] = useState(false); */
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [positions, setPositions] = useState([]);
  const [created, setCreated] = useState(false);
  const [emailRegistrata, setEmailRegistrata] = useState();

  useEffect(() => {
    getPositions();
  }, []);

  const getPositions = async () => {
    Positions.getAll().then((resp) => setPositions(resp));
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setError(false);
  };

  const onMessage = (m, s) => {
    setSeverity(s);
    setError(true);
    setErrorMessage(m);
    if (state.authenticated()) {
      setTimeout(() => {
        setError(false);
      }, 6000);
    }
  };

  const handleTogglePasswordVisibility = () => {
    setIsPasswordVisible((prev) => !prev);
  };

  /*   const handleCloseModal = () => {
    setIsModalOpen(false);
    navigate("/login");
  }; */

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmitFecth = async (data) => {
    const email = data.email.trim();
    const password = data.password.trim();
    setLoading(true);
    setEmailRegistrata(data.email);
    try {
      const response = await User.post({
        nome: data.nome,
        cognome: data.cognome,
        cap: data.cap,
        citta: data.citta,
        tel: data.telefono,
        email: email,
        dataNascita: moment(data.dataDiNascita).format("YYYY-MM-DD"),
        nazionalita: data.nazionalita,
        posizioneRicopertaId: parseInt(data.posizione),
        sesso: data.sesso,
        password: password,
      });
      console.log(response);
      if (response.data) {
        reset();
        setLoading(false);
        setCreated(true);
        onMessage(
          state?.authenticated() &&
            state.user()?.accessRole.includes("ADMIN") ? (
            "Utente creato con successo"
          ) : (
            <>
              completare registrazione tramite link inviato alla vostra email
              @jdk.it <Link to={"/login"}>Accedi</Link>
            </>
          ),
          "success"
        );
      }
    } catch (e) {
      setLoading(false);
      if (e.response.data) {
        onMessage(e.response.data, "error");
      } else {
        onMessage(e.message, "error");
      }
    }
  };

  const onSubmit = (data) => {
    onSubmitFecth(data);
  };

  /*   if (loading) {
    return <CircularProgress size={70} />;
  } */

  return (
    <>
      {created && !state?.user()?.accessRole.includes("ADMIN") ? 
        (<>
          Grazie per esserti registrato! <br/>
          A breve riceverai una email al tuo indirizzo <b style={{textDecoration: 'underline',  color: '#6a5f83'}}>{emailRegistrata}</b> con il link per confermare la registrazione <br/>
          {<Link to={"/login"}>Accedi</Link>}          
       </>)
       : (
        <Container style={{ marginBottom: "100px" }}>
          <Typography variant="h4" gutterBottom>
            {state?.user()?.accessRole.includes("ADMIN")
              ? "Modulo creazione utente"
              : "Registrazione utente"}
          </Typography>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="nome"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Nome"
                      variant="outlined"
                      fullWidth
                      error={!!errors.nome}
                      helperText={errors.nome?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="cognome"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Cognome"
                      variant="outlined"
                      fullWidth
                      error={!!errors.cognome}
                      helperText={errors.cognome?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="dataDiNascita"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      type="date"
                      label="Data di nascita"
                      variant="outlined"
                      fullWidth
                      InputLabelProps={{
                        shrink: !field.value || field.isDirty,
                      }}
                      error={!!errors.dataDiNascita}
                      helperText={errors.dataDiNascita?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="telefono"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Telefono"
                      variant="outlined"
                      fullWidth
                      error={!!errors.telefono}
                      helperText={errors.telefono?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="email"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Email"
                      variant="outlined"
                      fullWidth
                      error={!!errors.email}
                      helperText={errors.email?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="password"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Password"
                      type={isPasswordVisible ? "text" : "password"}
                      fullWidth
                      variant="outlined"
                      error={!!errors.password}
                      helperText={errors.password?.message}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={handleTogglePasswordVisibility}
                            >
                              {isPasswordVisible ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="sesso"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <FormControl
                      fullWidth
                      variant="outlined"
                      error={!!errors.sesso}
                    >
                      <InputLabel htmlFor="sesso">Sesso</InputLabel>
                      <Select
                        {...field}
                        label="Sesso"
                        inputProps={{
                          name: "sesso",
                          id: "sesso",
                        }}
                      >
                        <MenuItem value="Maschile">Maschio</MenuItem>
                        <MenuItem value="Femminile">Femmina</MenuItem>
                        <MenuItem value="Altro">Altro</MenuItem>
                      </Select>
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="nazionalita"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Nazionalità"
                      variant="outlined"
                      fullWidth
                      error={!!errors.nazionalita}
                      helperText={errors.nazionalita?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="citta"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Città"
                      variant="outlined"
                      fullWidth
                      error={!!errors.citta}
                      helperText={errors.citta?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="cap"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Cap"
                      variant="outlined"
                      fullWidth
                      error={!!errors.cap}
                      helperText={errors.cap?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="posizione"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    /*               <TextField
                  {...field}
                  label="Posizione ricoperta"
                  variant="outlined"
                  fullWidth
                  error={!!errors.posizione}
                  helperText={errors.posizione?.message}
                /> */
                    <FormControl
                      fullWidth
                      variant="outlined"
                      error={!!errors.posizione}
                    >
                      <InputLabel htmlFor="Posizione">
                        Posizione ricoperta
                      </InputLabel>
                      <Select
                        {...field}
                        label="Posizione"
                        input={<OutlinedInput label="Posizione ricoperta" />}
                        inputProps={{
                          name: "posizione",
                          id: "posizione",
                        }}
                      >
                        {positions?.map((el, i) => (
                          <MenuItem key={i} value={el.id}>
                            {el.nome}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  type="submit"
                  className="btn-questionaries questionaries-button"
                >
                  {loading ? (
                    <CircularProgress size={28} />
                  ) : state?.user()?.accessRole.includes("ADMIN") ? (
                    "Crea"
                  ) : (
                    "Registra"
                  )}
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Typography>
                  {!state?.user()?.accessRole.includes("ADMIN") ? (
                    <span>
                      Sei già registrato? <Link to={"/login"}>Accedi</Link>
                    </span>
                  ) : null}
                </Typography>
              </Grid>
              {/*           <Grid item xs={12}>
            <Typography>
              Hai già un account? <Link to={"/login"}>Accedi</Link>
            </Typography>
          </Grid> */}
            </Grid>
          </form>
          {/* Modale per il navigate alla pagina di login */}
          {/*       <Modal
        open={isModalOpen}
        onClose={handleCloseModal}
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography variant="h5" gutterBottom>
            Registrazione completata!
          </Typography>
          <Typography gutterBottom>
            Grazie per esserti registrato. Ora puoi effettuare il login.
          </Typography>
          <Button variant="outlined" color="primary" onClick={handleCloseModal}>
            Vai al login
          </Button>
        </Box>
      </Modal> */}
          <Snackbar
            open={error}
            autoHideDuration={state.authenticated() ? 6000 : null}
            onClose={handleClose}
          >
            <Alert
              onClose={handleClose}
              severity={severity}
              sx={{ width: "100%" }}
            >
              {errorMessage}
            </Alert>
          </Snackbar>
        </Container>
      )}
    </>
  );
};

export default RegisterPage;
