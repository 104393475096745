import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import jQuery from "jquery";
import { clone, debounce } from "lodash";
import Moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { AJAX_ENDPOINT, reclone } from "../commons/Constants";
import CVUpdateIstruzioneForm from "./CVUpdateIstruzioneForm";
import { useRef } from "react";
import { Button } from "@mui/material";
import { useGlobalState } from "../store/state";
import { CVIstruzioneData } from "../datasource/CVIstruzione";

function stripHtml(html) {
  let tmp = document.createElement("DIV");
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || "";
}

function truncate(str, n, useWordBoundary) {
  if (str.length <= n) {
    return str;
  }
  const subString = str.substr(0, n - 1); // the original check
  return (
    (useWordBoundary
      ? subString.substr(0, subString.lastIndexOf(" "))
      : subString) + "..."
  );
}

function CVIstruzione(props) {
  // const [count, setCount] = React.useState(0);
  const [esp, setEsp] = useState([]);
  const [lockSorting, setLockSorting] = useState(false);
  const [touched, setTouched] = useState(false);
  const state = useGlobalState();

  const refForm = useRef();

  const newEsp = () => {
    // debugger;
    esp.push({
      wpUserId: state.user()?.wpUserId,
      id: Math.random(),
      dummy: true,
      descrizione: "",
    });
    refreshState();
  };

  const deleteItem = async (i) => {
    if (window.confirm("Vuoi eliminare l'elemento selezionato?")) {
      const splitId = esp.splice(i, 1);
      setTouched(true);
      await CVIstruzioneData.delete(splitId[0].id);
      refreshState();
    }
  };

  const sortUp = (i) => {
    if (i > 0 && !lockSorting) {
      let tmp = esp[i - 1];
      esp[i - 1] = esp[i];
      esp[i] = tmp;
      setTouched(true);
      refreshState();
    }
  };

  const sortDown = (i) => {
    if (i < esp.length - 1 && !lockSorting) {
      let tmp = esp[i + 1];
      esp[i + 1] = esp[i];
      esp[i] = tmp;
      setTouched(true);
      refreshState();
    }
  };

  const refreshState = () => {
    setEsp(clone(esp));
    //setLockSorting(false);
  };

  const _refreshState = () => {
    setEsp(clone(esp));
    setLockSorting(false);
  };

  const refreshStateDebounced = useCallback(debounce(refreshState, 30), [esp]);

  const refreshStateDebouncedLarge = useCallback(
    debounce(_refreshState, 5000),
    [esp]
  );

  const _refreshStateDebounced = () => {
    setEsp(clone(esp));
  };

  const apiCall = async () => {
    //  setCount(2);
    await state.getIstruzione();
    let esp = state.istruzione();
    esp = reclone(esp);
    setEsp(esp);
  };

  const apiCallSave = async () => {
    try {
      const modifiedIstr = esp.filter((o) => o.touched === true);
      let promises = [];
      for (let i = 0; i < modifiedIstr.length; i++) {
        modifiedIstr[i].touched = false;
        promises.push(CVIstruzioneData.put(modifiedIstr[i]));
      }
      await Promise.all(promises);

      state.getIstruzione();
      props.onMessage("Salvataggio effettuato", "success");
    } catch (e) {
      console.error(e);
      props.onCardSkimPrev(document.querySelector("#skimprev-esplav"));
      props.onMessage("Errore nel salvataggio", "error");
    }
  };

  useEffect(() => {
    apiCall();
  }, []);

  return (
    <div className="JDKReactApp-header visible">
      <h3>Controlla le tue informazioni su istruzione e formazione</h3>
      {esp.length > 0 ? (
        <section className="formcontainer customscrollbar">
          <form ref={refForm}>
            {esp.map((o, i) => {
              const key = o.id; //JSON.stringify(o);//Math.random()+""+Date.now();
              //console.log('key',key);

              if (
                typeof o.descrizionePlainText === "undefined" ||
                o.descrizionePlainText === null ||
                o.descrizionePlainText === ""
              ) {
                //console.log('descrizione',o.descrizione);
                o.descrizionePlainText = truncate(o.descrizione, 37, true)
                  .replaceAll("\n-", " , ")
                  .replaceAll("\n -", " , ");
                // console.log('descrizionePlainText',o.descrizionePlainText);
                if (o.descrizionePlainText === "") {
                  o.descrizionePlainText = "Clicca l'accordion per compilare";
                }
              }
              return (
                <Accordion key={i}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <div className="accordion-header-jdk">
                      <div className="accordion-header-item">
                        <div className="aheader">#</div>
                        {i + 1}
                      </div>
                      <div className="accordion-header-item">
                        <div className="aheader">Istituto</div>
                        {o.istituto}
                        <div>
                          {" "}
                          <em className="date_range">
                            {Moment(o.anno, "YYYY-MM-DD").isValid()
                              ? Moment(o.anno, "YYYY-MM-DD").format("YYYY")
                              : "Conferma data conseguimento"}
                          </em>{" "}
                        </div>
                      </div>
                      <div className="accordion-header-item">
                        <div className="aheader">Luogo</div>
                        {o.citta}
                      </div>
                      <div className="accordion-header-item">
                        <div className="aheader">Titolo</div>
                        {o.titolo}
                      </div>
                      <div className="accordion-header-item descrizione">
                        <div className="aheader">descrizione</div>
                        {o.descrizionePlainText}
                      </div>
                      <div className="accordion-header-item descrizione">
                        <div className="aheader">Azioni</div>

                        <span
                          className="accordion-action"
                          title="Sposta su"
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            sortUp(i);
                          }}
                        >
                          <ArrowDropUpIcon />
                        </span>
                        <span
                          className="accordion-action"
                          title="Sposta giù"
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            sortDown(i);
                          }}
                        >
                          <ArrowDropDownIcon />
                        </span>
                        <span
                          className="accordion-action"
                          title="Elimina definitiviamente"
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            deleteItem(i);
                          }}
                        >
                          <DeleteForeverIcon />
                        </span>
                      </div>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <CVUpdateIstruzioneForm
                      onDescrizioneChange={(id, value, plainText) => {
                        const idx = esp.findIndex((v) => v.id === id);
                        if (esp[idx].descrizione_touched && plainText === "") {
                          return;
                        }
                        // console.log(idx,value,plainText);
                        esp[idx].descrizione = value;
                        esp[idx].touched = true;
                        esp[idx].descrizione_touched = true;
                        esp[idx].descrizionePlainText = plainText;
                        esp[idx].descrizionePlainText = truncate(
                          plainText,
                          37,
                          true
                        )
                          .replaceAll("\n-", " , ")
                          .replaceAll("\n -", " , ");

                        //setLockSorting(true);
                        refreshState();
                      }}
                      onAnnoChange={(id, value) => {
                        const idx = esp.findIndex((v) => v.id === id);
                        esp[idx].anno = Moment(value).format("YYYY-MM-DD");
                        esp[idx].touched = true;
                        refreshState();
                      }}
                      onTitoloChange={(id, value) => {
                        const idx = esp.findIndex((v) => v.id === id);
                        esp[idx].titolo = value;
                        esp[idx].touched = true;
                        refreshState();
                      }}
                      onIstitutoChange={(id, value) => {
                        const idx = esp.findIndex((v) => v.id === id);
                        esp[idx].istituto = value;
                        esp[idx].touched = true;
                        refreshState();
                      }}
                      onLuogoChange={(id, value) => {
                        const idx = esp.findIndex((v) => v.id === id);
                        esp[idx].citta = value;
                        esp[idx].touched = true;
                        refreshState();
                      }}
                      onCampoChange={(id, value) => {
                        const idx = esp.findIndex((v) => v.id === id);
                        esp[idx].campo = value;
                        esp[idx].touched = true;
                        refreshState();
                      }}
                      onLivelloChange={(id, value) => {
                        const idx = esp.findIndex((v) => v.id === id);
                        esp[idx].livello = value;
                        esp[idx].touched = true;
                        refreshState();
                      }}
                      o={o}
                      key={key}
                    ></CVUpdateIstruzioneForm>
                  </AccordionDetails>
                </Accordion>
              );
            })}
          </form>
        </section>
      ) : (
        <div>Nessuna istruzione o formazione aggiunta ...</div>
      )}

      <nav>
        <Button
          variant="outlined"
          color="primary"
          onClick={(e) => {
            e.preventDefault();
            props.onCardSkimPrev(e.target);
          }}
        >
          Indietro
        </Button>{" "}
        &nbsp;
        <Button
          variant="outlined"
          color="primary"
          onClick={(e) => {
            e.preventDefault();
            newEsp();
          }}
        >
          Aggiungi esp. formativa
        </Button>{" "}
        &nbsp;
        <Button
          variant="outlined"
          color="primary"
          onClick={(e) => {
            e.preventDefault();
            props.onCardSkim(e.target);
            apiCallSave();
          }}
        >
          Avanti
        </Button>
      </nav>
    </div>
  );
}

export default CVIstruzione;
