import React, { useEffect, useRef, useState } from "react";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import {
  Alert,
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  Snackbar,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Clear } from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import { useNavigate } from "react-router-dom";
import { User } from "../datasource/User";
import moment from "moment";

const CustomToolbar = (props) => {
  return (
    <div style={{ padding: "10px" }}>
      <GridToolbarContainer sx={{ justifyContent: "space-between" }}>
        <GridToolbarQuickFilter placeholder="Cerca..." />
        {/* <GridToolbarExport
          printOptions={{
            hideFooter: true,
            hideToolbar: true,
          }}   
          csvOptions={{
            fileName: 'Pannello utenti',
            delimiter: ';',
            utf8WithBom: true,
            includeColumnGroupsHeaders: true,
            includeHeaders: true,
          }} 
        /> */}
        {/*         <TextField
          label="Ricerca"
          variant="outlined"
          size="small"
          inputRef={inputRef}
          value={props.value}
          onChange={(e) => props.onChange(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {props.value && (
                  <IconButton onClick={props.onCancelSearch} size="small">
                    <Clear />
                  </IconButton>
                )}
              </InputAdornment>
            ),
          }}
        /> */}
        {props.rowSelectionModel.length > 0 && (
          <Button
            onClick={() => {
              try {
                User.deleteByListId(props.rowSelectionModel.join(",")).then(
                  (data) => {
                    if (data) {
                      props.fetchData();
                      props.setRowSelectionModel([]);
                      props.onMessage(
                        "Utenti eleminati con successo",
                        "success"
                      );
                    } else {
                      props.onMessage("Qualcosa è andato storto", "error");
                    }
                  }
                );
              } catch (error) {
                props.onMessage(error.message, "error");
              }
            }}
            variant="outlined"
          >
            Elimina utenti selezionati
          </Button>
        )}
      </GridToolbarContainer>
    </div>
  );
};

export default function UsersTable() {
  /* const [searchText, setSearchText] = useState(""); */
  /* const [originalData, setOriginalData] = useState([]); */
  const [tableData, setTableData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [email, setEmail] = useState(null);
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const navigate = useNavigate();
  console.log(tableData);
  const fetchData = async () => {
    setLoading(true);
    try {
      const resp = await User.getAll();
      const mappingResp = await resp.map((el) => {
        return { id: el.wpUserId, ...el };
      });
      setTableData(mappingResp);
      setLoading(false);
    } catch (error) {
      console.error(error.message);
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setError(false);
  };

  const onMessage = (m, s) => {
    setSeverity(s);
    setError(true);
    setErrorMessage(m);
    setTimeout(() => {
      setError(false);
    }, 6000);
  };

  /*   useEffect(() => {
    setTableData(originalData);
  }, [originalData]); */

  /*   const requestSearch = (searchValue) => {
    const filteredRows = originalData.filter((row) =>
      row.name.toLowerCase().includes(searchValue.toLowerCase())
    );
    setTableData(filteredRows);
  }; */

  /*   const cancelSearch = () => {
    setSearchText("");
    setTableData(originalData);
  }; */

  const handleEditClick = (id) => {
    // Azioni da eseguire quando si fa clic su Modifica
    console.log(`Modifica elemento con id: ${id}`);
    navigate("/modificaUtente/" + id);
  };

  const handleVisualClick = (id) => {
    // Azioni da eseguire quando si fa clic su Modifica
    console.log(`Modifica elemento con id: ${id}`);
    navigate("/dettagliUtente/" + id);
  };

  const handleDeleteClick = (id, email) => {
    // Azioni da eseguire quando si fa clic su Elimina
    setEmail(email);
    setSelectedId(id);
    setOpenModal(true);
  };

  const handleDeleteConfirm = () => {
    try {
      // Azioni da eseguire quando si conferma l'eliminazione
      User.deleteById(selectedId).then((data) => {
        if (data) {
          fetchData();
          onMessage("Utente eliminato con successo", "success");
        } else {
          onMessage("Qualcosa è andato storto", "error");
        }
      });
      setOpenModal(false);
    } catch (error) {
      onMessage(error.message, "error");
      setOpenModal(false);
    }
  };

  const handleDeleteCancel = () => {
    setOpenModal(false);
  };

  const columns = [
    { headerName: "Id", field: "id", width: 50 },
    { headerName: "Nome", field: "nome" },
    { headerName: "Cognome", field: "cognome" },
    { headerName: "Email", field: "email", width: 180 },
    /*    { headerName: "Sesso", field: "sesso" },
    { headerName: "Tel", field: "tel", width: 130 },
    { headerName: "Città", field: "citta", width: 130 },
    {
      headerName: "Data di nascita",
      field: "dataNasc",
      renderCell: (params) => (
        <p>
          {params.row.dataNasc
            ? moment(params.row.dataNasc).format("DD/MM/YYYY")
            : ""}
        </p>
      ),
    }, 
    { headerName: "Posizione", field: "posizioneRicoperta", width: 160 },*/
    {
      headerName: " ",
      width: 185,
      filter: false,
      field: "actions",
      sortable: false,
      filterable: false,
      renderCell: (params) => (
        <ButtonGroup variant="outlined">
          <Button onClick={() => handleVisualClick(params.row.id)}>
            <PersonSearchIcon />
          </Button>
          <Button onClick={() => handleEditClick(params.row.id)}>
            <EditIcon />
          </Button>
          <Button
            onClick={() => handleDeleteClick(params.row.id, params.row.email)}
          >
            <DeleteIcon />
          </Button>
        </ButtonGroup>
      ),
    },
  ];

  const theme = useTheme();
  const matches650 = useMediaQuery('(max-width:650px)');
  const matches500 = useMediaQuery('(max-width:500px)');
  const columnVisibilityModel = () => {
    if (matches650) {
      return {
        nome: false,
        cognome: false,
        id: false
      };
    } else {
      return {
        id: false
      }
    }
  }

  return (
    <div style={{ height: 500, width: "100%" }}>

      <Button
        onClick={() => {
          navigate("/register")
        }}
        variant="outlined"
        className="btn-questionaries questionaries-button"
        style={{ backgroundColor: "#8376a6 !important", color: "white", marginBottom: "10px" }}
      >
        CREA UTENTE
      </Button>
      <div className="dataGridUserTable" style={{height: "430px"}}>
        <DataGrid
          slots={{ toolbar: CustomToolbar }}
          rows={tableData}
          columns={columns}
        
          columnVisibilityModel={columnVisibilityModel()}
          disableColumnMenu
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 25,
              },
            },
          }}
          slotProps={{
            toolbar: {
              rowSelectionModel: rowSelectionModel,
              setRowSelectionModel: setRowSelectionModel,
              fetchData: fetchData,
              onMessage: onMessage,
              /*             value: searchText,
              onChange: (searchVal) => {
                setSearchText(searchVal);
                requestSearch(searchVal);
              },
              onCancelSearch: cancelSearch, */
            },
          }}
          keepNonExistentRowsSelected
          loading={loading}
          checkboxSelection = {matches650 ? false : true}
          onRowSelectionModelChange={(newRowSelectionModel, details) => {
            setRowSelectionModel(newRowSelectionModel);
          }}
          rowSelectionModel={rowSelectionModel}
          disableRowSelectionOnClick
        />
      </div>
      <Dialog open={openModal} onClose={handleDeleteCancel}>
        <DialogTitle>Conferma eliminazione</DialogTitle>
        <DialogContent>
          Sei sicuro di voler eliminare l'utente <b>{email}</b> ?
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleDeleteCancel}
            variant="outlined"
            color="primary"
          >
            Annulla
          </Button>
          <Button
            onClick={handleDeleteConfirm}
            className="btn-questionaries questionaries-button"
          >
            Elimina
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar open={error} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={severity} sx={{ width: "100%" }}>
          {errorMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}
