import axios from "axios";
import {
  AJAX_ENDPOINT,
  FAKE_USER_ID,
  getHeaders,
  reclone,
} from "../commons/Constants";
import { clone } from "lodash";

export class CVCompetenzeTecniche {
  static async get(id) {
    const HEADERS = getHeaders();
    const resp = await axios.get(
      AJAX_ENDPOINT + "inet-jdk/competenza-txt/get-by-user/" + id,
      {
        headers: HEADERS,
      }
    );
    if (resp.data.sesso === "M") {
      resp.data.sesso = "Maschile";
    }
    return resp.data;
  }

  static async put(competenzaTxt) {
    const HEADERS = getHeaders();
    competenzaTxt = reclone(competenzaTxt);

    let resp;
    if (competenzaTxt.id < 1) {
      delete competenzaTxt.id;
      resp = await axios.post(
        AJAX_ENDPOINT + "inet-jdk/competenza-txt/create",
        competenzaTxt,
        {
          headers: HEADERS,
        }
      );
    } else {
      resp = await axios.put(
        AJAX_ENDPOINT + "inet-jdk/competenza-txt/update/" + competenzaTxt.id,
        competenzaTxt,
        {
          headers: HEADERS,
        }
      );
    }
    return resp;
  }

  static async delete(id) {
    const HEADERS = getHeaders();
    const resp = await axios.delete(
      AJAX_ENDPOINT + "inet-jdk/competenza-txt/delete-by-id/" + id,
      {
        headers: HEADERS,
      }
    );
    return resp;
  }
}
