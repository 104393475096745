import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import * as React from "react";

function QuestionYesNo(props) {
    const [answer, setAnswer] = React.useState("");
  
    const handleChange = (event) => {
      setAnswer(event.target.value);
      props.onAnswerChange(event.target.value);
    };
  
    //console.log(props.q.question_answers);
    return (
      <FormControl component="fieldset">
        <FormLabel component="legend">Risposta</FormLabel>
        <RadioGroup
          onChange={handleChange}
          row
          aria-label="gender"
          name="row-radio-buttons-group"
        >
          <FormControlLabel value="yes" control={<Radio />} label="Sì" />
          <FormControlLabel value="no" control={<Radio />} label="No" />
        </RadioGroup>
      </FormControl>
    );
  }

  export default QuestionYesNo;