import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import jQuery from "jquery";
import React, { useEffect, useState } from "react";
import CVEsperienzeLavorative from "../pages/CVEsperienzeLavorative";
import CVFormAnagrafica from "../pages/CVFormAnagrafica";
import CVGenerate from "../pages/CVGenerate";
import CVIstruzione from "../pages/CVIstruzione";
import CVLingue from "../pages/CVLingue";
import { useGlobalState } from "../store/state";
import CVMadreLingua from "./CVMadreLingua";
import CompentenzeDigitali from "./CompetenzeDigitali";
import CompentenzeTecniche from "./CompetenzeTecniche";
import InfoAgency from "./InfoAgency";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function CVApp(props) {
  const [count, setCount] = React.useState(0);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [severity, setSeverity] = useState("");

  const state = useGlobalState();
  const [user, setUser] = useState([]);
  const [content, setContent] = useState("");
  const [edit, setEdit] = useState(false);
  const [updateId, setUpdateId] = useState("");

  const onCardSkim = (elm) => {
    //console.log(elm);

    jQuery(elm).closest(".JDKReactApp-header").addClass("skimming");
    jQuery(elm).closest(".JDKReactApp").addClass("skimming");
    jQuery(elm).closest(".JDKReactApp-header").prev().addClass("visible");
  };

  const onCardSkimPrev = (elm) => {
    //console.log(elm);
    const $e = jQuery(elm).closest(".JDKReactApp-header");
    $e.removeClass("visible");
    const $enext = $e.next();
    $enext
      .removeClass("skimming")
      .addClass("visible");
      jQuery(elm).closest(".JDKReactApp").removeClass("skimming");

    console.log($enext);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setError(false);
  };

  const onMessage = (m, s) => {
    setSeverity(s);
    setError(true);
    setErrorMessage(m);
    setTimeout(() => { setError(false); }, 6000);
  };


  return (
    <div>
     <CVGenerate
        onMessage={onMessage}
        onCardSkimPrev={onCardSkimPrev}
        onCardSkim={onCardSkim}
      ></CVGenerate>
        <InfoAgency
        onMessage={onMessage}
        onCardSkimPrev={onCardSkimPrev}
        onCardSkim={onCardSkim}
      ></InfoAgency>
        <CompentenzeTecniche
        onMessage={onMessage}
        onCardSkimPrev={onCardSkimPrev}
        onCardSkim={onCardSkim}
      ></CompentenzeTecniche>
         <CompentenzeDigitali
        onMessage={onMessage}
        onCardSkimPrev={onCardSkimPrev}
        onCardSkim={onCardSkim}
      ></CompentenzeDigitali>
      <CVLingue
        onMessage={onMessage}
        onCardSkimPrev={onCardSkimPrev}
        onCardSkim={onCardSkim}
      ></CVLingue>
      <CVMadreLingua
        onMessage={onMessage}
        onCardSkimPrev={onCardSkimPrev}
        onCardSkim={onCardSkim}
      ></CVMadreLingua>
      <CVIstruzione
        onMessage={onMessage}
        onCardSkimPrev={onCardSkimPrev}
        onCardSkim={onCardSkim}
      ></CVIstruzione> 
      <CVEsperienzeLavorative
        onMessage={onMessage}
        onCardSkimPrev={onCardSkimPrev}
        onCardSkim={onCardSkim}
      ></CVEsperienzeLavorative>
       <CVFormAnagrafica
        onMessage={onMessage}
        onCardSkimPrev={onCardSkimPrev}
        onCardSkim={onCardSkim}
      ></CVFormAnagrafica> 
      <Snackbar open={error} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={severity} sx={{ width: "100%" }}>
          {errorMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default CVApp;
