import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import * as React from "react";

function QuestionSlider10(props) {
    const [answer, setAnswer] = React.useState([]);
  
    const handleChange = (event) => {
      setAnswer(event.target.value);
      props.onAnswerChange(event.target.value);
    };
  
    //console.log(props.q.question_answers);
    return (
      <Box sx={{ width: 300 }}>
        <Slider
          defaultValue={5}
          onChange={handleChange}
          step={1}
          marks
          min={1}
          max={10}
          valueLabelDisplay="on"
        />
      </Box>
    );
  }

  export default QuestionSlider10;