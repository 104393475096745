import React, { useEffect, useState } from 'react';
import { Button } from "@mui/material";
import { useGlobalState } from '../store/state';
import template from '../assets/modello_cv-new.docx';
import Moment from "moment";
import { saveAs } from 'file-saver';
import PizZip from 'pizzip';
import Docxtemplater from 'docxtemplater';
import ImageModule from 'docxtemplater-image-module';
import { Buffer } from "buffer";

function CVGenerate(props) {
  const [cv, setCV] = useState(null);
  const state = useGlobalState();

  const uinfo = state.user();
  const esp = state.espLav();
  const competenze = state.competenza();
  const competenzeTxt = state.competenzaTxt();
  const lingue = state.lingue();
  const madreLingua = state.madreLingua();
  const istruzione = state.istruzione();
  const infoAgency = state.infoAgency();
  const posizione = state.posizione();
  
  const esperienzeLav = esp?.map((o) => {
    const progettiJsonS = o?.progettiJson.map((e) => {
      return ({
        ...e,
        data_inizio: Moment(e.data_inizio).format('MM-YYYY'),
        data_fine: Moment(e.data_inizio).format('MM-YYYY')
       })
    })

    return ({
      data_inizio: o.dataInizio,
      data_fine: o.dataFine,
      qualifica: o.qualifica,
      datore_lavoro: o.datoreLavoro,
      citta_datore: o.citta,
      mansioni: o.mansioni,
      lavoroQui: o.lavoroQui,
      progetti: progettiJsonS
    })
  })

  const cvIstruzione = istruzione?.map((o) => {
    return ({
      formazione_anno: o.anno,
      campo_studio: o.campo,
      citta_istituto: o.citta,
      formazione_descrizione: o.descrizione,
      istituto: o.istituto,
      formazione_livello: o.livello,
      titolo_studio: o.titolo
    })
  })
  const cvLingue = lingue?.map((o) => {
    return ({
      ascolto_liv: o.ascoltoLiv,
      lingua_cert: o.certificazione,
      interazione_liv: o.interazioneLiv,
      lettura_liv: o.letturaLiv,
      lingue: o.lingua, //?? 
      prod_orale_liv: o.prodOraleLiv,
      prod_scritta_liv: o.prodScrittaLiv,
    })
  })
  const cvMadreLingua = madreLingua?.map((o) => {
    return ({
      linguamadre: o.madrelingua
    })
  })
  const competenzeLav = competenze?.map((o) => {
    return ({
      competenza: o.competenza || 'competenza',
      elab_liv: o.elabLiv || 'elabLiv',
      comunicazione_liv: o.comunicazioneLiv || 'comunicazioneLiv',
      creazione_liv: o.creazioneLiv || 'creazioneLiv',
      sicurezza_liv: o.sicurezzaLiv || 'sicurezzaLiv',
      risoluzione_liv: o.risoluzioneLiv || 'risoluzioneLiv',
      comp_cert: o.certificazione || 'certificazione',
    })
  })

  const competenzeLavTxt = competenzeTxt?.map((o) => {
    return ({
      competenze: o.competenze || 'competenze',
    })
  })

  let base64Image
  let imageUrl
  if(infoAgency?.imageBase64){
     base64Image = infoAgency?.imageBase64;
     /* imageUrl = base64Image ? `${base64Image}` : null; */
     const convertBase64ToBlob = (base64) => {
      const binaryString = Buffer.from(base64, "base64").toString("binary");
      const byteArray = new Uint8Array(binaryString.length);
      for (let i = 0; i < binaryString.length; i++) {
        byteArray[i] = binaryString.charCodeAt(i);
      }
      return new Blob([byteArray], { type: "image/jpeg" });
    };
    const blob = convertBase64ToBlob(base64Image);
    imageUrl = URL.createObjectURL(blob);
  }
  if (!imageUrl) {
    console.error("Image URL is undefined. Cannot fetch image.");
    /* return; */ // Exit early if image URL is undefined
  }
  let resume = {
    display_name: uinfo ? uinfo.nome : 'nome',
    nazionalita: uinfo ? uinfo.nazionalita : 'nazionalita',
    cap: uinfo ? uinfo.cap : 'cap',
    citta: uinfo ? uinfo.citta : 'citta',
    mail_aziendale: uinfo ? uinfo.email : 'email@gmail.com',
    sesso: uinfo ? uinfo.sesso : 'seso',
    data_nasc: uinfo ? uinfo.dataNasc : '02/02/1990',
    posizione_ricoperta: posizione ? posizione : 'dev',
    esperienza_lavorativa: esp ? esperienzeLav : [],
    istruzione: cvIstruzione,
    lingue: cvLingue,
    linguamadre: cvMadreLingua,
    competenze: competenzeLav,
    altre_competenze: competenzeLavTxt,
    nome: infoAgency?.nome,
    sedeOperativa: infoAgency?.sedeOperativa,
    indirizzo: infoAgency?.indirizzo,
    partitaIvaCodiceFiscale: infoAgency?.partitaIvaCodiceFiscale,
    codiceUnivoco: infoAgency?.codiceUnivoco,
    capitaleSociale: infoAgency?.capitaleSociale,
    rea: infoAgency?.rea,
  };

  const generateDocument = async (resume, templatePath) => {
    try {
      // Fetch template data
      const response = await fetch(templatePath);
      const data = await response.arrayBuffer();
      const zip = new PizZip(data);

     // Fetch image data
     console.log(imageUrl);
      const imageResponse = await fetch(imageUrl);
      const imageArrayBuffer = await imageResponse.arrayBuffer();
      const imageBinaryData = new Uint8Array(imageArrayBuffer);

      // Set the image data in the resume object
      resume.image = new Uint8Array(resume.image);
      // Create Docxtemplater instance
      const templateDoc = new Docxtemplater(zip, {
        modules: [
          new ImageModule({
            imageTag:'{image}',  // Make sure it matches the image tag in your template
            getImage: (tagValue, tagName) => {
              return fetch(tagValue)
                .then((response) => response.arrayBuffer())
                .then((arrayBuffer) => new Uint8Array(arrayBuffer));
            },
            getSize: (img, tagValue, tagName) => {
              return fetch(tagValue)
                .then((response) => response.blob())
                .then((blob) => ({ width: blob.width, height: blob.height }));
            },
          }),
        ],
      });
  
      // Set the data for the template
      templateDoc.setData(resume);
      // Render the template
      templateDoc.render();
  
      // Get the generated document content
      const generatedDoc = templateDoc.getZip().generate({
        type: 'blob',
        mimeType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      });
      // Save the generated document
      saveAs(generatedDoc, `${resume.display_name}'s resume.docx`);
    } catch (error) {
      console.error('Error:', error);
    }
  }
  
  const handleGenerateDocument = () => {
    generateDocument(resume, template);
  };

  return (
    <div className="JDKReactApp-header">
      <section>
        <h3>Scarica il tuo CV</h3>
        <nav>
          <Button
            variant="outlined"
            color="primary"
            onClick={(e) => {
              e.preventDefault();
              props.onCardSkimPrev(e.target);
            }}
          >
            Indietro
          </Button>{" "}
          &nbsp;
          <Button
            variant="outlined"
            color="primary"
            onClick={handleGenerateDocument}
          >
            Scarica CV
          </Button>{" "}
        </nav>
      </section>
    </div>
  );
}

export default CVGenerate;
