import React, { useState, useEffect, useRef, useCallback } from "react";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Fade from "@mui/material/Fade";
import jQuery from "jquery";
import QuestionRow from "../components/questions/QuestionRow";
import {
  Alert,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Snackbar,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useGlobalState } from "../store/state";
import { AnsweredQuestionnaire } from "../datasource/AnsweredQuestionnaire";
import {
  FAKE_USER_ID,
  ROLE_ADMIN,
  ROLE_USER,
  reclone,
} from "../commons/Constants";
import { useNavigate, useParams } from "react-router-dom";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import { IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import SettingsIcon from "@mui/icons-material/Settings";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import ClearIcon from '@mui/icons-material/Clear';
import { clone } from "lodash";
import { Questionaries } from "../datasource/Questionaries";
import DoneIcon from '@mui/icons-material/Done';

const CustomToolbar = (props) => {
  const navigate = useNavigate();
  return (
    <div style={{ padding: "10px" }}>
      <GridToolbarContainer sx={{ justifyContent: "space-between" }}>
        <GridToolbarQuickFilter placeholder="Cerca..." />
        <Button
          onClick={() => {
            navigate("/createQuestionnaires");
          }}
          variant="outlined"
          className="btn-questionaries questionaries-button"
          style={{ backgroundColor: "#8376a6 !important", color: "white" }}
        >
          Crea Questionario
        </Button>
      </GridToolbarContainer>
    </div>
  );
};

const QuestionnaireForm = (props) => {
  const [questions, setQuestions] = useState([]);
  const [questionnaires, setQuestionnaires] = useState([]);
  const [loading, setLoading] = useState(true);
  const [apiError, setApiError] = useState(false);
  const [questionnaireSent, setQuestionnaireSent] = useState(false);
  const [apiErrorSending, setApiErrorSending] = useState(false);
  const state = useGlobalState();
  const [skipNext, setSkipNext] = useState(false);
  const [selectId, setSelectId] = useState(-1);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [selectedId, setSelectedId] = useState(null);

  const answeredQuestions = [];

  const navigate = useNavigate();

  const onSelectId = useCallback(
    (id) => {
      console.log("id onSelectId è: " + id);
      let idr = id;
      setSelectId(idr);
    },
    [selectId]
  );

  const apiCall = async () => {
    setLoading(true);
    //se admin mi da tutti i questionari
    if (state?.user().accessRole.includes("ADMIN") && !state.questForAdmin()) {
      await state.getAllQuestionaries();
      let questionnaires = state.questionaries();
      questionnaires = reclone(questionnaires);
      setQuestionnaires(questionnaires);
      setLoading(false);
    } else {
      //altrimenti solo i questionari in base all'idPosizione user
      await state.getAllQuestionaries();
      let questionnaires = state.questionaries();
      questionnaires = reclone(questionnaires);
      const filteredArray = questionnaires.filter(
        (el) =>
          el.positionsId === 1 ||
          el.positionsId === state.user().posizioneRicopertaId
      );
      //controllo se tra i questionari disponobili ha gia risposto, ciclando con la lista delle risposte
      await state.getAnsweredQuestionnaire(state?.user().wpUserId);
      let answeredQuest = state.answeredQuestionnaire();

      const questCompilati = filteredArray.map(questionario => {
        const completato = answeredQuest.some(risposta => risposta.wpIdQuestionary === questionario.id);
        return {
          id: questionario.id,
          nomeQuestionario: questionario.nomeQuestionario,
          descrizioneTesto: questionario.descrizioneTesto,
          completato: completato
        };
      });

      setQuestionnaires(questCompilati);
      setLoading(false);
      console.log("lista questionari disponibili indicando se ha gia risposto ", questCompilati);
    }
    let id = selectId;

    await state.getQuestionOpt(id);
    let questions = state.questionOpt(id);
    questions = reclone(questions);

    if (typeof questions === "string") {
      questions = JSON.parse(questions);
    }
    let questions2 = [];

    questions.forEach(function (q) {
      q.question_answers = q.questionAnswers;
      questions2.push(q);
    });
    setQuestions(questions2);
  };

  useEffect(() => {
    apiCall();
    state.setQuestionary(null);
  }, [selectId, state.questForAdmin()]);

  const apiCallInsert = async () => {
    try {
      const mapEspLav = answeredQuestions.map((e) => {
        const progettiJsonS = JSON.stringify({
          questiontext: e.questiontext,
          answer: e.answer,
        });
        return progettiJsonS;
      });

      let jsonArray =
        mapEspLav && mapEspLav.map((jsonString) => JSON.parse(jsonString));

      // Convert the array of objects to a valid JSON string
      let validJsonString = JSON.stringify(jsonArray, null, 2);

      const jsonString = JSON.stringify(mapEspLav);

      const combinedObject = {
        wpUserId: state.userId(),
        //dateTaken: Moment(new Date()),
        questionJson: validJsonString,
        wpIdQuestionary: selectId,
      };

      const r = await AnsweredQuestionnaire.put(combinedObject);

      setApiError(false);
      setQuestionnaireSent(true);
    } catch (e) {
      console.error(e);
      setApiError(true);
      /* props.onCardSkimPrev(document.querySelector("#skimprev-esplav"));
      props.onMessage("Errore nel salvataggio", "error", e); */
      setApiErrorSending(true);
    }
  };

  const onCardSkim = (elm) => {
    jQuery(elm).closest(".JDKReactApp-header").addClass("skimming");
    jQuery(elm).closest(".JDKReactApp").addClass("skimming");
    jQuery(elm).closest(".JDKReactApp-header").prev().addClass("visible");
  };

  const onCardSkimPrev = (elm) => {
    //console.log(elm);
    const $e = jQuery(elm).closest(".JDKReactApp-header");
    $e.removeClass("visible");
    const $enext = $e.next();
    $enext.removeClass("skimming").addClass("visible");
    jQuery(elm).closest(".JDKReactApp").removeClass("skimming");

    console.log($enext);
  };

  const sendQuestionnaireAndExit = (e) => {
    apiCallInsert();
  };

  /*   setSkipNext = (e) => {
      this.skipNext = e;
    }; */

  const onAnswerChange = (q, a) => {
    q.answer = a;
    let index = answeredQuestions.findIndex((x) => x.id === q.id);
    if (index !== -1) {
      answeredQuestions[index] = q;
    } else {
      answeredQuestions.push(q);
    }
  };

  let lastRow = true;
  let k = questions.length;
  let groupChanged = false;
  let groupChangedTmp = false;
  let groupCount = 1;
  let groupInfo = {};
  questions
    .slice(0)
    .reverse()
    .map((o, i) => {
      try {
        groupChangedTmp =
          o.questionGroup !== questions.slice(0).reverse()[i + 1].questionGroup;
      } catch (arrayOut) {
        groupChangedTmp = true;
      }
      if (groupChangedTmp) {
        groupCount++;
      }
    });

  questions
    .slice(0)
    .reverse()
    .map((o, i) => {
      try {
        groupChangedTmp =
          o.questionGroup !== questions.slice(0).reverse()[i + 1].questionGroup;
      } catch (arrayOut) {
        groupChangedTmp = true;
      }
      if (groupChangedTmp) {
        groupCount--;
        groupInfo.questionGroup = { num: groupCount };
      }
    });

  const confrontaPerOrderNum = (a, b) => a.orderNum - b.orderNum;

  questions.sort(confrontaPerOrderNum)

  const theme = useTheme();
  const matches850 = useMediaQuery('(min-width:850px)');
  const matches500 = useMediaQuery('(max-width:500px)');
  const matches850max = useMediaQuery('(max-width:850px)');

  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 70,
      renderCell: (params) => (
        <div
          style={{ cursor: "pointer" }}
          onClick={() =>
            state.user()?.accessRole.includes("ADMIN") && !state.questForAdmin()
              ? EditQuestionarie(params.row)
              : onSelectId(params.row.id)
          }
        >
          {params.row.id}
        </div>
      ),
    },
    {
      field: "nomeQuestionario",
      headerName: "Nome Questionario",
      width: matches500 ? 200 : 300,
      renderCell: (params) => (
        <b
          style={{
            cursor: "pointer",
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "left",
            alignItems: "center",
          }}
          onClick={() =>
            state.user()?.accessRole.includes("ADMIN") && !state.questForAdmin()
              ? EditQuestionarie(params.row)
              : onSelectId(params.row.id)
          }
        >
          {params.row.nomeQuestionario}
        </b>
      ),
    },
    {
      field: "descrizioneTesto",
      headerName: "Descrizione",
      width: 250,
      renderCell: (params) => (
        <div
          style={{
            cursor: "pointer",
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "left",
            alignItems: "center",
          }}
          onClick={() =>
            state.user()?.accessRole.includes("ADMIN") && !state.questForAdmin()
              ? EditQuestionarie(params.row)
              : onSelectId(params.row.id)
          }
        >
          {params.row.descrizioneTesto}
        </div>
      ),
    },
    {
      field: "completato",
      headerName: "Completato",
      width: 90,
      renderCell: (params) => (
        params.row.completato ? <DoneIcon /> : <ClearIcon />

      ),
    },
    {
      field: "elimina",
      headerName: "Elimina",
      width: 70,
      renderCell: (params) => (
        <IconButton onClick={() => handleDeleteClick(params.row)}>
          <DeleteIcon />
        </IconButton>
      ),
    },
  ];

  const columnVisibilityModel = React.useMemo(() => {
    if (state.user()?.accessRole.includes("ADMIN") && !state.questForAdmin() && matches850) {
      return {
        elimina: true,
        descrizioneTesto: true,
        id: true,
        completato: false,
      }
    } else if (state.user()?.accessRole.includes("ADMIN") && !state.questForAdmin() && !matches850) {
      return {
        elimina: true,
        descrizioneTesto: false,
        id: false,
        completato: false,
      }
    } else if ((state.questForAdmin() || !state.user()?.accessRole.includes("ADMIN")) && !matches850) {
      return {
        elimina: false,
        descrizioneTesto: false,
      }
    }
    return {
      elimina: false,
    }

  }, [state.user()]);

  const apiCallDeleteQuestionarie = async () => {
    try {
      setLoading(true);
      const response = await Questionaries.delete(selectedId.id);
      console.log("response: " + response);

      if (response.status === 200) {
        setLoading(false);
        refreshState();
      } else {
        setLoading(false);
        onMessage("Errore eliminazione questionario", "error");
      }
    } catch (error) {
      console.error("errore elimina questionario " + error);
      setLoading(false);
      onMessage("Errore eliminazione questionario", "error");
    }
  };

  const handleDeleteClick = (row) => {
    console.log("id questionario da cancellare ", row.nomeQuestionario);
    setSelectedId(row);
    setOpenModal(true);
  };

  const handleDeleteCancel = () => {
    setOpenModal(false);
  };

  const handleDeleteConfirm = () => {
    apiCallDeleteQuestionarie();
    setOpenModal(false);
  };

  const refreshState = async () => {
    console.log("refreshState");
    await state.getAllQuestionaries();
    let questionnaires = state.questionaries();
    questionnaires = reclone(questionnaires);
    setQuestionnaires(questionnaires);
  };

  const onMessage = (m, s) => {
    setSeverity(s);
    setError(true);
    setErrorMessage(m);
    setTimeout(() => {
      setError(false);
    }, 6000);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setError(false);
  };

  const EditQuestionarie = async (row) => {
    console.log("il questionario da modificare è: ", row.id);
    state.setQuestionary(row);
    navigate("/optionList/" + row.id);
  };



  return (
    <div style={{ paddingBottom: "70px" }}>
      {/*       <Card
        sx={{
          padding: "127px",
          paddingLeft: "242px",
          paddingRight: "242px",
          minWidth: 427,
          maxWidth: "92vw",
        }}
      > */}
      <h4>
        {state.user()?.accessRole.includes("ADMIN") && !state.questForAdmin()
          ? "Visualizza e modifica un questionario"
          : state.user()?.nome + ", scegli un questionario da compilare"}
      </h4>
      <div className="dataGridResponsive"
      >
        <DataGrid
          rows={questionnaires}
          getRowClassName={(params) => {
            return "pointer-cursor";
          }}
          columns={columns}
          sortModel={[
            {
              field: "id",
              sort: "desc",
            },
          ]}
          pageSize={5}
          rowsPerPageOptions={[5]}
          columnVisibilityModel={columnVisibilityModel}
          loading={loading}
          slots={
            state.user()?.accessRole.includes("ADMIN") && !state.questForAdmin()
              ? { toolbar: CustomToolbar }
              : null
          }
        />
      </div>

      {/* {state.user()?.accessRole.includes("ADMIN") &&
          <Grid container spacing={3} justifyContent="center" style={{ marginTop: 35 }}>
            <Button
              className="btn-questionaries questionaries-button"
              onClick={(e) => {
                navigate("/createQuestionnaires");
              }}
              style={{ backgroundColor: '#8376a6 !important', color: 'white' }}
            >
              Crea questionario
            </Button>
          </Grid>
        } */}

      <Dialog open={openModal} onClose={handleDeleteCancel}>
        <DialogTitle>Conferma eliminazione</DialogTitle>
        <DialogContent>
          Sei sicuro di voler eliminare il questionario '
          <b>{selectedId?.nomeQuestionario}</b>'?
          <br></br>
          <h5>Assicurarsi prima di aver eliminato tutte le domande!</h5>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCancel} color="primary">
            Annulla
          </Button>
          <Button
            onClick={handleDeleteConfirm}
            className="btn-questionaries questionaries-button"
            style={{ backgroundColor: "#8376a6 !important", color: "white" }}
          >
            Elimina
          </Button>
        </DialogActions>
      </Dialog>
      {/* </Card> */}

      {selectId !== -1 && (
        <div>
          {questions
            .slice(0)
            .reverse()
            .map((o, i) => {
              try {
                groupChanged =
                  o.questionGroup !==
                  questions.slice(0).reverse()[i + 1].questionGroup;
              } catch (arrayOut) {
                groupChanged = true;
              }
              let toret = (
                <QuestionRow
                  key={o.id}
                  groupInfo={groupInfo}
                  apiError={apiErrorSending}
                  sent={questionnaireSent}
                  /*  loading={loading} */
                  onAnswerChange={(q, a) => {
                    onAnswerChange(q, a);
                  }}
                  groupChanged={groupChanged}
                  first={i === questions.length - 1}
                  last={lastRow}
                  qlen={questions.length}
                  setSkipNext={(e) => {
                    setSkipNext(e);
                  }}
                  onQuestionnaireEnd={(e) => {
                    sendQuestionnaireAndExit(e);
                  }}
                  onCardSkimPrev={(e) => {
                    onCardSkimPrev(e);
                  }}
                  onCardSkim={(e) => {
                    onCardSkim(e);
                  }}
                  onViewSent={(e) => {
                    navigate("/questionnaire/list");
                  }}
                  q={o}
                  k={k}
                  backToQuestionaries={(e) => {
                    setSelectId(-1);
                  }}
                />
              );
              k--;
              if (lastRow) {
                lastRow = false;
              }
              return toret;
            })}
        </div>
      )}

      <Snackbar open={error} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={severity} sx={{ width: "100%" }}>
          {errorMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default QuestionnaireForm;
