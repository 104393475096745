import React, { useCallback, useEffect, useState } from "react";
import jQuery from "jquery";
import { DataGrid, GridDeleteIcon, GridToolbarContainer, GridToolbarQuickFilter } from "@mui/x-data-grid";
import { AJAX_ENDPOINT, ROLE_ADMIN, reclone } from "../commons/Constants";
import { useGlobalState } from "../store/state";
import { Navigate, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Alert, Button, Card, Dialog, DialogActions, DialogContent, DialogTitle, Snackbar, TextField, useMediaQuery, useTheme } from "@mui/material";
import { AnsweredQuestionnaire } from "../datasource/AnsweredQuestionnaire";
import { User } from "../datasource/User";
import moment from "moment";
import jsPDF from "jspdf";
import "jspdf-autotable";
import logo from "../assets/jdk-logo1.png";
import apprendoLogo from "../assets/apprendo-logo.png";
import { IconButton } from "@mui/material";

const CustomToolbar = (props) => {
    return (
        <div style={{ padding: "10px" }}>
            <GridToolbarContainer sx={{ justifyContent: "space-between" }}>
                <GridToolbarQuickFilter placeholder="Cerca..." />
                {/*  <Button onClick={() => props.openModal(true)} variant="outlined">
            Aggiungi posizione
          </Button> */}
            </GridToolbarContainer>
        </div>
    );
};

function QuestionaryUsersAnswerd(props) {
    const [answeredQuestionary, setAnsweredQuestionary] = useState([]);
    const [usersQuestionary, setUsersQuestionary] = useState([]);
    const [combinedArray, setCombinedArray] = useState([]);
    const [userSelected, setUserSelected] = useState(1);
    const [loading, setLoading] = useState(false)
    const [userAnswers, setUserAnswers] = useState([]);
    const [searchText, setSearchText] = useState("");
    const [nameSurname, setNameSurname] = useState("");
    const [email, setEmail] = useState("");
    const [numeroUtenti, setNumeroUtenti] = useState(1);
    const [selectedUserAnswers, setSelectedUserAnswers] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [severity, setSeverity] = useState("");
    const [deleteUserAnswer, setDeleteUserAnswer] = useState(false);
    const { id, title } = useParams();
    const state = useGlobalState();

    let navigate = useNavigate();

    const generatePdf = () => {
        const pdf = new jsPDF();

        if (email.endsWith("@apprendo.tech")) {
            pdf.addImage(apprendoLogo, "PNG", 150, 5, 45, 18);
        } else {
            pdf.addImage(logo, "PNG", 165, 5, 28, 18);
        }

        // Imposta il font in grassetto per il titolo
        pdf.setFont("helvetica", "bold");
        pdf.text(`Questionario: ${title}`, 15, 35);
        pdf.text(`Utente: ${nameSurname}`, 15, 45);


        // Ripristina il font normale
        pdf.setFont("helvetica", "normal");

        pdf.autoTable({
            head: [["N.", "Domanda", "Risposta"]],
            body: userAnswers.map((answer) => [
                answer.id,
                answer.questiontext,
                answer.answer,
            ]),
            startY: 55, // Posizione verticale della tabella
        });
        pdf.save(`${nameSurname}_risposte_questionario_${title}.pdf`);
    }

    useEffect(() => {
        setDeleteUserAnswer(false);
        apiCall();
    }, [deleteUserAnswer]);


    const apiCall = async () => {
        console.log("ALL");
        setLoading(true);
        AnsweredQuestionnaire.getById(id).then(async (data) => {
            setAnsweredQuestionary(data);
            const idUnici = {};

            // Filtriamo l'array tenendo solo gli oggetti con id unici
            const arraySenzaDuplicati = data.filter((oggetto) => {
                // Se l'id non esiste nell'oggetto, lo aggiungiamo all'oggetto di id unici
                if (!idUnici[oggetto.wpUserId]) {
                    idUnici[oggetto.wpUserId] = true;
                    return true;
                }
                return false;
            });
            console.log(arraySenzaDuplicati);

            const apiCalls = arraySenzaDuplicati.map(async (e) => {
                try {
                    const response = await User.get(e.wpUserId);
                    setUsersQuestionary((prev) => [...prev, response]);
                    return response;
                } catch (error) {
                    console.error("erore, ", error);
                }
            });
            const responsies = await Promise.all(apiCalls);

            const arrayCombinato = data.map((el) => {
                const utenteCorrispondente = responsies.find(
                    (utente) => utente.wpUserId === el.wpUserId
                );
                if (utenteCorrispondente) {
                    return {
                        data: moment(el.dateTaken).format("DD/MM/YYYY HH:mm"),
                        ...el,
                        nome: utenteCorrispondente.nome,
                        cognome: utenteCorrispondente.cognome,
                        email: utenteCorrispondente.email,
                    };
                }
            });
            setCombinedArray(arrayCombinato);
            setNumeroUtenti(responsies.length);
            console.log("responsies: ", responsies);
            console.log("numero utenti che hanno risposto: ", responsies.length);
            setLoading(false);
        });
    };

    const goBack = () => {
        console.log("torna indietro");
        setUserSelected(1);
        setUserAnswers(null);
    };

    const selezionaUtente = (answers) => {
        const answersWithId = answers.questionJson.map((answer, index) => ({
            ...answer,
            id: index + 1, // Aggiungi un id unico basato sull'indice della riga
        }));
        setUserAnswers(answersWithId);
        setNameSurname(answers.nome + " " + answers.cognome);
        setEmail(answers.email);
        setUserSelected(2);
    };

    console.log("le risposte dell'utente son: ", userAnswers);


    /*     useEffect(() => {
              if (usersQuestionary.length> 0 && answeredQuestionary.length>0 )  {
                  const arrayCombinato =  answeredQuestionary.map( (el) =>{
                      const utenteCorrispondente = usersQuestionary.find( (utente) => utente.wpUserId === el.wpUserId);
                      if(utenteCorrispondente){
                      return{
                          data: moment(el.dateTaken).format("DD/MM/YYYY HH:MM"),
                          ...el, nome: utenteCorrispondente.nome,
                          cognome: utenteCorrispondente.cognome,
                      }
                      }
                  })
                  setCombinedArray(arrayCombinato);
                  
      
              } 
          }, [usersQuestionary, answeredQuestionary]); */

    console.log("CombinedArray: ", combinedArray);

    const theme = useTheme();
    const matches850 = useMediaQuery('(min-width:850px)');
    const matches500 = useMediaQuery('(max-width:500px)');
    const matches850max = useMediaQuery('(max-width:850px)');

    const columnVisibilityModel = () => {
        if (matches850max && !matches500) {
            return {
                id: false
            };
        } else if (matches500 && matches850max) {
            return {
                id: false,
                data: false
            }
        }
        return {
            id: true,
            data: true
        }
    }

    const columns = [
        {
            field: "id", headerName: "ID", width: 70, renderCell: (params) => (
                <div
                    style={{
                        cursor: "pointer",
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        justifyContent: "left",
                        alignItems: "center",
                    }}
                    onClick={() =>
                        selezionaUtente(params.row)
                    }
                >
                    {params.row.id}
                </div>
            )
        },
        {
            field: "cognome", headerName: "Cognome", width: matches850max ? 130 : 250, renderCell: (params) => (
                <b
                    style={{
                        cursor: "pointer",
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        justifyContent: "left",
                        alignItems: "center",
                    }}
                    onClick={() =>
                        selezionaUtente(params.row)
                    }
                >
                    {params.row.cognome}
                </b>)
        },
        {
            field: "nome", headerName: "Nome", width: matches850max ? 130 : 250, renderCell: (params) => (
                <div
                    style={{
                        cursor: "pointer",
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        justifyContent: "left",
                        alignItems: "center",
                    }}
                    onClick={() =>
                        selezionaUtente(params.row)
                    }
                >
                    {params.row.nome}
                </div>
            )
        },
        {
            field: "data", headerName: "Data risposte", width: 150, renderCell: (params) => (
                <div
                    style={{
                        cursor: "pointer",
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        justifyContent: "left",
                        alignItems: "center",
                    }}
                    onClick={() =>
                        selezionaUtente(params.row)
                    }
                >
                    {params.row.data}
                </div>
            )
        },
        {
            field: "elimina", headerName: "Elimina", width: 80, renderCell: (params) => (
                <IconButton onClick={() => handleDeleteClick(params.row)}>
                    <GridDeleteIcon />
                </IconButton>
            ),
        },
    ];

    const columsAnswers = [
        { field: "id", headerName: "ID", width: 70 },
        {
            field: "questiontext", headerName: "Domanda", width: matches500 ? 200 : 350, renderCell: (params) => (
                <b>{params.row.questiontext}</b>)
        },
        { field: "answer", headerName: "Risposta", width: matches850max ? 150 : 200, },
    ];


    const handleDeleteClick = (row) => {
        console.log("is risposte da cancellare ", row?.id);
        setSelectedUserAnswers(row);
        setOpenModal(true);
    };

    const handleDeleteCancel = () => {
        setOpenModal(false);
    };

    const handleDeleteConfirm = () => {
        apiCallDeleteQuestionarie();
        setOpenModal(false);
    };

    const apiCallDeleteQuestionarie = async () => {
        try {
            setLoading(true);
            const response = await AnsweredQuestionnaire.delete(selectedUserAnswers.id);

            if (response.status === 200) {
                setLoading(false);
                setDeleteUserAnswer(true);
            } else {
                setLoading(false);
                onMessage("Errore eliminazione questionario utente", "error");
            }
        } catch (error) {
            console.error("errore elimina questionario utente" + error);
            setLoading(false);
            onMessage("Errore eliminazione questionario utente", "error");
        }
    };



    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setError(false);
    };


    const onMessage = (m, s) => {
        setSeverity(s);
        setError(true);
        setErrorMessage(m);
        setTimeout(() => {
            setError(false);
        }, 6000);
    };





    return (
        <>
            <div >
                {userSelected === 1 ? (
                    <>
                        <h4>Risposte degli utenti al questionario: '{title}' </h4>
                        {/* <div style={{ display: "flex", alignItems: "center", marginBottom: 10, }}>
                            <TextField
                                label="Cerca per nome o cognome"
                                value={searchText}
                                onChange={(e) => setSearchText(e.target.value)}
                                style={{ marginBottom: 10, width: '40%' }}
                            />
                            <h6 style={{ fontSize: '14px', marginLeft: 'auto' }}>utenti che hanno risposto:{numeroUtenti}</h6>
                        </div> */}
                        <div className="dataGridUserAnswer" style={{ paddingBottom: "50px" }} >
                            <DataGrid
                                rows={combinedArray.filter((row) =>
                                    row.nome.toLowerCase().includes(searchText.toLowerCase()) ||
                                    row.cognome.toLowerCase().includes(searchText.toLowerCase())
                                )}
                                columns={columns}
                                sortModel={[
                                    {
                                        field: 'cognome',
                                        sort: 'asc',
                                    },
                                ]}
                                pageSize={5}
                                rowsPerPageOptions={[5]}
                                loading={loading}
                                /* onRowClick={(params) => {
                                    const answersUser = params.row
                                    selezionaUtente(answersUser);
                                }} */
                                slots={{ toolbar: CustomToolbar }}
                                getRowClassName={(params) => {
                                    return 'pointer-cursor';
                                }}
                                columnVisibilityModel={columnVisibilityModel()}

                            >
                            </DataGrid>
                            <Button style={{ marginTop: "15px" }}
                                variant="outlined"
                                color="primary"
                                className="btn btn-success"
                                onClick={() => navigate(-1)}
                            >
                                indietro
                            </Button>
                        </div>

                        <Dialog open={openModal} onClose={handleDeleteCancel}>
                            <DialogTitle>Conferma eliminazione</DialogTitle>
                            <DialogContent>
                                Sei sicuro di voler eliminare il questionario compilato di <b>{selectedUserAnswers?.nome} {selectedUserAnswers?.cognome}</b>?
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleDeleteCancel} color="primary">
                                    Annulla
                                </Button>
                                <Button
                                    onClick={handleDeleteConfirm}
                                    className="btn-questionaries questionaries-button"
                                    style={{ backgroundColor: "#8376a6 !important", color: "white" }}
                                >
                                    Elimina
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </>
                ) : (
                    <div >
                        <h4>Risposte di '{nameSurname}' per il questionario: '{title}' </h4>
                        <div className="dataGridUserAnswer" style={{ paddingBottom: "50px" }}>
                            <DataGrid
                                rows={userAnswers}
                                columns={columsAnswers}
                                sortModel={[
                                    {
                                        field: 'id',
                                        sort: 'asc',
                                    },
                                ]}
                                pageSize={5}
                                rowsPerPageOptions={[5]}
                                columnVisibilityModel={columnVisibilityModel()}
                            >
                            </DataGrid>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-evenly",
                                    marginTop: "20px",
                                }}
                            >
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    className="btn btn-success"
                                    onClick={() => goBack()}
                                >
                                    indietro
                                </Button>

                                <Button
                                    className="btn-questionaries questionaries-button"
                                    onClick={() => generatePdf()}
                                >
                                    Genera PDF
                                </Button>
                            </div>
                        </div>

                    </div>
                )}
                <Snackbar open={error} autoHideDuration={6000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity={severity} sx={{ width: "100%" }}>
                        {errorMessage}
                    </Alert>
                </Snackbar>
            </div>
        </>
    )
}
export default QuestionaryUsersAnswerd;
