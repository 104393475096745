import React, { useEffect, useState } from "react";
import { User } from "../datasource/User";
import { useNavigate, useParams } from "react-router-dom";
import { Positions } from "../datasource/Positions";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Buffer } from "buffer";
import moment from "moment";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const DettagliUtente = () => {
  const { id } = useParams();
  const [profile, setProfile] = useState(null);
  const [posizione, setPosizione] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const navigate = useNavigate();

  const labelStyle = {
    fontWeight: "bold",
    marginRight: "8px",
  };

  const contentStyle = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
    padding: "25px",
    width: "100%",
  };

  useEffect(() => {
    User.get(id).then((resp) => setProfile(resp));
  }, []);

  useEffect(() => {
    if (profile) {
      Positions.get(profile?.posizioneRicopertaId).then((data) =>
        setPosizione(data.nome)
      );
      if (profile.imageBase64) {
        const cleanBase64String = profile.imageBase64.replace(
          /^\"data:image\/(jpeg|png);base64,/,
          ""
        );
        const convertBase64ToBlob = (base64) => {
          const binaryString = Buffer.from(base64, "base64").toString("binary");
          const byteArray = new Uint8Array(binaryString.length);
          for (let i = 0; i < binaryString.length; i++) {
            byteArray[i] = binaryString.charCodeAt(i);
          }
          return new Blob([byteArray], { type: "image/jpeg" });
        };
        const blob = convertBase64ToBlob(cleanBase64String);
        setImageUrl(URL.createObjectURL(blob));
      }
    }
  }, [profile]);


  const theme = useTheme();
  const matches500 = useMediaQuery('(max-width:500px)');
  return (
    <div style={!matches500 ? { display: "flex", width:"100%"  } : {display: "flex", flexDirection: "column", alignItems: "center" }}>
      <div>
        <Button onClick={() => navigate(-1)} variant="outlined">
          <ArrowBackIcon fontSize="large" />
        </Button>
      </div>
      <div style={{ width: "90%", display: "flex", justifyContent:"center" }}>
        <Card
          sx={{ minWidth: "400px", maxWidth: "450px"}}
        >
          <CardHeader
            avatar={<Avatar sx={{ width: 100, height: 100 }} src={imageUrl} />}
            title={
              <Typography variant="h4">
                {profile?.nome} {profile?.cognome}
              </Typography>
            }
            subheader={<Typography>{profile?.email}</Typography>}
          />
          <CardContent style={contentStyle}>
            <Box
              display={"flex"}
              flexDirection={"column"}
              gap={"20px"}
              textAlign="left"
            >
              <Typography variant="body1">
                <span style={labelStyle}>Posizione ricoperta:</span> {posizione}
              </Typography>
              <Typography variant="body1">
                <span style={labelStyle}>Telefono:</span> {profile?.tel}
              </Typography>
              <Typography variant="body1">
                <span style={labelStyle}>Data di nascita:</span>{" "}
                {profile?.dataNasc
                  ? moment(profile?.dataNasc).format("DD/MM/YYYY")
                  : ""}
              </Typography>
              <Typography variant="body1">
                <span style={labelStyle}>Nazionalità:</span>{" "}
                {profile?.nazionalita}
              </Typography>
              <Typography variant="body1">
                <span style={labelStyle}>Città:</span> {profile?.citta}
              </Typography>
              <Typography variant="body1">
                <span style={labelStyle}>Cap:</span> {profile?.cap}
              </Typography>
              <Typography variant="body1">
                <span style={labelStyle}>Sesso:</span> {profile?.sesso}
              </Typography>
              <Typography variant="body1">
                <span style={labelStyle}>Ruolo:</span> {profile?.accessRole.toLowerCase()}
              </Typography>
            </Box>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default DettagliUtente;
