import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import TextField from "@mui/material/TextField";
import jQuery from "jquery";
import { clone, debounce } from 'lodash';
import Moment from 'moment';
import React, { useCallback, useEffect, useState } from "react";
import { AJAX_ENDPOINT, reclone } from "../commons/Constants";
import CVUpdateCompetenzeForm from './CVUpdateCompetenzeForm';
import Box from "@mui/material/Box";
import { Button } from "@mui/material";
import { useGlobalState } from '../store/state';
import { useNavigate } from 'react-router';
import { CVCompetenzeTecniche } from '../datasource/CVCompetenzeTecniche';


function stripHtml(html) {
  let tmp = document.createElement("DIV");
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || "";
}

function truncate( str, n, useWordBoundary ){
  if (str.length <= n) { return str; }
  const subString = str.substr(0, n-1); // the original check
  return (useWordBoundary 
    ? subString.substr(0, subString.lastIndexOf(" ")) 
    : subString) + "...";
};

function CompentenzeTecniche(props) {
  // const [count, setCount] = React.useState(0);
  const [competenza, setCompetenza] = useState([]);
  const [touched, setTouched] = useState(false);
  let navigate = useNavigate();

  const state = useGlobalState();

  const [lockSorting, setLockSorting] = useState(false);

  const newEsp = () => {
   //debugger;
   competenza.push({
      wpUserId: state.user()?.wpUserId,
      id: Math.random(),
      dummy: true,
      descrizione: ""
    });
    refreshState();
  };

  const newEspTecniche = () => {
    //debugger;
    competenza.push({
       wpUserId: state.user()?.wpUserId,
       id: Math.random(),
       dummy: true,
       descrizione: ""
     });
     refreshState();
   };


  const deleteItemTech = async(i) => {
       const splitId = competenza.splice(i, 1);
        setTouched(true);
        await CVCompetenzeTecniche.delete(splitId[0].id);
        refreshState();  
  }; 

  const sortUp = (i) => {
    if (i>0 && !lockSorting){
      let tmp = competenza[i-1];
      competenza[i-1]=competenza[i];
      competenza[i] = tmp;
      setTouched(true);
      refreshState();
    }
  };

  const sortDown = (i) => {
    if (i<competenza.length-1 && !lockSorting){
      let tmp = competenza[i+1];
      competenza[i+1]=competenza[i];
      competenza[i] = tmp;
      setTouched(true);
      refreshState();
    }
  }


  const refreshState = () => {
    setCompetenza(clone(competenza));
    //setLockSorting(false);
  }

  const _refreshState = () => {
    setCompetenza(clone(competenza));
    setLockSorting(false);
  }

  const refreshStateDebounced = useCallback(
    debounce(refreshState, 30)
  , [competenza]);

  const refreshStateDebouncedLarge = useCallback(
    debounce(_refreshState, 5000)
  , [competenza]);


  const _refreshStateDebounced = () => {
    setCompetenza(clone(competenza));
  }

  const apiCall = async () => {
    await state.getCompetenzeTecniche();
    let competenza = state.competenzaTxt();
    competenza = reclone(competenza)
    setCompetenza(competenza);
  };

  const apiCallSave = async () => {
    try{
    const modifiedEsp = competenza.filter((o)=> o.touched===true);
    let promises = [];
    for (let i = 0; i < modifiedEsp.length; i++) {
      modifiedEsp[i].touched = false;
      promises.push(CVCompetenzeTecniche.put(modifiedEsp[i]));
    }

    await Promise.all(promises);
    state.getCompetenzeTecniche();
    props.onMessage("Salvataggio effettuato", "success");
    //redirect
    //navigate("/");
    }catch(e){
      console.error(e);
      props.onCardSkimPrev(document.querySelector("#skimprev-esplav"));
      props.onMessage("Errore nel salvataggio", "error");
    }
  };

  useEffect(() => {
    apiCall();
  }, []);


  return (
    <div className="JDKReactApp-header visible">
      <h3>Controlla le tue informazioni sulle tue competenze</h3>
        {competenza.length > 0 ? (
          <section className="formcontainer customscrollbar" style={{height: '100%', width: '100%'}}>
            <h4>Competenze tecniche:</h4>
            {competenza.map((o, i) => {
              const key = o.id;//JSON.stringify(o);//Math.random()+""+Date.now();
              return (
                <Accordion className="lingue-accordion" key={i}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <div className="accordion-header-jdk">
                      <div className="accordion-header-item">
                        <div className="aheader">#</div>
                        {i + 1}
                      </div>
                      <div className="accordion-header-item">
                        <div className="aheader">Competenza</div>
                        {o.competenze}
                      </div>
                      <div className="accordion-header-item descrizione">
                        <div className="aheader">Azioni</div>
                        <span className="accordion-action" title="Sposta su" onClick={(e) => { e.preventDefault(); e.stopPropagation(); sortUp(i) }}><ArrowDropUpIcon /></span>
                        <span className="accordion-action" title="Sposta giù" onClick={(e) => { e.preventDefault(); e.stopPropagation(); sortDown(i) }}><ArrowDropDownIcon /></span>
                        <span className="accordion-action" title="Elimina definitiviamente" onClick={(e) => { e.preventDefault(); e.stopPropagation(); deleteItemTech(i) }}><DeleteForeverIcon /></span>
                      </div>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <TextField
                      key={o.id}
                      multiline
                      onChange={(e) => {
                        console.log("e", e)
                        o.competenze = e.target.value;
                        o.touched = true;
                      }}
                      name="compentenze"
                      defaultValue={o.competenze}
                    />
                  </AccordionDetails>
                </Accordion>
              );
            })}
          </section>)
          : <div>Nessuna competenza aggiunta...</div>
        }

      <nav>
        <Button
          variant="outlined"
          color="primary"
          onClick={(e) => {
            e.preventDefault();
            props.onCardSkimPrev(e.target);
          }}
        >
          Indietro
        </Button>{" "}
        &nbsp;
        <Button
          variant="outlined"
          color="primary"
          onClick={(e) => {
            e.preventDefault();
            newEspTecniche();
          }}
        >
          Aggiungi altre compentenza
        </Button>{" "}
        <Button
          variant="outlined"
          color="primary"
          onClick={(e) => {
            e.preventDefault();
            props.onCardSkim(e.target);
            apiCallSave();
          }}
        >
          Avanti
        </Button>
      </nav>

    </div>
  );
}

export default CompentenzeTecniche;
