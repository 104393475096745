import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import jQuery from "jquery";
import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import Stack from '@mui/material/Stack';
import {AJAX_ENDPOINT} from "../commons/Constants";
import DocsList from "./DocsList";
import DocsNew from "./DocsNew";


const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function DocsApp(props) {
 // const [count, setCount] = React.useState(0);
 
const [loading, setLoading] = React.useState(true);
const [isAdmin,setIsAdmin] = useState(false);
const [error,setError] = useState(false);
const [errorMessage,setErrorMessage] = useState("");
const [severity,setSeverity] = useState("");

const handleClose = (event, reason) => {
  if (reason === 'clickaway') {
    return;
  }

  setError(false);
};

const onMessage = (m,s)=>{setSeverity(s); setError(true); setErrorMessage(m);};


const apiCall = () => {
  jQuery
    .ajax({
      url: AJAX_ENDPOINT,
      data: {
        action: "jdk_inet_isuseradmin",
      },
      dataType: "json",
    })
    .done(function (response) {
       console.log(response);
       setIsAdmin(response.auth);
       setLoading(false)
    }).fail(function (){
      setLoading(false)
      console.log("error");
    });
};

 useEffect(() =>{apiCall();},[props]);


  return (
    <div>
      {loading ? "Caricando..." : 
        props.isSingleDocView ? <>Single doc view here</>:
        props.isNewDocView ? <DocsNew/>:
        <DocsList isAdmin={isAdmin}></DocsList>
      }
      <Snackbar open={error} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
          {errorMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default DocsApp;
