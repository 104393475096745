

import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import * as React from "react";

function QuestionOpenSmall(props) {
  const [answer, setAnswer] = React.useState("");

  const handleChange = (event) => {
    setAnswer(event.target.value);
    props.onAnswerChange(event.target.value);
  };

  //console.log(props.q.question_answers);
  return (
    <Box sx={{ minWidth: 120 }}>
      <TextField onChange={handleChange} label="Risposta" variant="outlined" />
    </Box>
  );
}


  export default QuestionOpenSmall;