import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  Card,
  CardContent,
  TextField,
  Button,
  Grid,
  InputAdornment,
  IconButton,
  Typography,
} from "@mui/material";
import { useGlobalState } from "../store/state";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { User } from "../datasource/User";

const schema = yup.object({
  password: yup
    .string()
    .required("La password è obbligatoria")
    .min(8, "La password deve contenere almeno 8 caratteri")
    .max(14, "La password deve contenere massimo 14 caratteri")
    .matches(
      /^(?=.*[A-Z])(?=.*[0-9])(?=.*[-_.#@*])\S+$/,
      "La password deve contenere almeno una lettera maiuscola, un numero e uno dei seguenti caratteri speciali: - _ . # @ *"
    ),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Le password devono corrispondere")
    .required("Conferma password è obbligatoria"),
  oldPassword: yup.string().required("La vecchia password è obbligatoria"),
});

const ModifiedPassword = ({ onMessage, setModifiedPassword, matches }) => {
  const state = useGlobalState();
  const userId = state.userId();
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isConfirmedPasswordVisible, setIsConfirmedPasswordVisible] =
    useState(false);
  const [isOldPasswordVisible, setIsOldPasswordVisible] = useState(false);

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleTogglePasswordVisibility = () => {
    setIsPasswordVisible((prev) => !prev);
  };

  const handleToggleConfirmedPasswordVisibility = () => {
    setIsConfirmedPasswordVisible((prev) => !prev);
  };

  const handleToggleOldPasswordVisibility = () => {
    setIsOldPasswordVisible((prev) => !prev);
  };

  const onSubmit = (data) => {
    console.log("Nuova password:", data.password);
    User.putPassword(userId, data.password, data.oldPassword)
      .then((resp) => {
        console.log(resp);
        if (resp.status === 200) {
          state.logout();
        } else {
          onMessage("Qualcosa è andato storto", "error");
        }
      })
      .catch((err) => {
        onMessage(err.message, "error");
      });
  };

  const handleCancel = () => {
    setModifiedPassword(false);
  };

  return (
    <Card style={{ width: "100%" }}>
      <CardContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3} direction="column">
            <Grid item>
              <Typography variant="body2" color="textSecondary">
                Modificando la password verrai reindirizzato al login.
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="oldPassword"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Vecchia password"
                    type={isOldPasswordVisible ? "text" : "password"}
                    variant="outlined"
                    sx={{ width: matches ? "400px" : null }}
                    error={!!errors.oldPassword}
                    helperText={errors.oldPassword?.message}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={handleToggleOldPasswordVisibility}
                          >
                            {isOldPasswordVisible ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="password"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Nuova password"
                    type={isPasswordVisible ? "text" : "password"}
                    sx={{ width: matches ? "400px" : null }}
                    variant="outlined"
                    error={!!errors.password}
                    helperText={errors.password?.message}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={handleTogglePasswordVisibility}>
                            {isPasswordVisible ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="confirmPassword"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Conferma Password"
                    type={isConfirmedPasswordVisible ? "text" : "password"}
                    variant="outlined"
                    sx={{ width: matches ? "400px" : null}}
                    error={!!errors.confirmPassword}
                    helperText={errors.confirmPassword?.message}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={handleToggleConfirmedPasswordVisibility}
                          >
                            {isConfirmedPasswordVisible ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
            <Grid display={"flex"} justifyContent={"center"} item gap={"40px"}>
              <Button
                type="button"
                onClick={handleCancel}
                variant="outlined"
                color="primary"
              >
                Annulla
              </Button>
              <Button
                type="submit"
                className="btn-questionaries questionaries-button"
              >
                Conferma
              </Button>
            </Grid>
          </Grid>
        </form>
      </CardContent>
    </Card>
  );
};

export default ModifiedPassword;
