import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Box,
  Button,
  CardActionArea,
} from "@mui/material";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const UserProfileCard = ({
  position,
  phone,
  birthDate,
  nationality,
  citta,
  sesso,
  setIsModified,
  setModifiedPassword,
}) => {
  const navigate = useNavigate();
  const cardStyle = {
    height: "auto",
    width: "100%",
  };

  const contentStyle = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
    flexWrap: "wrap",
    padding: "25px",
    width: "100%",
  };

  const labelStyle = {
    fontWeight: "bold",
    marginRight: "8px",
  };

  return (
    <Card style={cardStyle}>
      <CardContent style={contentStyle}>
        <Box
          display={"flex"}
          flexDirection={"column"}
          gap={"10px"}
          textAlign="left"
          marginLeft="10px"
        >
          <Typography variant="body1" >
            <span style={labelStyle}>Posizione ricoperta:</span> {position}
          </Typography>
          <Typography variant="body1">
            <span style={labelStyle}>Telefono:</span> {phone}
          </Typography>
          <Typography marginBottom="10px" variant="body1">
            <span style={labelStyle}>Data di nascita:</span>{" "}
            {birthDate ? moment(birthDate).format("DD/MM/YYYY") : ""}
          </Typography>
        </Box>
        <Box display={"flex"} flexDirection={"column"} textAlign="left" gap={"10px"} marginRight="10px">
          <Typography variant="body1">
            <span style={labelStyle}>Nazionalità:</span> {nationality}
          </Typography>
          <Typography variant="body1">
            <span style={labelStyle}>Città:</span> {citta}
          </Typography>
          <Typography variant="body1">
            <span style={labelStyle}>Sesso:</span> {sesso}
          </Typography>
        </Box>
      </CardContent>
      <CardActionArea sx={{ display: "flex", justifyContent: "center", gap: "20px", flexWrap:"wrap" , padding: "10px"}}>
        <Button
          variant="outlined"
          color="primary"
          className="btn btn-success"
          onClick={() => navigate("/")}
        >
          Indietro
        </Button>
        <Button
          className="btn-questionaries questionaries-button"
          onClick={() => setIsModified(true)}
        >
          Modifica Profilo
        </Button>
        <Button
          className="btn-questionaries questionaries-button"
          onClick={() => setModifiedPassword(true)}
        >
          Modifica Password
        </Button>
      </CardActionArea>
    </Card>
  );
};

export default UserProfileCard;
