import axios from "axios";
import {
  AJAX_ENDPOINT,
  FAKE_USER_ID,
  getHeaders,
  reclone,
} from "../commons/Constants";

export class AnsweredQuestionnaire {
  static async get(id) {
    const HEADERS = getHeaders();
    const resp = await axios.get(
      AJAX_ENDPOINT + "inet-jdk/answered-questionary/get-answerd/" + id,
      {
        headers: HEADERS,
      }
    );
    if (resp.data.sesso === "M") {
      resp.data.sesso = "Maschile";
    }
    return resp.data;
  }

  static async getAll() {
    const HEADERS = getHeaders();
    const resp = await axios.get(
      AJAX_ENDPOINT + "inet-jdk/answered-questionary/all",
      {
        headers: HEADERS,
      }
    );
    if (resp.data.sesso === "M") {
      resp.data.sesso = "Maschile";
    }
    return resp.data;
  }

  static async getById(id) {
    const HEADERS = getHeaders();
    const resp = await axios.get(
      AJAX_ENDPOINT +
        "inet-jdk/answered-questionary/get-by-questionary-id/" +
        id,
      {
        headers: HEADERS,
      }
    );
    if (resp.data.sesso === "M") {
      resp.data.sesso = "Maschile";
    }
    return resp.data;
  }

  static async getByIdQuestionary(id) {
    const HEADERS = getHeaders();
    const resp = await axios.get(
      AJAX_ENDPOINT +
        "inet-jdk/answered-questionary/get-users-by-questionary-id/" +
        id,
      {
        headers: HEADERS,
      }
    );
    if (resp.data.sesso === "M") {
      resp.data.sesso = "Maschile";
    }
    return resp.data;
  }

  static async put(answeredQuestionnaire) {
    const HEADERS = getHeaders();
    answeredQuestionnaire = reclone(answeredQuestionnaire);
    let resp;
    if (!answeredQuestionnaire.id) {
      delete answeredQuestionnaire.id;
      resp = await axios.post(
        AJAX_ENDPOINT + "inet-jdk/answered-questionary/create",
        answeredQuestionnaire,
        {
          headers: HEADERS,
        }
      );
      const responseData = resp.data;
      const idSin = responseData?.id;
      return idSin;
    } else {
      resp = await axios.put(
        AJAX_ENDPOINT +
          "inet-jdk/answered-questionary/update/" +
          answeredQuestionnaire.id,
        answeredQuestionnaire,
        {
          headers: HEADERS,
        }
      );
    }
    return resp;
  }

  static async delete(id) {
    const HEADERS = getHeaders();
    const resp = await axios.delete(
      AJAX_ENDPOINT + "inet-jdk/answered-questionary/deleteById/" + id,
      { headers: HEADERS }
    );
    return resp;
  }
}
