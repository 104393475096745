import React, { useState } from "react";
import Cards from "./card/Cards";

function Home(props) {

  return (
      <Cards />
  );
}

export default Home;
