import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  TextField,
  Button,
  Grid,
  Container,
  Typography,
  Snackbar,
  Alert,
  InputAdornment,
  IconButton,
  CircularProgress,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { User } from "../datasource/User";
import { Link, useNavigate } from "react-router-dom";
import { useGlobalState } from "../store/state";
import { FAKE_USER_ID, reclone } from "../commons/Constants";

const schema = yup.object({
  email: yup
    .string()
    .email("Inserisci un indirizzo email valido")
    .required("L'email è obbligatoria")
    .test(
      "email",
      "L'indirizzo email deve essere di tipo jdk o apprendo ",
      (value) => {
        if (!value) return true; // La validazione non è richiesta se l'input è vuoto
        const regex = /^[a-zA-Z0-9._%+-]+@(jdk\.it|apprendo\.tech)$/;
        return regex.test(value);
      }
    ),
  password: yup
    .string()
    .required("La password è obbligatoria")
    .min(6, "La password deve essere di almeno 6 caratteri"),
});
const RegisterPage = () => {
  const navigate = useNavigate();
  const state = useGlobalState();
  const isAuthenticated = state.authenticated();
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setError(false);
  };

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/");
    }
  }, [isAuthenticated]);

  const onMessage = (m, s) => {
    setSeverity(s);
    setError(true);
    setErrorMessage(m);
    setTimeout(() => {
      setError(false);
    }, 6000);
  };

  const handleTogglePasswordVisibility = () => {
    setIsPasswordVisible((prev) => !prev);
  };

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmitFecth = async (data) => {
    setLoading(true);
    const email = data.email.trim();
    const password = data.password.trim();
    try {
      User.getToken(email)
        .then((resp) => {
          if (resp) {
            User.login(resp, email, password)
              .then((data) => {
                console.log("token della resp", data.token);
                localStorage.setItem("auth_token", data.token);
                state.setToken(data.token);
              })
              .catch((err) => {
                onMessage("Email o password errati", "error");
                setLoading(false);
              })
              .then(() => {
                User.getByEmail(state.token(), data.email).then((resp) => {
                  if (resp) {
                    const user = reclone(resp);
                    state.setUser(user);
                    localStorage.setItem("userId", user.wpUserId);
                    state.setUserId(user.wpUserId);
                    state.setAuthenticated(true);
                    /*  window.location.reload(); */
                  } else {
                    setLoading(false);
                    onMessage("Ops qualcosa è andato storto", "error");
                  }
                });
              });
            setLoading(false);
          } else {
            onMessage("Email non esistente", "error");
            setLoading(false);
          }
        })
        .catch((err) => {
          onMessage(err.message, "error");
          setLoading(false);
        });
    } catch (e) {
      console.error(e);
      setLoading(false);
      onMessage("Errore nel login", "error");
    }
  };

  const onSubmit = (data) => {
    onSubmitFecth(data);
  };

  /*   if (loading) {
    return <CircularProgress size={70} />;
  } */

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Login
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} sm={7}>
            <Controller
              name="email"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Email"
                  variant="outlined"
                  fullWidth
                  error={!!errors.email}
                  helperText={errors.email?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={7}>
            <Controller
              name="password"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Password"
                  type={isPasswordVisible ? "text" : "password"}
                  fullWidth
                  variant="outlined"
                  error={!!errors.password}
                  helperText={errors.password?.message}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={handleTogglePasswordVisibility}>
                          {isPasswordVisible ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              type="submit"
              variant="outlined"
              color="primary"
              className="btn-questionaries questionaries-button"
            >
              {loading ? <CircularProgress size={28} /> : "Accedi"}
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Typography>
              Non sei registrato? <Link to={"/register"}>Registrati</Link>
            </Typography>
          </Grid>
        </Grid>
      </form>
      <Snackbar open={error} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={severity} sx={{ width: "100%" }}>
          {errorMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default RegisterPage;
