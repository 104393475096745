import TextField from "@mui/material/TextField";
import { clone, debounce, merge } from "lodash";
import Moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { AJAX_ENDPOINT, reclone } from "../commons/Constants";
import Box from "@mui/material/Box";
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { useGlobalState } from "../store/state";
import { useNavigate } from "react-router";
import { CVInfoAgency } from "../datasource/CVInfoAgency";
import Input from "@mui/material/Input";
import CardMedia from "@mui/material/CardMedia";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { User } from "../datasource/User";

function InfoAgency(props) {
  const [infoAgency, setInfoAgency] = useState([]);
  const [touched, setTouched] = useState(false);
  const [lockSorting, setLockSorting] = useState(false);
  const [allInfoAgencies, setAllInfoAgencies] = useState([]);
  let navigate = useNavigate();

  const state = useGlobalState();

  const deleteItem = async (i) => {
    /* da capire */
    console.log("delete infoAgency", infoAgency);
    const splitId = infoAgency?.id;
    setTouched(true);
    await CVInfoAgency.delete(splitId.id);
    refreshState();
  };

  const sortUp = (i) => {
    if (i > 0 && !lockSorting) {
      let tmp = infoAgency[i - 1];
      infoAgency[i - 1] = infoAgency[i];
      infoAgency[i] = tmp;
      setTouched(true);
      refreshState();
    }
  };

  const sortDown = (i) => {
    if (i < infoAgency.length - 1 && !lockSorting) {
      let tmp = infoAgency[i + 1];
      infoAgency[i + 1] = infoAgency[i];
      infoAgency[i] = tmp;
      setTouched(true);
      refreshState();
    }
  };

  const refreshState = () => {
    setInfoAgency(clone(infoAgency));
  };

  const _refreshState = () => {
    setInfoAgency(clone(infoAgency));
  };

  const refreshStateDebounced = useCallback(debounce(refreshState, 30), [
    infoAgency,
  ]);

  const refreshStateDebouncedLarge = useCallback(
    debounce(_refreshState, 5000),
    [infoAgency]
  );

  const _refreshStateDebounced = () => {
    setInfoAgency(clone(infoAgency));
  };

  const apiCall = async () => {
    /* await state.getInfoAgency();
        let infoAgency = state.infoAgency();
        infoAgency = reclone(infoAgency)
        setInfoAgency(infoAgency); */
    User.getInfoAgencies().then((infoAgency) => setAllInfoAgencies(infoAgency));
  };

  const handleImageChange = (e, index) => {
    console.log("eecsdcf", e);
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        infoAgency.imageBase64 = reader?.result;
        infoAgency.touched = true;
        refreshStateDebounced();
      };
      reader.readAsDataURL(file);
    }
  };

  const handleChangeSelect = (e) => {
    console.log(e.target.value);
    const selectAgency = allInfoAgencies.filter(
      (infoAgency) => infoAgency.id === e.target.value
    );
    setInfoAgency(selectAgency[0]);
  };
  // Function to convert base64 to Blob using fetch and Blob constructor

  const apiCallSave = async () => {
    try {
      /*       const modifiedEsp = infoAgency.touched === true;

      if (modifiedEsp) {
        const mapEspLav = {
          capitaleSociale: infoAgency.capitaleSociale,
          codiceUnivoco: infoAgency.codiceUnivoco,
          indirizzo: infoAgency.indirizzo,
          nome: infoAgency.nome,
          partitaIvaCodiceFiscale: infoAgency.partitaIvaCodiceFiscale,
          sedeOperativa: infoAgency.sedeOperativa,
          imageBase64: infoAgency.imageBase64,
          rea: infoAgency.rea,
          id: infoAgency.id,
        };
        const resp = await CVInfoAgency.put(mapEspLav); 
    }*/
      state.setInfoAgency(infoAgency);
      props.onMessage("Salvataggio effettuato", "success");
    } catch (e) {
      console.error(e);
      props.onCardSkimPrev(document.querySelector("#skimprev-esplav"));
      props.onMessage("Errore nel salvataggio", "error");
    }
  };

  useEffect(() => {
    apiCall();
  }, []);


  return (
    <div className="JDKReactApp-header ">
      <h3>Scegli le informazioni sull'azienda</h3>
      <section
        className="formcontainer customscrollbar"
        style={{ height: "100%", width: "100%" }}
      >
        <Accordion key={infoAgency.id}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <div className="accordion-header-jdk">
              <div className="accordion-header-item">
                <div className="aheader">#</div>
                {infoAgency.id}
              </div>
              <div className="accordion-header-item">
                <div className="aheader">Nome</div>
                {infoAgency.nome}
              </div>
              <div className="accordion-header-item">
                <div className="aheader">indirizzo</div>
                {infoAgency.indirizzo}
              </div>
              <div className="accordion-header-item">
                <div className="aheader">PartitaIva/ CodiceFiscale</div>
                {infoAgency.partitaIvaCodiceFiscale}
              </div>
              <div className="accordion-header-item descrizione">
                <div className="aheader">sedeOperativa</div>
                {infoAgency.sedeOperativa}
              </div>
              {/* <div className="accordion-header-item descrizione">
                <div className="aheader">Azioni</div>

                <span
                  className="accordion-action"
                  title="Sposta su"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    sortUp(infoAgency.id);
                  }}
                >
                  <ArrowDropUpIcon />
                </span>
                <span
                  className="accordion-action"
                  title="Sposta giù"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    sortDown(infoAgency.id);
                  }}
                >
                  <ArrowDropDownIcon />
                </span>
                <span
                  className="accordion-action"
                  title="Elimina definitiviamente"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    deleteItem(infoAgency.id);
                  }}
                >
                  <DeleteForeverIcon />
                </span>
              </div> */}
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <Box
              component="form"
              sx={{
                "& .MuiTextField-root": { m: 1, width: "305ch" },
              }}
              autoComplete="off"
            >
              <FormControl fullWidth variant="outlined">
                <InputLabel htmlFor="Azienda">Scegli l'azienda</InputLabel>
                <Select
                  defaultValue={infoAgency.id || null}
                  onChange={handleChangeSelect}
                  label="Azienda"
                  inputProps={{
                    name: "agencies",
                    id: "agencies",
                  }}
                >
                  {allInfoAgencies?.map((el, i) => (
                    <MenuItem key={i} value={el.id}>
                      {el.nome}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {/*   <TextField
                key={infoAgency.nome}
                onChange={(e) => {
                  infoAgency.nome = e.target.value;
                  infoAgency.touched = true;
                }}
                name="nome"
                label="Nome"
                defaultValue={infoAgency.nome}
              />
              <TextField
                key={infoAgency.sedeOperativa}
                onChange={(e) => {
                  infoAgency.sedeOperativa = e.target.value;
                  infoAgency.touched = true;
                }}
                name="sedeOperativa"
                label="sedeOperativa"
                defaultValue={infoAgency.sedeOperativa}
              />
              <TextField
                key={infoAgency.indirizzo}
                onChange={(e) => {
                  infoAgency.indirizzo = e.target.value;
                  infoAgency.touched = true;
                }}
                name="indirizzo"
                label="indirizzo"
                defaultValue={infoAgency.indirizzo}
              />
              <TextField
                key={infoAgency.partitaIvaCodiceFiscale}
                onChange={(e) => {
                  infoAgency.partitaIvaCodiceFiscale = e.target.value;
                  infoAgency.touched = true;
                }}
                name="partitaIvaCodiceFiscale"
                label="partitaIvaCodiceFiscale"
                defaultValue={infoAgency.partitaIvaCodiceFiscale}
              />
              <TextField
                key={infoAgency.codiceUnivoco}
                onChange={(e) => {
                  infoAgency.codiceUnivoco = e.target.value;
                  infoAgency.touched = true;
                }}
                name="codiceUnivoco"
                label="codiceUnivoco"
                defaultValue={infoAgency.codiceUnivoco}
              />
              <TextField
                key={infoAgency.capitaleSociale}
                onChange={(e) => {
                  infoAgency.capitaleSociale = e.target.value;
                  infoAgency.touched = true;
                }}
                name="capitaleSociale"
                label="capitaleSociale"
                defaultValue={infoAgency.capitaleSociale}
              />
              <TextField
                key={infoAgency.rea}
                onChange={(e) => {
                  infoAgency.rea = e.target.value;
                  infoAgency.touched = true;
                }}
                name="rea"
                label="rea"
                defaultValue={infoAgency.rea}
              /> */}
              {/*         <Grid container spacing={3} justifyContent="center" style={{ marginTop: 20 }}>
                                <Grid item xs={7}>
                                    <h5>Carica il logo della tua azienda</h5>
                                    <Input
                                        id="file-upload-label"
                                        type="file"
                                        accept="image/*"
                                        onChange={(e) => handleImageChange(e, 0)}
                                        style={{ display: 'none' }}
                                    />
                                    <label htmlFor="file-upload-label">
                                        <Button variant="contained" component="span">
                                            Upload
                                        </Button>
                                    </label>
                                </Grid>
                                <Grid item xs={7}>
                                    {infoAgency?.imageBase64 && (
                                        <div>
                                            <h5>Anteprima logo azienda:</h5>
                                            <CardMedia
                                                component="img"
                                                alt="Preview"
                                                height="200"
                                                image={infoAgency.imageBase64}
                                            />
                                        </div>
                                    )}
                                </Grid>
                            </Grid> */}
            </Box>
          </AccordionDetails>
        </Accordion>
      </section>
      <nav>
        <Button
          variant="outlined"
          color="primary"
          onClick={(e) => {
            e.preventDefault();
            props.onCardSkimPrev(e.target);
          }}
        >
          Indietro
        </Button>{" "}
        &nbsp;
        <Button
          variant="outlined"
          color="primary"
          onClick={(e) => {
            e.preventDefault();
            props.onCardSkim(e.target);
            apiCallSave();
          }}
        >
          Avanti
        </Button>
      </nav>
    </div>
  );
}

export default InfoAgency;
