import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import {DatePicker}from "@mui/x-date-pickers";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import it from "date-fns/locale/it";
import Moment from 'moment';
import React, { useEffect, useState } from "react";
import { LocalizationProvider } from '@mui/x-date-pickers'

//import { registerLocale } from 'react-datepicker';
//registerLocale('it', it);

function CVUpdateIstruzioneForm(props) {
  // const [count, setCount] = React.useState(0);
  const [anno, setAnno] = useState(null);
  const [campo, setCampo] = useState(null);
  const [livello, setLivello] = useState(null);
  const [titolo, setTitolo] = useState(null);
  const [istituto, setIstituto] = useState(null);
  const [citta, setCitta] = useState(null);
  const [descrizione, setDescrizione] = useState(null);




/*
  const [childKey, setChildKey] = useState(0);
  
  useEffect(() => {
    setChildKey(prev => prev + 1);
  },[props.key]); */

  useEffect(() => {
   // console.log('useEffect',props.o);
    setAnno(props.o.anno);
    setIstituto(props.o.istituto);
    setDescrizione(props.o.descrizione);
    setTitolo(props.o.titolo);
    setCitta(props.o.citta);
    setCampo(props.o.campo);
    setLivello(props.o.livello);
   
  }, [props]);

  return (
    <Box
      sx={{
        "& .MuiTextField-root": { m: 1, width: "25ch" },
      }}
      autoComplete="off"
    >
     
      {istituto!==null?
      <TextField
        required
        onChange={(e)=>props.onIstitutoChange(props.o.id, e.target.value)}
        name="isituto"
        label="Istituto"
        defaultValue={istituto}
      />
:<div></div>}
      {citta!==null?
      <TextField
        required
        onChange={(e)=>props.onLuogoChange(props.o.id, e.target.value)}
        name="luogo"
        label="Luogo"
        defaultValue={citta}
      />
      : <div></div>}

      <LocalizationProvider locale={it} dateAdapter={AdapterMoment}>
        <DatePicker
          views={["year"]}
          label="Anno di conseguimento"
          name="anno"
          maxDate={Moment(new Date())}
          onChange={(newValue) => {
            console.log(newValue);
            console.log(Moment(newValue).format('YYYY-MM-DD'));
          //  setDataInizio(Moment(newValue).format('YYYY-MM-DD'));
            props.onAnnoChange(props.o.id, newValue);
          }}
          value={Moment(anno)}
          textField={(params) => (
            <TextField {...params} helperText={null} />
          )}
        />

      
</LocalizationProvider>

          {titolo !==null?
                <TextField
                  required
                  onChange={(e)=>props.onTitoloChange(props.o.id, e.target.value)}
                  name="titolo"
                  label="Titolo conseguito"
                  defaultValue={titolo}
                />
          :<div></div>}


{campo!==null?
      <TextField
        required
        onChange={(e)=>props.onCampoChange(props.o.id, e.target.value)}
        name="campo"
        label="Campo di studi"
        placeholder="Informatica"
        defaultValue={campo}
      />
:<div></div>}

{livello!==null?
      <TextField
        required
        onChange={(e)=>props.onLivelloChange(props.o.id, e.target.value)}
        name="livello"
        label="Livello o valutazione (se disponibile)"
        defaultValue={livello}
      />
:<div></div>}




        { (descrizione !== null || props.o.dummy) ? (
          <div className="mui-rte-holder"><h6>Note e dettagli</h6>
         <Box sx={{ width: '75%' }}>
                <TextField
        required
        style ={{width: '100%'}}
        multiline
        onChange={(e)=>props.onDescrizioneChange(props.o.id, e.target.value, e.target.value)}
        name="dettagli"
        label="Descrivi l'esperienza formativa o aggiungi dettagli"
        defaultValue={descrizione}
      />
      </Box>
<br/>
          <br/>
          </div>
        ) : (
          <div></div>
        )}
    </Box>
  );
}

export default CVUpdateIstruzioneForm;
