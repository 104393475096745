import React, { useEffect, useRef, useState } from "react";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import {
  Alert,
  Button,
  ButtonGroup,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  TextField,
  Tooltip,
} from "@mui/material";
import { Clear } from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { User } from "../datasource/User";
import moment from "moment";
import { useGlobalState } from "../store/state";
import { ROLE_ADMIN, reclone } from "../commons/Constants";
import { QuestionOptions } from "../datasource/QuestionOptions";



function EditOption(props) {
  const [options, setOptions] = useState([]);
  const state = useGlobalState();
  const [severity, setSeverity] = useState("");
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errors, setErrors] = useState({}); // Stato per tenere traccia degli errori
  const { id } = useParams();


  console.log("id domanda: ", id);
  const initialValues = {
    questiontext: "",
    questionType: "",
    questionAnswers: "",
    questionGroup: "",
    orderNum: null,
    questionaryId: id,
    questionEvents: null,
  };
  const [formData, setFormData] = useState(
    state.optionToModify ? state.optionToModify : initialValues);

  const navigate = useNavigate();

  const handleFieldChange = (fieldName, value) => {
    if (fieldName === "questionType" && (value === "open" || value === "yesno")) {
      setFormData({ ...formData, questionType: value, questionAnswers: "" });
    }
    setFormData({ ...formData, [fieldName]: value });
  };

  // Funzione di validazione dei campi
  const validateFields = () => {
    const errors = {};
    if (!formData?.questiontext?.trim()) {
      errors.questiontext = "campo obbligatorio";
    }
    if ((formData?.questionType === "multiple" || formData?.questionType === "choice") && !formData?.questionAnswers) {
      errors.questionAnswers = "Il campo è richiesto solo se come tipo di domanda viene selezionato 'Multipla' o 'A scelta'";
    }
    if (!formData?.questionType) {
      errors.questionType = "campo obbligatorio";
    }
    if (!formData?.questionGroup) {
      errors.questionGroup = "campo obbligatorio";
    }
    if (state.optionToModify() && isNaN(formData?.orderNum)) {
      errors.orderNum = "L'ordine deve essere un numero";
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };


  const apiCall = async () => {
    await state.getQuestionOpt(id);
    let questions = state.questionOpt()
    if (typeof questions === "string") {
      questions = JSON.parse(questions);
    }
    setOptions(questions);
    console.log("la lista domande del questionario sono: ", questions);
  }

  /* const formatQuestionAnswer = (formato) =>{
    console.log("questo è il formato ", formato);
    if (typeof formato === 'object' && formato !== null) {
      const values = Object.values(formato);
      // Unisci i valori con una virgola
      return values.join(',');
   }
  } */

  const apiCallSave = async () => {
    console.log("inizio save domanda");

    if (!validateFields()) {
      return; // Esce se ci sono errori di validazione
    }

    let formattedJsonString;
    if (formData.questionType === "multiple" || formData.questionType === "choice") {
      let values = formData?.questionAnswers;

      if (typeof formData?.questionAnswers !== 'string') {
        values = formData.questionAnswers.toString();
      }
      values = values.split(",").map((value) => value.trim());
      const arrayData = [...values];
      // Convert the array to a JSON string
      formattedJsonString = JSON.stringify(arrayData, null, 2).replace(/\n/g, '');

      console.log(formattedJsonString);
    }

    console.log("orderNumb tipo", typeof state.questionLenght(), state.questionLenght());
    const questionOpt = {
      id: formData.id,
      questiontext: formData.questiontext,
      questionAnswers: formattedJsonString
        ? formattedJsonString
        : null,
      questionType: formData.questionType,
      questionGroup: formData.questionGroup,
      orderNum: !state?.optionToModify() ? state.questionLenght() + 1 : formData.orderNum,
      questionaryId: id,
      questionEvents: null,
    };

    console.log("la domanda che salvo: ", questionOpt);

    try {
      const response = await QuestionOptions.put(questionOpt);
      if (response.status === 200) {
        navigate(-1)
        return response
      } else {
        onMessage("Errore salvataggio questionario", "error");
      }
    } catch (error) {
      console.error("Error during API call for question", error);
      onMessage("Errore salvataggio questionario", "error");
    }
  };


  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setError(false);
  };

  const onMessage = (m, s) => {
    setSeverity(s);
    setError(true);
    setErrorMessage(m);
    setTimeout(() => {
      setError(false);
    }, 6000);
  };

  useEffect(() => {
    apiCall();
  }, []);

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          maxWidth: "70%",
          maxHeight: "400px%",
          margin: "0 auto",
          marginBottom: "50px",
          overflowY: "auto"
        }}
      >

        <h4 style={{ width: "100%" }}> {state?.optionToModify() ? "Modifica domanda: '" + state?.optionToModify()?.questiontext + "'"
          : "Aggiungi una domanda al questionario: '" + state.questionary().nomeQuestionario + "'"}  </h4>

        <TextField
          label="Nome domanda"
          value={formData?.questiontext}
          onChange={(e) => handleFieldChange("questiontext", e.target.value)}
          fullWidth
          name="questiontext"
          margin="normal"
          error={errors.questiontext ? true : false}
          helperText={errors.questiontext}
        />
        <Tooltip title="Inserisci le risposte separate da virgola" arrow>
          <TextField
            label="Risposte"
            disabled={!formData?.questionType || formData.questionType === "open" || formData.questionType === "yesno"}
            placeholder="Separare le risposte con la virgola"
            value={formData?.questionAnswers}
            onChange={(e) => handleFieldChange("questionAnswers", e.target.value)}
            fullWidth
            margin="normal"
            error={errors.questionAnswers ? true : false}
            helperText={errors.questionAnswers}
          />
        </Tooltip>
        <FormControl fullWidth margin="normal" error={!!errors.questionType}>
          <InputLabel id="questionGroup-label">Tipo di domanda</InputLabel>
          <Select
            labelId="questionType-label"
            id="questionType"
            value={formData?.questionType}
            label="Question Type"
            onChange={(e) => handleFieldChange("questionType", e.target.value)}
          >
            <MenuItem value="choice">A scelta</MenuItem>
            <MenuItem value="multiple">Multipla</MenuItem>
            <MenuItem value="yesno">Si o no</MenuItem>
            <MenuItem value="open">Aperta</MenuItem>
          </Select>
          {!!errors.questionType && (
            <FormHelperText>{errors.questionType}</FormHelperText>
          )}
        </FormControl>

        <FormControl fullWidth margin="normal" error={!!errors.questionGroup}>
          <InputLabel id="questionGroup-label">Gruppo domanda</InputLabel>
          <Select
            labelId="questionGroup-label"
            id="questionGroup"
            value={formData?.questionGroup}
            label="Question Group"
            onChange={(e) => handleFieldChange("questionGroup", e.target.value)}
          >
            <MenuItem value="hardskills">Hard skills</MenuItem>
            <MenuItem value="brandidentity">Brand identity</MenuItem>
            <MenuItem value="softskills">Soft skills</MenuItem>
          </Select>
          {!!errors.questionGroup && (
            <FormHelperText>{errors.questionGroup}</FormHelperText>
          )}
        </FormControl>

        {state?.optionToModify() &&
          <TextField
            label="Ordine"
            value={formData?.orderNum}
            onChange={(e) => handleFieldChange("orderNum", e.target.value)}
            fullWidth
            name="orderNum"
            margin="normal"
            error={errors.orderNum ? true : false} // Imposta l'errore se esiste
            helperText={errors.orderNum} // Visualizza il messaggio di errore
          />
        }

        <div>
          <Button
            variant="outlined"
            color="primary"
            className="btn btn-success"
            onClick={() => navigate(-1)}
          >
            Indietro
          </Button>
          &nbsp;
          <Button
            variant="outlined"
            color="primary"
            className="btn-questionaries questionaries-button"
            style={{ backgroundColor: '#8376a6 !important', color: 'white' }}
            onClick={(e) => {
              e.preventDefault();
              apiCallSave()
            }}
          >{state?.optionToModify() ? "modifica" : "aggiungi"}

          </Button>
        </div>
      </div>

      <Snackbar open={error} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={severity} sx={{ width: "100%" }}>
          {errorMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default EditOption;