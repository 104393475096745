

import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import * as React from "react";

function QuestionSelectSingle(props) {
  const [answer, setAnswer] = React.useState("");

  const handleChange = (event) => {
    setAnswer(event.target.value);
    props.onAnswerChange(event.target.value);
  };

  //console.log(props.q.question_answers);
  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Risposta</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          className={`question-answerselectbox querstion-answer_${props.q.id}`}
          value={answer}
          label="Risposta"
          onChange={handleChange}
        >
          {props.q.question_answers.map((ans, i) => (
            <MenuItem value={ans}>{ans}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}

  export default QuestionSelectSingle;