import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Fade from "@mui/material/Fade";
import Snackbar from "@mui/material/Snackbar";
import React, { useState, useEffect, useRef } from "react";
import QuestionSelectSingle from "./QuestionSelectSingle";
import QuestionSelectMultiple from "./QuestionSelectMultiple";
import QuestionOpen from "./QuestionOpen";
import QuestionOpenSmall from "./QuestionOpenSmall";
import QuestionSlider10 from "./QuestionSlider10";
import QuestionYesNo from "./QuestionYesNo";
import { Button, Typography, useMediaQuery, useTheme } from "@mui/material";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { useGlobalState } from "../../store/state";
import { useNavigate } from "react-router";

function QuestionRow(props) {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const [answerValidated, setAnswerValidated] = useState("");
  let showErrorToast = props.apiError;
  const navigate = useNavigate();

  const state = useGlobalState();
  const onCardSkim = (event) => {
    //console.log(event);
    props.onCardSkim(event.target);
  };

  const onCardSkimPrev = (event) => {
    //console.log(event);
    props.onCardSkimPrev(event.target);
  };

  const onQuestionnaireEnd = (event) => {
    console.log("evento onQuestionnaireEnd", event);
    props.onQuestionnaireEnd(event.target);
  };

  const onAnswerChange = (answer) => {
    setAnswerValidated(true);
    if (props.q.questionType === "yesno") {
      if (props.q.questionAnswers?.onyes === "skip") {
        props.setSkipNext(answer === "yes");
      }
      if (props.q.questionAnswers?.onno === "skip") {
        props.setSkipNext(answer === "no");
      }
    }
    props.onAnswerChange(props.q, answer);
  };
  // setAnswerValidated(true);
  let bottoni;
  let additionalClass = "";
  if (props.first) {
    additionalClass = "visible";
    bottoni = (
      <nav>
        <Button
          variant="outlined"
          color="primary"
          className="btn btn-success"
          disabled={!answerValidated}
          onClick={onCardSkim}
        >
          Avanti
        </Button>
      </nav>
    );
  } else {
    bottoni = (
      <nav>
        <Button
          variant="outlined"
          color="primary"
          className="btn btn-success"
          onClick={onCardSkimPrev}
        >
          Indietro
        </Button>
        &nbsp;
        <Button
          variant="outlined"
          color="primary"
          className="btn btn-success"
          disabled={!answerValidated}
          onClick={onCardSkim}
        >
          Avanti
        </Button>
      </nav>
    );
  }
  let bottoniSection;
  if (!props.first) {
    bottoniSection = (
      <nav>
        <Button
          variant="outlined"
          color="primary"
          className="btn btn-success"
          onClick={onCardSkimPrev}
        >
          Indietro
        </Button>
        &nbsp;
        <Button
          variant="outlined"
          color="primary"
          className="btn btn-success"
          onClick={onCardSkim}
        >
          Avanti
        </Button>
      </nav>
    );
  } else {
    bottoniSection = (
      <nav>
        <Button
          variant="outlined"
          color="primary"
          className="btn btn-success"
          onClick={props.backToQuestionaries}
        >
          Indietro
        </Button>
        &nbsp;
        <Button
          variant="outlined"
          color="primary"
          className="btn btn-success"
          onClick={onCardSkim}
        >
          Avanti
        </Button>
      </nav>
    );
  }

  const renderButtons = () => {
    if (props.first) {
      return (
        <>
          <Button
            variant="outlined"
            color="primary"
            disabled={!answerValidated}
            onClick={onCardSkim}
          >
            Avanti
          </Button>
        </>
      );
    } else {
      return (
        <>
          <Button variant="outlined" color="primary" onClick={onCardSkimPrev}>
            Indietro
          </Button>
          &nbsp;
          <Button
            variant="outlined"
            color="primary"
            disabled={!answerValidated}
            onClick={onCardSkim}
          >
            Avanti
          </Button>
        </>
      );
    }
  };

  // ... resto del componente ...

  const questionCard = (additionalClassEnabled = true) => (
    <Card
      sx={{
        padding: matches ? "127px" : "100px",
        paddingLeft: matches ? "200px" : "80px",
        paddingRight: matches ? "200px" : "80px",
        minWidth: matches ? 427 : "90%",
        maxWidth: matches ? "92vw" : null,
        overflow: "scroll"
      }}
    >
      {/* <CardMedia
  sx={{ height: 140 }}
  image={"/cards/" + (index + 1) + ".png"}
  title={card.title}
/> */}
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          <div className="card-topper">
            {/*             <h2>{state.titleQuestionary()}</h2>
             */}{" "}
            <span className="badge-card">{props.q.questionGroup}</span>
            <br />
            {questionNavInfo()}
          </div>
        </Typography>
        <Typography
          className="questionText"
          gutterBottom={matches ? true : false}
          variant={matches ? "h4" : "h6"}
          component="div"
        >
          {props.q.questiontext}
          <br />
          <br />
        </Typography>
        <Typography
          className="questionInput"
          variant="body2"
          color="text.secondary"
        >
          {questionBodyElements()}
        </Typography>
      </CardContent>
      <CardActions sx={{ justifyContent: "flex-end", }}>
        {" "}
        {/* Aggiunta dello stile qui */}
        {renderButtons()}
      </CardActions>
    </Card>
  );

  const questionCardContainer = (additionalClassEnabled = true) => (
    <header
      id={`question${props.q.id}`}
      className={`JDKReactApp-header ${
        additionalClassEnabled ? additionalClass : ""
      }`}
    >
      {questionCard(additionalClassEnabled)}
    </header>
  );

  const questionNavInfo = () => (
    <>
      <h6 className="questionCount">
        Domanda {props.k} di {props.qlen}{" "}
      </h6>
    </>
  );

  const questionBodyElements = () => <div>{input}</div>;

  let bottoneTermina = (
    <nav>
      <Button
        variant="outlined"
        color="primary"
        className="btn btn-success"
        onClick={onQuestionnaireEnd}
      >
        Invia
      </Button>
    </nav>
  );

  let bottoneTerminaDisabled = (
    <nav>
      <Button
        variant="outlined"
        color="primary"
        className="btn btn-success"
        disabled
        onClick={onQuestionnaireEnd}
      >
        Inviato
      </Button>
      &nbsp;
      <Button
        variant="outlined"
        color="primary"
        className="btn btn-success"
        onClick={props.onViewSent}
      >
        Vedi inviati
      </Button>
    </nav>
  );

  let input;
  if (props.q.questionType === "choice") {
    input = (
      <QuestionSelectSingle
        onAnswerChange={(a) => {
          onAnswerChange(a);
        }}
        key={props.q.id}
        q={props.q}
      ></QuestionSelectSingle>
    );
  }

  if (props.q.questionType === "slider10") {
    input = (
      <QuestionSlider10
        onAnswerChange={(a) => {
          onAnswerChange(a);
        }}
        key={props.q.id}
        q={props.q}
      ></QuestionSlider10>
    );
  }

  if (props.q.questionType === "multiple") {
    input = (
      <QuestionSelectMultiple
        onAnswerChange={(a) => {
          onAnswerChange(a);
        }}
        key={props.q.id}
        q={props.q}
      ></QuestionSelectMultiple>
    );
  }

  if (props.q.questionType === "open-small") {
    input = (
      <QuestionOpenSmall
        onAnswerChange={(a) => {
          onAnswerChange(a);
        }}
        key={props.q.id}
        q={props.q}
      ></QuestionOpenSmall>
    );
  }

  if (props.q.questionType === "open") {
    input = (
      <QuestionOpen
        onAnswerChange={(a) => {
          onAnswerChange(a);
        }}
        key={props.q.id}
        q={props.q}
      ></QuestionOpen>
    );
  }

  if (props.q.questionType === "yesno") {
    input = (
      <QuestionYesNo
        onAnswerChange={(a) => {
          onAnswerChange(a);
        }}
        key={props.q.id}
        q={props.q}
      ></QuestionYesNo>
    );
  }
  let toret;
  if (props.groupChanged) {
    toret = [
      questionCardContainer(false),
      <header className={`JDKReactApp-header ${additionalClass}`}>
        <Card
          sx={{
            padding: matches ? "127px" : "100px",
            paddingLeft: matches ? "200px" : "80px",
            paddingRight: matches ? "200px" : "80px",
            minWidth: matches ? 427 : "90%",
            maxWidth: matches ? "92vw" : null,
          }}
        >
          {/*           <h2>{state.titleQuestionary()}</h2>
           */}{" "}
          <h6>Sezione {props.groupInfo[props.q.questionGroup]}</h6>
          <h4 className="qgtitle">{props.q.questionGroup}</h4>
          {bottoniSection}
        </Card>
      </header>,
    ];
  } else {
    if (props.last) {
      toret = [
        <header className={`JDKReactApp-header ${additionalClass}`}>
          <Box sx={{ height: 40 }}>
            <Fade
              in={props.loading}
              style={{
                transitionDelay: props.loading ? "80ms" : "0ms",
              }}
              unmountOnExit
            >
              <CircularProgress />
            </Fade>
          </Box>
          <Snackbar
            onClose={() => {
              showErrorToast = false;
            }}
            open={showErrorToast}
            autoHideDuration={6000}
          >
            <Alert severity="error" sx={{ width: "100%" }}>
              Qualcosa è andato storto
            </Alert>
          </Snackbar>
          <Snackbar
            onClose={() => {}}
            open={props.sent}
            autoHideDuration={6000}
          >
            <Alert severity="success" sx={{ width: "100%" }}>
              Inviato con successo
            </Alert>
          </Snackbar>
          <h4>Finito!</h4>
          <h5>
            {props.sent === false ? <div>Ora puoi inviarlo:</div> : <div></div>}
          </h5>
          {props.sent === false ? bottoneTermina : bottoneTerminaDisabled}
        </header>,
        questionCardContainer(),
      ];
    } else {
      toret = questionCardContainer();
    }
  }
  return toret;
}

export default QuestionRow;
