import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import TextField from "@mui/material/TextField";
import jQuery from "jquery";
import { clone, debounce } from 'lodash';
import Moment from 'moment';
import React, { useCallback, useEffect, useState } from "react";
import { AJAX_ENDPOINT, reclone } from "../commons/Constants";
import CVUpdateCompetenzeForm from './CVUpdateCompetenzeForm';
import Box from "@mui/material/Box";
import { Button } from "@mui/material";
import { useGlobalState } from '../store/state';
import { CVCompetenzeDigitali } from '../datasource/CVCompetenzeDigitali';
import { useNavigate } from 'react-router';


function stripHtml(html) {
  let tmp = document.createElement("DIV");
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || "";
}

function truncate( str, n, useWordBoundary ){
  if (str.length <= n) { return str; }
  const subString = str.substr(0, n-1); // the original check
  return (useWordBoundary 
    ? subString.substr(0, subString.lastIndexOf(" ")) 
    : subString) + "...";
};

function CompentenzeDigitali(props) {
  const [competenzeDigitali, setCompetenzeDigitali] = useState([]);
  const [touched, setTouched] = useState(false);
  let navigate = useNavigate();

  console.log(competenzeDigitali);
  const state = useGlobalState();

  const [lockSorting, setLockSorting] = useState(false);

  const newEsp = () => {
    competenzeDigitali.push({
      wpUserId: state.user()?.wpUserId,
      id: Math.random(),
      dummy: true,
      descrizione: ""
    });
    refreshState();
  };

  const deleteItem = async(i) => {
    if (window.confirm('Vuoi eliminare l\'elemento selezionato?')){
       const splitId = competenzeDigitali.splice(i, 1);
        setTouched(true);
        await CVCompetenzeDigitali.delete(splitId[0].id);
        refreshState();  
    }
  };

  const sortUp = (i) => {
    if (i>0 && !lockSorting){
      let tmp = competenzeDigitali[i-1];
      competenzeDigitali[i-1]=competenzeDigitali[i];
      competenzeDigitali[i] = tmp;
      setTouched(true);
      refreshState();
    }
  };

  const sortDown = (i) => {
    if (i<competenzeDigitali.length-1 && !lockSorting){
      let tmp = competenzeDigitali[i+1];
      competenzeDigitali[i+1]=competenzeDigitali[i];
      competenzeDigitali[i] = tmp;
      setTouched(true);
      refreshState();
    }
  }


  const refreshState = () => {
    setCompetenzeDigitali(clone(competenzeDigitali));
    //setLockSorting(false);
  }

  const _refreshState = () => {
    setCompetenzeDigitali(clone(competenzeDigitali));
    setLockSorting(false);
  }

  const refreshStateDebounced = useCallback(
    debounce(refreshState, 30)
  , [competenzeDigitali]);

  const refreshStateDebouncedLarge = useCallback(
    debounce(_refreshState, 5000)
  , [competenzeDigitali]);


  const _refreshStateDebounced = () => {
    setCompetenzeDigitali(clone(competenzeDigitali));
  }

  const apiCall = async () => {
    await state.getCompetenzeDigitali();
    let competenzeDigitali = state.competenza();
    competenzeDigitali = reclone(competenzeDigitali)
    setCompetenzeDigitali(competenzeDigitali);
  };

  const apiCallSave = async () => {
    try{
    const modifiedEsp = competenzeDigitali.filter((o)=> o.touched===true);
    let promises = [];
    
    for (let i = 0; i < modifiedEsp.length; i++) {
      console.log(modifiedEsp[i]);
      modifiedEsp[i].touched = false;
      promises.push(CVCompetenzeDigitali.put(modifiedEsp[i]));
    }

    await Promise.all(promises);
    state.getCompetenzeDigitali();
    props.onMessage("Salvataggio effettuato", "success");
    //redirect
    //navigate("/");
    }catch(e){
      console.error(e);
      props.onCardSkimPrev(document.querySelector("#skimprev-esplav"));
      props.onMessage("Errore nel salvataggio", "error");
    }
  };

  useEffect(() => {
    apiCall();
  }, []);



  return (
    <div className="JDKReactApp-header visible">
    <h3>Controlla le tue informazioni sulle tue competenze</h3>
      {competenzeDigitali.length > 0 ? (
        <section className="formcontainer customscrollbar" style={{height: '100%', width: '100%'}}>
          <h4>Compentenze digitali:</h4>
          {competenzeDigitali.map((o, i) => {
            const key = o.id;//JSON.stringify(o);//Math.random()+""+Date.now();

            return (
              <Accordion className="lingue-accordion" key={i}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <div className="accordion-header-jdk">
                    <div className="accordion-header-item">
                      <div className="aheader">#</div>
                      {i + 1}
                    </div>
                    <div className="accordion-header-item">
                      <div className="aheader">Competenza</div>
                      {o.competenza}
                    </div>
                    <div className="accordion-header-item descrizione">
                      <div className="aheader">Azioni</div>
                      <span className="accordion-action" title="Sposta su" onClick={(e) => { e.preventDefault(); e.stopPropagation(); sortUp(i) }}><ArrowDropUpIcon /></span>
                      <span className="accordion-action" title="Sposta giù" onClick={(e) => { e.preventDefault(); e.stopPropagation(); sortDown(i) }}><ArrowDropDownIcon /></span>
                      <span className="accordion-action" title="Elimina definitiviamente" onClick={(e) => { e.preventDefault(); e.stopPropagation(); deleteItem(i) }}><DeleteForeverIcon /></span>
                    </div>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <CVUpdateCompetenzeForm
                    onCertificazioneChange={(id, value, plainText) => {
                      const idx = competenzeDigitali.findIndex((v) => v.id === id);
                      // console.log(idx,value,plainText);
                      competenzeDigitali[idx].certificazione = value;
                      competenzeDigitali[idx].touched = true;
                      //setLockSorting(true);
                      refreshState();
                    }}
                    onRisoluzioneChange={(id, value) => {
                      const idx = competenzeDigitali.findIndex((v) => v.id === id);
                      competenzeDigitali[idx].risoluzioneLiv = value;
                      competenzeDigitali[idx].touched = true;
                      refreshState();
                    }}
                    onSicurezzaChange={(id, value) => {
                      const idx = competenzeDigitali.findIndex((v) => v.id === id);
                      competenzeDigitali[idx].sicurezzaLiv = value;
                      competenzeDigitali[idx].touched = true;
                      refreshState();
                    }}
                    onCreazioneChange={(id, value) => {
                      const idx = competenzeDigitali.findIndex((v) => v.id === id);
                      competenzeDigitali[idx].creazioneLiv = value;
                      competenzeDigitali[idx].touched = true;
                      refreshState();
                    }}
                    onComunicazioneChange={(id, value) => {
                      const idx = competenzeDigitali.findIndex((v) => v.id === id);
                      competenzeDigitali[idx].comunicazioneLiv = value;
                      competenzeDigitali[idx].touched = true;
                      refreshState();
                    }}
                    onElabChange={(id, value) => {
                      const idx = competenzeDigitali.findIndex((v) => v.id === id);
                      competenzeDigitali[idx].elabLiv = value;
                      competenzeDigitali[idx].touched = true;
                      refreshState();
                    }}
                    onCompetenzaChange={(id, value) => {
                      const idx = competenzeDigitali.findIndex((v) => v.id === id);
                      competenzeDigitali[idx].competenza = value;
                      competenzeDigitali[idx].touched = true;
                      refreshState();
                    }}
                    o={o}
                    key={key}
                  ></CVUpdateCompetenzeForm>
                </AccordionDetails>
              </Accordion>
            );
          })}
        </section>)
        : <div>Nessuna competenza aggiunta...</div>
      }
    <nav>
      <Button
        variant="outlined"
        color="primary"
        onClick={(e) => {
          e.preventDefault();
          props.onCardSkimPrev(e.target);
        }}
      >
        Indietro
      </Button>{" "}
      &nbsp;
      <Button
        variant="outlined"
        color="primary"
        onClick={(e) => {
          e.preventDefault();
          newEsp();
        }}
      >
        Aggiungi compentenza
      </Button>{" "}
      &nbsp;
      <Button
        variant="outlined"
        color="primary"
        onClick={(e) => {
          e.preventDefault();
          props.onCardSkim(e.target);
          apiCallSave();
        }}
      >
        Avanti
      </Button>
    </nav>

  </div>
  );
}

export default CompentenzeDigitali;
