// Step.js

import React, { useEffect } from "react";
import {
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Grid,
} from "@mui/material";

const Step = ({ length, stepIndex, formData, handleFieldChange }) => {
  useEffect(() => {
    handleFieldChange(stepIndex, "orderNum", stepIndex);
  }, [stepIndex]);

  return (
    <div>
      <h2>
        Domanda {stepIndex + 1} di {length}
      </h2>
      <Grid container spacing={2}>
        <Grid item sm={6} xs={12}>
          <TextField
            label="Nome domanda"
            value={formData?.questiontext}
            required
            onChange={(e) =>
              handleFieldChange(stepIndex, "questiontext", e.target.value)
            }
            fullWidth
            margin="normal"
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextField
            label="Risposte"
            disabled={
              !formData?.questionType ||
              formData.questionType === "open" ||
              formData.questionType === "yesno"
            }
            required={
              formData?.questionType === "choice" ||
              formData?.questionType === "choice"
            }
            placeholder="Separare le risposte con la virgola"
            value={formData?.questionAnswers}
            onChange={(e) =>
              handleFieldChange(stepIndex, "questionAnswers", e.target.value)
            }
            fullWidth
            helperText='Il campo è richiesto solo se come tipo di domanda viene selezionato "Multipla" o "A scelta"'
            margin="normal"
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <FormControl required fullWidth margin="normal">
            <InputLabel id="questionType-label">Tipo di domanda</InputLabel>
            <Select
              labelId="questionType-label"
              id="questionType"
              value={formData?.questionType}
              label="Tipo di domanda"
              onChange={(e) =>
                handleFieldChange(stepIndex, "questionType", e.target.value)
              }
            >
              <MenuItem value="choice">A scelta</MenuItem>
              <MenuItem value="multiple">Multipla</MenuItem>
              <MenuItem value="yesno">Si o no</MenuItem>
              <MenuItem value="open">Aperta</MenuItem>
              {/* Aggiungi altri tipi di domanda se necessario */}
            </Select>
          </FormControl>
        </Grid>
        <Grid item sm={6} xs={12}>
          <FormControl required fullWidth margin="normal">
            <InputLabel id="questionGroup-label">Gruppo domanda</InputLabel>
            <Select
              labelId="questionGroup-label"
              id="questionGroup"
              value={formData?.questionGroup}
              label="Gruppo domanda"
              onChange={(e) =>
                handleFieldChange(stepIndex, "questionGroup", e.target.value)
              }
            >
              <MenuItem value="hardskills">Hard skills</MenuItem>
              <MenuItem value="brandidentity">Brand identity</MenuItem>
              <MenuItem value="softskills">Soft skills</MenuItem>
              {/* Aggiungi altri gruppi se necessario */}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </div>
  );
};

export default Step;
