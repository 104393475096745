import axios from "axios";
import {
  AJAX_ENDPOINT,
  FAKE_USER_ID,
  getHeaders,
  reclone,
} from "../commons/Constants";

export class Positions {
  static async get(id) {
    const HEADERS = getHeaders();
    const resp = await axios.get(
      AJAX_ENDPOINT + "inet-jdk/positions/get-posizione/" + id,
      {
        headers: HEADERS,
      }
    );
    return resp.data;
  }

  static async getAll() {
    const HEADERS = getHeaders();
    const resp = await axios.get(AJAX_ENDPOINT + "inet-jdk/positions/all", /* {
      headers: HEADERS,
    } */);
    console.log("positions", resp)
    return resp.data;
  }

  static async put(position, id) {
    const HEADERS = getHeaders();
    position = reclone(position);
    const resp = await axios.put(
      AJAX_ENDPOINT + "inet-jdk/positions/update/" + id, null,
      {
        params: {
          new_nome: position
        },
        headers: HEADERS,
      }
    );
    return resp;
  }

  static async post(position) {
    const HEADERS = getHeaders();
    const resp = await axios.post(
      AJAX_ENDPOINT + "inet-jdk/positions/create",
      { nome: position },
      {
        headers: HEADERS,
      }
    );
    return resp.data;
  }

  static async delete(id) {
    const HEADERS = getHeaders();
    const resp = await axios.delete(
      AJAX_ENDPOINT + "inet-jdk/positions/delete/" + id,
      {
        headers: HEADERS,
      }
    );
    return resp;
  }
}
