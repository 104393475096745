import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import React, { useEffect, useState } from "react";
import jQuery, { error } from "jquery";
import { AJAX_ENDPOINT, reclone } from "../commons/Constants";
import { merge, clone, union } from "lodash";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import it from "date-fns/locale/it";
import { DatePicker } from "@mui/x-date-pickers";
import Moment from "moment";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import "yup-phone-lite";
import { useGlobalState } from "../store/state";
import {
  Alert,
  Button,
  OutlinedInput,
  Snackbar,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { User } from "../datasource/User";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { useNavigate, useParams } from "react-router-dom";
import { Positions } from "../datasource/Positions";

const ModificaUtente = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [uinfo, setUInfo] = useState(null);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const [positions, setPositions] = useState([]);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up(736));

  useEffect(() => {
    getPositions();
  }, []);

  const getPositions = async () => {
    Positions.getAll().then((resp) => setPositions(resp));
  };

  const validationSchema = Yup.object().shape({
    nome: Yup.string().required("Il nome è richiesto"),
    cognome: Yup.string().required("Il cognome è richiesto"),
    cap: Yup.number()
      .required("Il CAP è richiesto")
      .typeError("Il CAP deve essere numerico"),
    citta: Yup.string().required("La città è richiesta"),
    tel: Yup.string()
      .phone("IT", true, "${path} non è un numero di telefono valido")
      .required("Il tel è richiesto"),
    email: Yup.string()
      .required("L'email è richiesta")
      .email("Email non valida"),
    nazionalita: Yup.string().required("La nazionalità è richiesta"),
  });

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setError(false);
  };

  const onMessage = (m, s) => {
    setSeverity(s);
    setError(true);
    setErrorMessage(m);
    setTimeout(() => {
      setError(false);
    }, 6000);
  };

  const {
    register,
    control,
    trigger,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "all",
    resolver: yupResolver(validationSchema),
  });

  const apiCall = async () => {
    User.get(id).then((user) => {
      setUInfo(user);
    });
  };

  const apiCallSave = async () => {
    try {
      const response = await User.adminUpdate(uinfo.wpUserId,{
        nome: uinfo.nome,
        cognome: uinfo.cognome,
        cap: uinfo.cap,
        citta: uinfo.citta,
        tel: uinfo.tel,
        email: uinfo.email,
        dataNasc: uinfo.dataNasc,        
        nazionalita: uinfo.nazionalita,        
        posizioneRicopertaId: uinfo.posizioneRicopertaId,
        sesso: uinfo.sesso,
      });
      if (response.status === 200) {
        onMessage("Salvataggio effettuato", "success");
        setTimeout(() => navigate(-1), 1000);
      }else{
        onMessage("Salvataggio non effettuato", "error");
      }
    } catch (e) {
      console.error(e);
      onMessage("Salvataggio non effettuato, " + error.message, "error");
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    trigger();
    handleSubmit(
      (data, evt) => {
        apiCallSave();
      },
      (errors, evt) => {
        console.log(errors);
      }
    )();
  };

  useEffect(() => {
    apiCall();
  }, [id]);

  return (
    <div className="JDKReactApp-header visible">
      <section
        style={{ marginBottom: !matches ?"100px" : null }}
        className="formcontainer customscrollbar"
      >
        <h3>
          Modifica le informazioni anagrafiche dell'utente {uinfo?.nome}{" "}
          {uinfo?.cognome}
        </h3>
        {uinfo ? (
          <Box
            component="form"
            sx={{
              "& .MuiTextField-root": { m: 1, width: "25ch" },
            }}
            autoComplete="off"
          >
            <TextField
              required
              name="nome"
              label="Nome"
              defaultValue={uinfo?.nome}
              {...register("nome")}
              error={errors.nome ? true : false}
              helperText={errors.nome?.message}
              onChange={(e) => {
                var o = clone(uinfo);
                merge(o, { nome: e.target.value });
                console.log(o);
                setUInfo(o);
              }}
            />

            <TextField
              required
              name="cognome"
              label="Cognome"
              defaultValue={uinfo?.cognome}
              {...register("cognome")}
              error={errors.cognome ? true : false}
              helperText={errors.cognome?.message}
              onChange={(e) => {
                var o = clone(uinfo);
                merge(o, { cognome: e.target.value });
                //console.log(o);
                setUInfo(o);
              }}
            />

            <TextField
              required
              name="cap"
              label="C.A.P"
              type="text"
              defaultValue={uinfo?.cap?.toString()}
              {...register("cap")}
              error={errors.cap ? true : false}
              helperText={errors.cap?.message}
              placeholder="00100"
              onChange={(e) => {
                var o = clone(uinfo);
                merge(o, { cap: e.target.value });
                //console.log(o);
                setUInfo(o);
              }}
            />

            <TextField
              required
              name="citta"
              label="Città"
              defaultValue={uinfo?.citta?.toString()}
              {...register("citta")}
              error={errors.citta ? true : false}
              helperText={errors.citta?.message}
              placeholder="Roma"
              onChange={(e) => {
                var o = clone(uinfo);
                merge(o, { citta: e.target.value });
                //console.log(o);
                setUInfo(o);
              }}
            />

            <TextField
              required
              name="tel"
              label="Telefono"
              defaultValue={uinfo?.tel?.toString()}
              {...register("tel")}
              error={errors.tel ? true : false}
              helperText={errors.tel?.message}
              placeholder=""
              onChange={(e) => {
                var o = clone(uinfo);
                merge(o, { tel: e.target.value });
                //console.log(o);
                setUInfo(o);
              }}
            />
            <TextField
              required
              name="email"
              label="Email"
              defaultValue={uinfo?.email?.toString()}
              {...register("email")}
              error={errors.email ? true : false}
              helperText={errors.email?.message}
              placeholder=""
              onChange={(e) => {
                var o = clone(uinfo);
                merge(o, { email: e.target.value });
                //console.log(o);
                setUInfo(o);
              }}
            />

            <LocalizationProvider locale={it} dateAdapter={AdapterMoment}>
              <DatePicker
                label="Data di nascita"
                name="data_nascita"
                maxDate={Moment(new Date())}
                onChange={(newValue) => {
                  var o = clone(uinfo);
                  merge(o, {
                    dataNasc: newValue.format("YYYY-MM-DD"),
                  });
                  //console.log(o);
                  setUInfo(o);
                }}
                value={Moment(uinfo?.dataNasc)}
                textField={(params) => (
                  <TextField {...params} helperText={null} />
                )}
              />
            </LocalizationProvider>

            <TextField
              required
              name="nazionalita"
              label="Nazionalità"
              defaultValue={uinfo?.nazionalita?.toString()}
              {...register("nazionalita")}
              error={errors.nazionalita ? true : false}
              helperText={errors.nazionalita?.message}
              placeholder="Italiana"
              onChange={(e) => {
                var o = clone(uinfo);
                merge(o, { nazionalita: e.target.value });
                //console.log(o);
                setUInfo(o);
              }}
            />

            <FormControl className={"sexSelect"} margin="normal">
              <InputLabel htmlFor="Posizione">Posizione ricoperta</InputLabel>
              <Select
                label="Posizione"
                sx={{ minWidth: "300px" }}
                input={<OutlinedInput label="Posizione ricoperta" />}
                value={uinfo?.posizioneRicopertaId}
                inputProps={{
                  name: "posizione",
                  id: "posizione",
                }}
                onChange={(e) => {
                  var o = clone(uinfo);
                  merge(o, { posizioneRicopertaId: e.target.value });
                  //console.log(o);
                  setUInfo(o);
                }}
              >
                {positions?.map((el, i) => (
                  <MenuItem key={i} value={el.id}>
                    {el.nome}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {/*             <TextField
              required
              name="posizione_ricoperta"
              label="Posizione ricoperta all'interno dell'azienda"
              defaultValue={uinfo?.posizioneRicoperta?.toString()}
              {...register("posizione")}
              error={errors.posizione ? true : false}
              helperText={errors.posizione?.message}
              placeholder="Software Developer"
              onChange={(e) => {
                var o = clone(uinfo);
                merge(o, { posizioneRicoperta: e.target.value });
                //console.log(o);
                setUInfo(o);
              }}
            /> */}

            <FormControl className={"sexSelect"} margin="normal">
              <InputLabel id="sesso_label">Sesso</InputLabel>
              <Select
                sx={{ minWidth: matches ? "100px": "300px" }}
                labelId="sesso_label"
                value={uinfo?.sesso}
                label="Sesso"
                onChange={(e) => {
                  var o = clone(uinfo);
                  merge(o, { sesso: e.target.value });
                  //console.log(o);
                  setUInfo(o);
                }}
              >
                <MenuItem value={"Maschile"}>Maschile</MenuItem>
                <MenuItem value={"Femminile"}>Femminile</MenuItem>
                <MenuItem value={"Altro"}>Altro</MenuItem>
              </Select>
            </FormControl>

            <nav style={{ display: "flex", justifyContent: "space-evenly" }}>
              <Button
                id="skimnext-formanagrafica"
                variant="outlined"
                color="primary"
                onClick={() => navigate(-1)}
              >
                Indietro
              </Button>
              <Button
                id="skimnext-formanagrafica"
                className="btn-questionaries questionaries-button"
                disabled={
                  Object.keys(errors).length === 0 &&
                  errors.constructor === Object
                    ? false
                    : true
                }
                onClick={onSubmit}
              >
                Modifica
              </Button>
            </nav>
          </Box>
        ) : (
          <div>Carico...</div>
        )}
      </section>
      <Snackbar open={error} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={severity} sx={{ width: "100%" }}>
          {errorMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default ModificaUtente;
