import { Link, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import jQuery from "jquery";
import { AJAX_ENDPOINT, reclone } from "./commons/Constants";
import { useGlobalState } from "./store/state";
import { AnsweredQuestionnaire } from "./datasource/AnsweredQuestionnaire";
import moment from "moment";

function AnsweredQuestionnaires(props) {
  let { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [questionnaireBy, setQuestionnaireBy] = useState(null);
  const [questLabel, setQuestionLabel] = useState("Caricando...");
  /* const [questions, setQuestions] = useState([]); */

  const state = useGlobalState();

  const apiCall = async () => {
    setLoading(true);
    state
      .getAnsweredQuestionnaireById(id)
      .then(() =>
        setQuestionnaireBy(reclone(state?.answeredQuestionnaireById()))
      );
    setLoading(false);
  };

/*     useEffect(() => {
    setQuestionnaireBy(reclone(state?.answeredQuestionnaireById()));
  }, [state.answeredQuestionnaireById.value]); */

  useEffect(() => {
    apiCall();
  }, []);

  return (
    <div>
      <br />
      <small>
        {/* <a href="/questionnaire/list">Torna alla lista dei questionari inviati</a> */}
        <Link to={"/questionnaire/list"}>
          Torna alla lista dei questionari inviati
        </Link>
      </small>
      {!loading ? (
        <section>
          <h4>Questionario inviato da {state?.user()?.nome}</h4>
          <small>In data {moment(questionnaireBy?.dateTaken).format("DD/MM/YYYY HH:MM") }</small>
          {questionnaireBy?.questionJson?.map((o, i) => {
            return (
              <>
                <ul key={i} className="callouts">
                  {/* {questions.map((e, i) => {})} */}
                  <li className="callouts--left">
                    <h4 className="question">{o.questiontext}</h4>
                  </li>
                  <li className="callouts--right answer-callout">
                    <h5 className="answer">
                      {typeof o.answer === "string"
                        ? o.answer
                        : JSON.stringify(o.answer)}
                    </h5>
                  </li>
                </ul>
              </>
            );
          })}
        </section>
      ) : (
        <div>{questLabel}</div>
      )}
    </div>
  );
}

export default AnsweredQuestionnaires;
