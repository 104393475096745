import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import QuestionnaireForm from "./pages/QuestionnaireForm";
import DocsApp from "./pages/DocsApp";
import QuestionnaireList from "./pages/QuestionnaireList";
import Home from "./pages/home/Home";
import CVApp from "./pages/CVApp";
import CVLog from "./pages/CVLog";
import AnsweredQuestionnaires from "./AnsweredQuestionnaire";
import RegisterPage from "./pages/RegisterPage";
import LoginPage from "./pages/LoginPage";
import ProfilePage from "./pages/ProfilePage";
import { useGlobalState } from "./store/state";
import CreateQuestionnaires from "./pages/CreateQuestionnaires";
import UsersTable from "./pages/UsersTable";
import CardQuestion from "./components/cardQuestion/CardQuestion";
import ModificaUtente from "./pages/ModificaUtente";
import { ROLE_ADMIN } from "./commons/Constants";
import OptionList from "./pages/OptionList";
import EditOption from "./pages/EditOption";
import PositionsTable from "./pages/PositionsTable";
import DettagliUtente from "./pages/DettagliUtente";
import QuestionaryUsersAnswerd from "./pages/QuestionaryUsersAnswerd";

export const AppRoutes = () => {
  const state = useGlobalState();

  return (
    <div className="container my-4">
      <Routes>
        <Route
          path="/"
          element={state.authenticated() ? <Home /> : <Navigate to="/login" />}
        />
        <Route
          path="/questionnaire/list"
          element={
            state.authenticated() ? (
              <QuestionnaireList />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="/questionnaire/list/:id"
          element={
            state.authenticated() ? (
              <AnsweredQuestionnaires />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="/docs/:id"
          element={
            state.authenticated() ? (
              <DocsApp isSingleDocView="true" />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="/docs/new"
          element={
            state.authenticated() ? (
              <DocsApp isNewDocView="true" />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="/questionnaire"
          element={
            state.authenticated() ? (
              <QuestionnaireForm />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="/register"
          element={
            <RegisterPage />
          }
        />
        {/* <Route path="/login" element={<LoginPage />} /> */}
        <Route path="/login" element={
          state.authenticated() ? <Navigate to="/" /> : <LoginPage />
        } />
        <Route
          path="/user"
          element={
            state.authenticated() ? <ProfilePage /> : <Navigate to="/login" />
          }
        />
        <Route
          path="/cv"
          element={state.authenticated() ? <CVApp /> : <Navigate to="/login" />}
        />
        <Route
          path="/cv/list"
          element={state.authenticated() ? <CVLog /> : <Navigate to="/login" />}
        />
        <Route
          path="/docs"
          element={
            state.authenticated() ? <DocsApp /> : <Navigate to="/login" />
          }
        />
        <Route
          path="/createQuestionnaires"
          element={
            state.authenticated() ? (
              <CreateQuestionnaires />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="/usersTable"
          element={
            state.authenticated() && state.user()?.accessRole.includes("ADMIN") ? (
              <UsersTable />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="/positionsTable"
          element={
            state.authenticated() && state.user()?.accessRole.includes("ADMIN") ? (
              <PositionsTable />
            ) : (
              <Navigate to="/login" />
            )
          }
        />

        <Route
          path="/cardQuestion/:id"
          element={
            state.authenticated() ? <CardQuestion /> : <Navigate to="/login" />
          }
        />
        <Route
          path="/optionList/:id"
          element={
            state.authenticated() && state.user()?.accessRole.includes("ADMIN") ? (
              <OptionList />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="/modificaUtente/:id"
          element={
            state.authenticated() && state.user()?.accessRole.includes("ADMIN") ? (
              <ModificaUtente />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="/dettagliUtente/:id"
          element={
            state.authenticated() && state.user()?.accessRole.includes("ADMIN") ? (
              <DettagliUtente />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="/editOption/:id"
          element={
            state.authenticated() && state.user()?.accessRole.includes("ADMIN") ? (
              <EditOption />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="/usersAnswered/:id/:title"
          element={
            state.authenticated() && state.user()?.accessRole.includes("ADMIN") ? (
              <QuestionaryUsersAnswerd />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
      </Routes>
      {/* Altre card o contenuti */}
    </div>
  );
};
