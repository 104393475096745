import axios from "axios";
import {
  AJAX_ENDPOINT,
  reclone,
  FAKE_USER_ID,
  getHeaders,
} from "../commons/Constants";
import { clone } from "lodash";

export class CVMadreLinguaData {
  static async get(id) {
    const HEADERS = getHeaders();
    const resp = await axios.get(
      AJAX_ENDPOINT + "inet-jdk/madrelingua/get-madrelingua/" + id,
      {
        headers: HEADERS,
      }
    );
    if (resp.data.sesso === "M") {
      resp.data.sesso = "Maschile";
    }
    return resp.data;
  }
  static async put(madreLingua) {
    const HEADERS = getHeaders();
    madreLingua = reclone(madreLingua);
    let resp;
    if (madreLingua.id < 1) {
      delete madreLingua.id;
      resp = await axios.post(
        AJAX_ENDPOINT + "inet-jdk/madrelingua/create",
        madreLingua,
        {
          headers: HEADERS,
        }
      );
    } else {
      resp = await axios.put(
        AJAX_ENDPOINT + "inet-jdk/madrelingua/update/" + madreLingua.id,
        madreLingua,
        {
          headers: HEADERS,
        }
      );
    }
    return resp;
  }
  static async delete(id) {
    const HEADERS = getHeaders();
    const resp = await axios.delete(
      AJAX_ENDPOINT + "inet-jdk/madrelingua/deleteById/" + id,
      {
        headers: HEADERS,
      }
    );
    return resp;
  }
}
