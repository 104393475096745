import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import {DatePicker}from "@mui/x-date-pickers";
import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import it from "date-fns/locale/it";
import { convertToHTML } from 'draft-convert';
import debounce from 'lodash.debounce';
import Moment from 'moment';
import { LocalizationProvider } from '@mui/x-date-pickers'
import React, { useCallback, useEffect, useRef, useState } from "react";
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormHelperText from '@mui/material/FormHelperText';
import Checkbox from '@mui/material/Checkbox';
import CVUpdateProjectForm from "./CVUpdateProjectForm";
import {newProject} from "../commons/Constants";
import { Button } from '@mui/material';

//import { registerLocale } from 'react-datepicker';
//registerLocale('it', it);

function CVUpdateEspForm(props) {
  // const [count, setCount] = React.useState(0);
  const [data_inizio, setDataInizio] = useState(null);
  const [data_fine, setDataFine] = useState(null);
  const [lavoro_qui, setLavoroQui] = useState(false);
  const [qualifica, setQualifica] = useState(null);
  const [datore, setDatore] = useState(null);
  const [citta, setCitta] = useState(null);
  const [mansioni, setMansioni] = useState(null);
  const [progetti, setProgetti] = useState(null);

  const mansioni_rte = useRef(null);
  const oldmansioni = useRef(null);
  const first_change_of_rte = useRef(true);


  const changeHandler = e => {
    if (!first_change_of_rte.current){

    const plainText = e.getCurrentContent().getPlainText(); // for plain text
    // const rteContent = convertToRaw(e.getCurrentContent()); // for rte content with text formating
    // console.log(rteContent);
    // rteContent && setMansioni(JSON.stringify(rteContent)); // store your rteContent to state
     //console.log(convertToHTML(e.getCurrentContent()));
     const html = convertToHTML(e.getCurrentContent());
    // setMansioni(html);
     props.onMansioniChange(props.o.id, html , plainText);
    }else{
      first_change_of_rte.current = false;
    }
  };
  const debouncedChangeHandler = useCallback(
    debounce(changeHandler, 20)
  , []);
/*
  const [childKey, setChildKey] = useState(0);
  
  useEffect(() => {
    setChildKey(prev => prev + 1);
  },[props.key]); */

  useEffect(() => {
   // console.log('useEffect',props.o);
   //debugger
    setDataInizio(props.o.dataInizio);
    setDataFine(props.o.dataFine);
    setLavoroQui(props.o.lavoroQui);
    setDatore(props.o.datoreLavoro);
    setQualifica(props.o.qualifica);
    setCitta(props.o.citta);
    setMansioni(props.o.mansioni);
    setProgetti(props.o.progettiJson);

    if (props.o.dummy && typeof props.o.mansioni_touched === 'undefined') {
      //props.o.mansioni = "";
      mansioni_rte.current = "";
    }else{
      mansioni_rte.current = props.o.mansioni;
    }
    /*if (props.o.mansioni !== oldmansioni.current){
      oldmansioni.current = props.o.mansioni;
    // 1. Convert the HTML
    const contentHTML = convertFromHTML(props.o.mansioni);

    // 2. Create the ContentState object
    const state = ContentState.createFromBlockArray(
      contentHTML.contentBlocks,
      contentHTML.entityMap
    );

    // 3. Stringify `state` object from a Draft.Model.Encoding.RawDraftContentState object
    const content = JSON.stringify(convertToRaw(state));
    mansioni_rte.current=content;
    //mansioni_rte = content;
    console.log('content',content);
    }*/
    
  }, [props]);

  return (
    <Box
      sx={{
        "& .MuiTextField-root": { m: 1, width: "25ch" },
      }}
      
      autoComplete="off"
    >
     
      {qualifica!==null?
      <TextField
        required
        onBlur={(e)=>props.onQualificaChange(props.o.id, e.target.value)}
        name="qualifica"
        label="Qualifica"
        defaultValue={qualifica}
      />
:<div></div>}
      {datore!==null?
      <TextField
        required
        onBlur={(e)=>props.onDatoreChange(props.o.id, e.target.value)}
        name="datore_lavoro"
        label="Datore di lavoro"
        defaultValue={datore}
      />
      : <div></div>}

      <LocalizationProvider locale={it} dateAdapter={AdapterMoment}>
        <DatePicker
          views={["year", "month"]}
          label="Data di inizio"
          name="data_inizio"
          maxDate={data_fine ? Moment(data_fine,"YYYY-MM-DD") : Moment(new Date())}
          onChange={(newValue) => {
            //console.log(newValue);
            //console.log(Moment(newValue).format('YYYY-MM-DD'));
          setDataInizio(newValue.format('YYYY-MM-DD'));
            props.onDataInizioChange(props.o.id, newValue.format('YYYY-MM-DD'));
          }}
          value={Moment(data_inizio)}
          textField={(params) => (
            <TextField {...params} helperText={null} />
          )}
        />

        {! (lavoro_qui ==="1" || lavoro_qui === 1 || lavoro_qui === true) ? (
          <DatePicker
            views={["year", "month"]}
            label="Data di fine"
            name="data_fine"
            minDate={data_inizio ? Moment(data_inizio,"YYYY-MM-DD") : Moment(new Date())}
            maxDate={Moment(new Date())}
            onChange={(newValue) => {
            setDataFine(newValue.format('YYYY-MM-DD'));
              props.onDataFineChange(props.o.id, newValue.format('YYYY-MM-DD'));
            }}
            value={Moment(data_fine)}
            textField={(params) => (
              <TextField {...params} helperText={null} />
            )}
          />
          ) : (
          <div></div>
        )}
</LocalizationProvider>
<Box
            component="form"
            sx={{
              "& .MuiTextField-root": { m: 1, width: "25ch" },
            }}
            
            autoComplete="off"
          >
        
        <FormControlLabel
          control={
            <Switch
              checked={  (lavoro_qui ==="1" || lavoro_qui === 1 || lavoro_qui === true) ? true : false}
              onChange={(e) => {
                setLavoroQui(e.target.checked);
                props.onLavoroQuiChange(props.o.id, e.target.checked);

              }}
            />
          }
          label="Attualmente lavoro qui"
        />
</Box>
{citta !==null?
      <TextField
        required
        onBlur={(e)=>props.onCittaChange(props.o.id, e.target.value)}
        name="citta"
        label="Città"
        defaultValue={citta}
      />
:<div></div>}

<h3>Progetti</h3> 
<h6>Partendo dal più recente, controlla la lista dei progetti per questa esperienza lavorativa: </h6>

    <Button
            variant="outlined"
            color="primary" onClick={()=>{      
  progetti.splice(0,0,newProject(props.o));
      props.onProgettiChange(props.o.id,progetti,true,"project-"+props.o.id+"-"+(0));
    }}
>Aggiungi progetto</Button>


{ progetti !==null && progetti.map((o, i) => {
    return (<CVUpdateProjectForm 
      onProgettoChange={(idx, value)=>{
        //console.log(value);
        progetti[idx].progetto = value;
        props.onProgettiChange(props.o.id,progetti, false);
      }}
      onScopoChange={(idx, value)=>{
        progetti[idx].scopo = value;
        props.onProgettiChange(props.o.id,progetti);
      }}
      onRuoloChange={(idx, value)=>{
        progetti[idx].ruolo = value;
        props.onProgettiChange(props.o.id,progetti);

      }}
      onTecnologieChange={(idx, value)=>{
        progetti[idx].tecnologie = value;
        props.onProgettiChange(props.o.id,progetti);

      }}
      onClienteChange={(idx, value)=>{
        progetti[idx].cliente = value;
        props.onProgettiChange(props.o.id,progetti);

      }}
      onDataInizioChange={(idx, value)=>{
        progetti[idx].data_inizio = value;
        props.onProgettiChange(props.o.id,progetti,true,"project-"+props.o.id+"-"+idx);

      }}
      onDataFineChange={(idx, value)=>{
        progetti[idx].data_fine = value;
        props.onProgettiChange(props.o.id,progetti,true,"project-"+props.o.id+"-"+idx);

      }}
      onLavoroQuiChange={(idx, value)=>{
        progetti[idx].lavoro_qui = value;
        props.onProgettiChange(props.o.id,progetti);

      }}
      onDeleteProjectAtIndex={(idx)=>{
     // console.log('delete');
      progetti.splice(idx,1);
      props.onProgettiChange(props.o.id,progetti, true,"project-"+props.o.id+"-"+idx);
    }}
    onAddProjectAtIndex={(idx)=>{
      progetti.splice(idx+1,0,newProject(props.o));
     // console.log(progetti);
      props.onProgettiChange(props.o.id,progetti,true,"project-"+props.o.id+"-"+(idx+1));
    }} key={Math.random()+'_'+i} i={i} o={o} parent={props.o}></CVUpdateProjectForm>);
})}

        { (false) ? (
          <div className="mui-rte-holder"><h6>Mansioni</h6>
         <Box sx={{ width: '75%' }}>
                <TextField
        required
        style ={{width: '100%'}}
        multiline
        placeholder="Descrivi qui di cosa ti sei occupato/a
        - Puoi creare elenchi
        - Inserendo un elemento
        - Per riga"
        onBlur={(e)=>props.onMansioniChange(props.o.id, e.target.value, e.target.value)}
        name="manisoni"
        label="Descrivi di cosa ti sei occupata/o nel dettaglio"
        defaultValue={mansioni_rte.current}
      />
      </Box>
<br/>
          <br/>
          </div>
        ) : (
          <div></div>
        )}
    </Box>
  );
}

export default CVUpdateEspForm;
