import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import React, { useEffect, useState } from "react";
import jQuery from "jquery";
import { AJAX_ENDPOINT } from "../commons/Constants";
import { merge, clone } from 'lodash';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import it from "date-fns/locale/it";
import {DatePicker} from "@mui/x-date-pickers";
import Moment from 'moment';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { LocalizationProvider } from '@mui/x-date-pickers'
import Select from '@mui/material/Select';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import "yup-phone";
import { Autocomplete } from '@mui/material';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import FormHelperText from '@mui/material/FormHelperText';
import { Document, Page, pdfjs } from 'react-pdf';
import UploadIcon from '@mui/icons-material/Upload';
import { useNavigate } from 'react-router';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function DocsNew(props) {
  // const [count, setCount] = React.useState(0);
  let navigate = useNavigate();

  const [userList, setUserList] = useState([]);
  const [docdata, setDocData] = useState({ data_doc:  Moment().format("YYYY-MM-DD HH:mm:ss") });
  const [loadingMsg, setLoadingMsg] = useState("Caricando utenti...");
  const [currentUser, setCurrentUser] = useState(null);
  const [pdfDataUrl, setPdfDataUrl] = useState(null);
  const [pdfUrl, setPdfUrl] = useState(null);
  const [uploadingMsg, setUploadingMsg] = useState("Caricando il file...");

  const subjectOptions = [
    { label: 'Contratto' },
  ];

  const validationSchema = Yup.object().shape({
    subject: Yup.string().required("L'oggetto del documento è richiesto"),
    data_doc: Yup.string().transform(v => (v === null ? "" : v))
      .required("La data del documento è richiesta"),
    file_url: Yup.string().transform(v => (v === null ? "" : v)).url().required('Il percorso remoto del file del documento è richiesto')



    /*username: Yup.string()
      .required('Username is required')
      .min(6, 'Username must be at least 6 characters')
      .max(20, 'Username must not exceed 20 characters'),
    email: Yup.string()
      .required('Email is required')
      .email('Email is invalid'),
    password: Yup.string()
      .required('Password is required')
      .min(6, 'Password must be at least 6 characters')
      .max(40, 'Password must not exceed 40 characters'),
    confirmPassword: Yup.string()
      .required('Confirm Password is required')
      .oneOf([Yup.ref('password'), null], 'Confirm Password does not match'),
    acceptTerms: Yup.bool().oneOf([true], 'Accept Terms is required')*/
  });

  const {
    register,
    control,
    trigger,
    handleSubmit,
    formState: { errors }
  } = useForm({
    mode: 'all',
    resolver: yupResolver(validationSchema)
  });

  const apiCall = () => {
    //  setCount(2);
    jQuery
      .ajax({
        url: AJAX_ENDPOINT,
        data: {
          action: "jdk_inet_get_userlist",
        },
        dataType: "json",
      })
      .done(function (response) {
        setUserList(response);
        // setUInfo(null);
        //setCount(1);
        ////console.log(uinfo);
      })
      .fail(function () {
        props.onMessage("Errore AJAX", "error");
        setLoadingMsg("Errore AJAX");
      });
  };


  const apiCallSave = () => {
    //  setCount(2);
    jQuery
      .ajax({
        url: AJAX_ENDPOINT,
        method: "POST",
        data: {
          action: "jdk_inet_put_document",
          data: { docdata: docdata, user_id: currentUser?.id, fileUrl: pdfUrl },
        },
        dataType: "json",
      })
      .done(function (response) {
        if (response === 1) {
          navigate("/docs");
        }else{
          alert("Si è verificato un errore, si prega di riprovare");
        }
        // setUInfo(null);
        //setCount(1);
        ////console.log(uinfo);
      })
      .fail(function () {
        alert("Si è verificato un errore, si prega di riprovare");
        props.onMessage("Errore AJAX", "error");
      });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    trigger();
    handleSubmit((data, evt) => {
      console.log(docdata);
      apiCallSave();

    }, (errors, evt) => {
      console.log(errors);
    })();

  };

  useEffect(() => {
    apiCall();
  }, [props]);


  const handleFileUploadChange = (field,evt) => {
    const fileReader = new FileReader();
    //const name = target.accept.includes('image') ? 'images' : 'videos';

    fileReader.readAsDataURL(evt.target.files[0]);
    fileReader.onload = (e) => {
      setPdfDataUrl(e.target.result);
      jQuery
        .ajax({
          url: AJAX_ENDPOINT,
          method: "POST",
          data: {
            action: "jdk_inet_upload_document",
            data: e.target.result,
            user_id: currentUser.id,
            subject: docdata.subject,
          },
          dataType: "json",
        })
        .done(function (response) {
          //alert(response);
          setPdfUrl(response.url);
          field.onChange(response.url);
          // setUInfo(null);
          //setCount(1);
          ////console.log(uinfo);
        })
        .fail(function () {
          setUploadingMsg("Errore AJAX");
          props.onMessage("Errore AJAX", "error");
        });
    };
  };


  return (
    <div className="JDKReactApp-header visible">
      <section className="formcontainer customscrollbar">
        {userList.length > 0 ? (
          <>
            {!currentUser ?
              (<> <h3>Scegli il destinatario del documento</h3><br /> <Autocomplete
                id="foruser"
                options={userList}
                getOptionLabel={(option) => option.name}
                sx={{ width: '100%', minWidth: 450 }}
                renderOption={(props, option) => (
                  <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                    <img
                      loading="lazy"
                      width="64"
                      src={`${option.avatar_uri}`}
                      alt=""
                    />
                    {option.name}
                  </Box>
                )}
                textField={(params) => <TextField {...params} label="Destinatario" />}
                onChange={(e, value) => { setCurrentUser(value); }}
              />
              <br/>Oppure
              <br/>
              <br/>
                  <Button
            variant="outlined"
            color="primary" className="btn btn-success" onClick={() => { setCurrentUser({ avatar_uri: 'https://www2.jdk.it/wp-content/uploads/2021/02/Logo_JDK.png', id: 0, name: "Tutti i dipendenti" }); }}>Invia a tutti</Button>
              </>) :
              ""
            }
            {currentUser ? (
              <>
                <h3>Caricando un documento per </h3>
                <h3>   <Box component="div" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', '& > img': { mr: 2, flexShrink: 0, } }} {...props}>
                  <img
                    loading="lazy"
                    width="64"
                    src={`${currentUser.avatar_uri}`}
                    alt=""
                  />
                  {currentUser.name}
                </Box> {
                    //<small>({currentUser.email})</small>
                  }
                  <br />       <Button
            variant="outlined"
            color="primary" className="btn btn-success" onClick={() => setCurrentUser(null)}>Cambia utente di destinazione</Button>
                </h3>
                <Box
                  component="form"
                  sx={{
                    "& .MuiTextField-root": { m: 1, width: "25ch" },
                  }}

                  autoComplete="off"
                >
                  {/*  <Controller
        name="subject"
        control={control}
        defaultValue={""}
        render={({ field: { ref, ...field }, fieldState: { error } }) => (
          <Autocomplete
            {...field}
            disableClearable
            disablePortal
        freeSolo
        required
        options={subjectOptions}
        name="description"
        label="Descrizione documento"
        defaultValue={docdata?.subject}
        getOptionLabel={(option) => option.label}
        textField={(params) => <TextField              
        {...params} label="Oggetto" />}
        error={errors.subject ? true : false}
        helperText={errors.subject?.message}
        onInputChange={(e)=>{
          var o = clone(docdata);
          merge(o,{subject: e.target.value});
          console.log(o,errors.subject);
          setDocData(o);
        }}
      /> 
  )}
      />
*/
                  }

                  <Controller
                    name="subject"
                    control={control}
                    defaultValue={""}
                    render={({ field: { ref, ...field }, fieldState: { error } }) => (
                      <Autocomplete
                        {...field}
                        freeSolo
                        disabled={pdfUrl !== null}
                        disableClearable
                        disablePortal
                        getOptionLabel={(option) => option.label ? option.label : option}
                        id="subject-autocomplete"
                        onChange={(event, value) => {
                          const val = value.label ? value.label : value;
                          field.onChange(val)
                          var o = clone(docdata);
                          merge(o, { subject: val });
                          setDocData(o);
                        }}
                        onInputChange={(event, value) => {
                          //console.log(event,value);
                          field.onChange(value);
                          var o = clone(docdata);
                          merge(o, { subject: value });
                          setDocData(o);
                        }}
                        options={subjectOptions}
                        textField={(params) => (
                          <TextField
                            required
                            error={!!error}
                            helperText={error?.message}
                            id="subject"
                            label="Oggetto del documento"
                            name="subject"
                            type="text"
                            inputRef={ref}
                            {...params}
                          />
                        )}
                      />
                    )}
                  />

                  <Controller
                    name="data_doc"
                    control={control}
                    defaultValue={Moment().format("YYYY-MM-DD HH:mm:ss")}
                    render={({ field: { ref, ...field }, fieldState: { error } }) => (
                      <LocalizationProvider locale={it} dateAdapter={AdapterMoment}>
                        <DatePicker
                          {...field}
                          label="Data del documento"
                          name="data_documento"
                          maxDate={Moment(new Date())}
                          onChange={(newValue) => {
                            field.onChange(newValue);
                            var o = clone(docdata);
                            merge(o, { data_doc: Moment(newValue).format("YYYY-MM-DD HH:mm:ss") });
                            //console.log(o);
                            setDocData(o);
                          }}
                          value={Moment(docdata?.dataDoc)}
                          textField={(params) => <TextField error={!!error}
                            helperText={error?.message} {...params} />}
                        />
                      </LocalizationProvider>)} />

                  <br />
                  <Controller
        name="file_url"
        control={control}
        defaultValue={""}
        render={({ field: { ref, ...field }, fieldState: { error } }) => (
                  <>          
                  {docdata?.subject && errors.subject === undefined ? (
                    <>
                      <h4>Il documento da caricare</h4>
                      {pdfDataUrl === null ? (
                        <IconButton color="primary" aria-label="upload picture" component="label">
                          <input hidden accept="application/pdf" onChange={(evt)=>{handleFileUploadChange(field,evt);}} type="file" />
                          <UploadIcon />
                        </IconButton>
                      ) : (
                        pdfUrl ? (
                          <>
                            <Document file={pdfUrl}>
                              <Page pageNumber={1} />
                            </Document>

                                <Button
            variant="outlined"
            color="primary" className="btn btn-success" onClick={() => { field.onChange(null); setPdfUrl(null); setPdfDataUrl(null); }}>Cambia file</Button>

                          </>
                        ) : (
                          <div>
                            {uploadingMsg}      </div>
                        )
                      )}
                    </>)
                    : ""}
                  {error?.message? <h4 style={{padding: '10px', backgroundColor: '#ff000044', color:'#fff'}}>{error.message}</h4> : ""}
                  </> )}/>
                  <nav>
                        <Button
            variant="outlined"
            color="primary" className="btn btn-success" disabled={Object.keys(errors).length === 0 && errors.constructor === Object ? false : true} onClick={onSubmit}>Salva e invia documento</Button>
                  </nav>

                </Box>
              </>) : ""}
          </>
        ) : <h3>{loadingMsg}</h3>}
      </section>
    </div>
  );
}

export default DocsNew;
