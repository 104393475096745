import React, { useEffect, useState } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./App.scss";
import { AppRoutes } from "./AppRoutes";
import {
  AppBar,
  Toolbar,
  Box,
  Button,
  Container,
  Typography,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Drawer,
  Tooltip,
  Avatar,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import HomeIcon from "@mui/icons-material/Home";
import ListAltIcon from "@mui/icons-material/ListAlt";
import AssessmentIcon from "@mui/icons-material/Assessment";
import DescriptionIcon from "@mui/icons-material/Description";
import PersonIcon from "@mui/icons-material/Person";
import LogoutIcon from "@mui/icons-material/Logout";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import { useGlobalState } from "./store/state";
import { Menu, MenuItem } from "@mui/material";
import AccountCircle from "@mui/icons-material/AccountCircle";
import GroupIcon from "@mui/icons-material/Group";
import { Buffer } from "buffer";
import { ROLE_ADMIN } from "./commons/Constants";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import HomeRepairServiceIcon from "@mui/icons-material/HomeRepairService";

const lightTheme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#8376A6",
    },
  },
});

const MainLayout = (props) => {
  const state = useGlobalState();
  const userId = state.userId();
  const token = state.token();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const theme = useTheme();
  const location = useLocation();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const matchesL = useMediaQuery(theme.breakpoints.down(760));
  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    if (state.user()?.imageBase64) {
      const cleanBase64String = state
        .user()
        .imageBase64.replace(/^\"data:image\/(jpeg|png);base64,/, "");

      const convertBase64ToBlob = (base64) => {
        const binaryString = Buffer.from(base64, "base64").toString("binary");
        const byteArray = new Uint8Array(binaryString.length);
        for (let i = 0; i < binaryString.length; i++) {
          byteArray[i] = binaryString.charCodeAt(i);
        }
        return new Blob([byteArray], { type: "image/jpeg" });
      };

      const blob = convertBase64ToBlob(cleanBase64String);
      setImageUrl(URL.createObjectURL(blob));
    }
  }, [state.user()?.imageBase64]);

  React.useEffect(() => {
    console.log(userId);
    if (userId && token) {
      state.getUser();
    }
  }, [userId, token]);

  const userNavLinks = [
    {
      href: "/user",
      title: "Your Profile",
      icon: <PersonIcon />,
      showInToolbar: true,
    },
    {
      command: () => state.logout(),
      title: "Logout",
      icon: <LogoutIcon />,
      showInToolbar: false,
    },
  ];
  //Togliere disabled quando saranno pronti i componenti
  const navLinks = [
    {
      href: "/",
      title: "Home",
      icon: <HomeIcon />,
      showInToolbar: true,
    },
    {
      href: "/user",
      title: "Il tuo profilo",
      icon: <PersonIcon />,
      showInToolbar: false,
    },
    /*     {
          href: "/docs/new",
          title: "I tuoi documenti",
          icon: <DescriptionIcon />,
          showInToolbar: false,
          disabled: true,
        }, */
    /*     {
          href: "/news",
          title: "Le nostre news",
          icon: <AssessmentIcon />,
          showInToolbar: false,
          disabled: true,
        }, */
    {
      href: "/cv",
      title: "Aggiorna il tuo CV",
      icon: <ManageAccountsIcon />,
      showInToolbar: true,
    },
    /*     {
          href: "/docs",
          title: "I tuoi documenti amministrativi",
          icon: <DescriptionIcon />,
          showInToolbar: true,
          disabled: true,
        }, */
    {
      href: "/questionnaire",
      title: state?.user()?.accessRole.includes("ADMIN")
        ? "Questionari"
        : "I tuoi questionari",
      icon: <AssessmentIcon />,
      showInToolbar: true,
    },
    {
      href: "/questionnaire/list",
      title: state?.user()?.accessRole.includes("ADMIN")
        ? "Risposte"
        : "Le tue risposte",
      icon: <ListAltIcon />,
      showInToolbar: true,
    },
    {
      href: "/usersTable",
      title: "Pannello utenti",
      icon: <GroupIcon />,
      showInToolbar: false,
      admin: true,
    },
    {
      href: "/positionsTable",
      title: "Pannello posizioni",
      icon: <HomeRepairServiceIcon />,
      showInToolbar: false,
      admin: true,
    },
    {
      href: "/questionnaire",
      title: "I tuoi questionari",
      icon: <AssessmentIcon />,
      showInToolbar: false,
      admin: true,
    },
    {
      href: "/register",
      title: "Crea un utente",
      icon: <PersonAddAlt1Icon />,
      showInToolbar: false,
      admin: true,
    },
    {
      command: () => state.logout(),
      title: "Logout",
      icon: <LogoutIcon />,
      showInToolbar: false,
    },
  ];

  const questForAdmin = (link) => {
    console.log("il link è: ", link);
    if (
      link.title === "I tuoi questionari" &&
      state.user()?.accessRole.includes("ADMIN")
    ) {
      state.setQuestForAdmin(true);
      navigate(link.href);
    } else if (
      link.title === "Questionari" &&
      state.user()?.accessRole.includes("ADMIN")
    ) {
      state.setQuestForAdmin(false);
      navigate(link.href);
    } else {
      navigate(link.href);
    }
  };

  const filteredNavLinks = navLinks.filter((link) => {
    // Filtra solo i link che hanno la proprietà admin true quando l'utente ha accesso come admin
    return (
      !link.admin || (link.admin && state.user()?.accessRole.includes("ADMIN"))
    );
  });

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    console.log({ open });
    setDrawerOpen(open);
  };

  const DrawerList = () => (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        {filteredNavLinks.map((link, index) => (
          <ListItem
            button
            key={link.title}
            disabled={link.disabled}
            onClick={() =>
              link.command ? link.command() : questForAdmin(link)
            }
          >
            <ListItemIcon>{link.icon}</ListItemIcon>
            <ListItemText primary={link.title} />
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const menuId = "primary-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <List>
        {userNavLinks.map((link, index) => (
          <ListItem
            button
            key={link.title}
            disabled={link.disabled}
            onClick={() =>
              link.command ? link.command() && navigate("/") : navigate(link.href)
            }
          >
            <ListItemIcon>{link.icon}</ListItemIcon>
            <ListItemText primary={link.title} />
          </ListItem>
        ))}
      </List>
    </Menu>
  );

  return (
    <ThemeProvider theme={lightTheme}>
      <CssBaseline />
      <AppBar
        className="navbar-inet"
        position="static"
        color="default"
        elevation={0}
      >
        <Toolbar style={{ justifyContent: "space-between" }}>
          <Box style={{ display: "flex", alignItems: "center" }}>
            {state.authenticated() && (
              <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={toggleDrawer(true)}
                sx={{ mr: 2 }}
              >
                <MenuIcon />
              </IconButton>
            )}
            <img
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/")}
              src="/jdk-logo.webp"
              alt="jdk logo"
              className="logo"
            />
            {matches && (
              <Typography variant="h6" noWrap component="div">
                &nbsp;&nbsp;&nbsp;&nbsp; TEAM MEMBER HUB
              </Typography>
            )}
          </Box>

          <Box
            className="toolbar-container"
            sx={{
              display: { xs: "none", sm: "block" },
              overflowX: "auto", // Permette lo scorrimento orizzontale
              whiteSpace: "nowrap", // Mantiene tutto in una singola linea
              "&::-webkit-scrollbar": { display: "none" }, // Nasconde la scrollbar su Webkit/Blink
              scrollbarWidth: "none", // Nasconde la scrollbar su Firefox
            }}
          >
            {state.user()?.nome && (
              <IconButton
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                color="inherit"
              >
                {imageUrl ? (
                  <Avatar
                    sx={{ width: 50, height: 50 }}
                    alt={"profile"}
                    src={imageUrl}
                  />
                ) : (
                  <AccountCircle />
                )}
                &nbsp;{" "}
                <span style={{ fontSize: "16.4px", fontWeight: "bold" }}>
                  {state.user().nome + " " + state.user().cognome}
                </span>{" "}
                &nbsp; &nbsp;
              </IconButton>
            )}
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
        <DrawerList />
      </Drawer>

      <Container className="smoked-white-bg" maxWidth="xl">
        <Box my={4}>
          {state.loading() === true ? <div>Caricamento...</div> : ""}
          {/*           {state.user() === null ? (
            <div className="JDKReactApp">
              Prima effettua il login...
              <br />
              <a
                href={
                  "/login/?redirect_to=https%3A%2F%2Fwww2.jdk.it%2Fjdk-intranet-app%2F" +
                  window.location.hash
                }
              >
                <Button variant="outlined" color="primary">
                  Accedi
                </Button>
              </a>
            </div>
          ) : (
            <div className="JDKReactApp">
              <AppRoutes />
            </div>
          )} */}
          <div className="JDKReactApp">
            <AppRoutes />
          </div>
        </Box>
      </Container>

      {location.pathname === "/cv" && matchesL ? (
        <></>
      ) : (
        <AppBar
          className="toolbar-inet"
          position="fixed"
          color="primary"
          sx={{ top: "auto", bottom: 0 }}
        >
          <Toolbar>
            {state.authenticated() && (
              <Container maxWidth="xl" sx={{ textAlign: "center" }}>
                {navLinks
                  .filter((i) => i.showInToolbar)
                  .map((link) => (
                    <Tooltip title={link.title} key={link.title}>
                      <IconButton
                        color="inherit"
                        disabled={link.disabled}
                        onClick={() =>
                          link.command ? link.command() : questForAdmin(link)
                        }
                      >
                        {link.icon}
                      </IconButton>
                    </Tooltip>
                  ))}
              </Container>
            )}
          </Toolbar>
        </AppBar>
      )}

      {state.user()?.nome && renderMenu}
    </ThemeProvider>
  );
};

export default MainLayout;
