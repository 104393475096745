import React, { useEffect, useState } from "react";
import { useGlobalState } from "../store/state";
import { reclone } from "../commons/Constants";
import {
  Alert,
  Box,
  Button,
  Snackbar,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import ProfileCard from "../components/ProfileCard";
import UserProfileCard from "../components/UserInformation";
import "./ProfilePage.scss";
import ModificaProfilo from "../components/ModificaProfilo";
import ModifiedPassword from "../components/ModifiedPassword";
import { Positions } from "../datasource/Positions";

const ProfilePage = () => {
  const state = useGlobalState();
  const [profile, setProfile] = useState(null);
  const [isModified, setIsModified] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const [modifiedPassword, setModifiedPassword] = useState(false);
  const [posizione, setPosizione] = useState(null);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up(500));

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setError(false);
  };

  const onMessage = (m, s) => {
    setSeverity(s);
    setError(true);
    setErrorMessage(m);
    setTimeout(() => {
      setError(false);
    }, 6000);
  };

  useEffect(() => {
    if (!isModified) {
      state
        .getUser()
        .then(() => setProfile(reclone(state?.user())))
        .then(() =>
          Positions.get(state.user()?.posizioneRicopertaId).then((data) =>
            setPosizione(data.nome)
          )
        );
    }
  }, [isModified]);

  const employeeData = {
    imageSrc: profile?.imageBase64
      ? profile.imageBase64
      : "https://banner2.cleanpng.com/20180508/toe/kisspng-user-profile-computer-icons-clip-art-5af1ac8cee74c6.8111281615257877889767.jpg",
    /* imageSrc: profile?.image, */
    nome: profile?.nome,
    cognome: profile?.cognome,
    email: profile?.email,
    posizione: posizione,
    citta:profile?.citta,
    telefono: profile?.tel,
    cap: profile?.cap,
  };

  return (
    <Box marginBottom={matches ? null : "100px"} className="profile-container">
      <ProfileCard {...employeeData} matches={matches} />
      {!isModified && !modifiedPassword ? (
        <UserProfileCard
          position={posizione}
          phone={profile?.tel}
          birthDate={profile?.dataNasc}
          nationality={profile?.nazionalita}
          citta={profile?.citta}
          sesso={profile?.sesso}
          setIsModified={setIsModified}
          setModifiedPassword={setModifiedPassword}
          matches={matches} 
        />
      ) : modifiedPassword ? (
        <ModifiedPassword
          onMessage={onMessage}
          setModifiedPassword={setModifiedPassword}
          matches={matches} 
        />
      ) : (
        <ModificaProfilo
          onMessage={onMessage}
          setIsModified={setIsModified}
          tel={profile?.tel}
          citta={profile?.citta}
          cap={profile?.cap}
          image={profile?.imageBase64}
          id={profile?.wpUserId}
          matches={matches} 
        />
      )}
      <Snackbar open={error} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={severity} sx={{ width: "100%" }}>
          {errorMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default ProfilePage;
