import axios from "axios";
import {
  AJAX_ENDPOINT,
  reclone,
  FAKE_USER_ID,
  getHeaders,
} from "../commons/Constants";
import { clone } from "lodash";

export class User {
  static async get(id) {
    const HEADERS = getHeaders();
    const resp = await axios.get(AJAX_ENDPOINT + "inet-jdk/get/" + id, {
      headers: HEADERS,
    });
    //da rimuovere successivamente
    if (resp.data.sesso === "Maschio") {
      resp.data.sesso = "Maschile";
    }
    return resp.data;
  }

  static async getAll() {
    const HEADERS = getHeaders();
    const resp = await axios.get(AJAX_ENDPOINT + "inet-jdk/getall", {
      headers: HEADERS,
    });
    return resp.data;
  }

  static async deleteByListId(id) {
    const HEADERS = getHeaders();
    const resp = await axios.delete(AJAX_ENDPOINT + "inet-jdk/deleteByListId", {
      params: { ids: id },
      headers: HEADERS,
    });
    return resp;
  }

  static async deleteById(id) {
    const HEADERS = getHeaders();
    const resp = await axios.delete(
      AJAX_ENDPOINT + "inet-jdk/deleteById/" + id,
      {
        headers: HEADERS,
      }
    );
    return resp;
  }

  //completare lo user con i vari delete!!!
  static async put(user) {
    const HEADERS = getHeaders();
    user = reclone(user);
    delete user.competenzaList;
    delete user.competenzaTxtList;
    delete user.espLavList;
    delete user.istruzioneList;
    delete user.admin;
    const resp = await axios.put(
      AJAX_ENDPOINT + "inet-jdk/update/" + user.wpUserId,
      user,
      {
        headers: HEADERS,
      }
    );
    return resp;
  }
  static async post(user) {
    const HEADERS = getHeaders();
    user = reclone(user);
    let resp;
    resp = await axios.post(AJAX_ENDPOINT + "api/v1/auth/register", user/* , {
      headers: HEADERS,
    } */);
    return resp;
  }

  static async putPassword(id, password, oldPassword) {
    const HEADERS = getHeaders();
    let resp;
    resp = await axios.put(AJAX_ENDPOINT + "inet-jdk/changepassword", null, {
      params: {
        idUtente: id,
        newPassword: password,
        oldPassword: oldPassword,
      },
      headers: HEADERS,
    });
    return resp;
  }

  static async putAvatar(avatar, id) {
    const HEADERS = getHeaders();
    let resp;
    resp = await axios.put(
      AJAX_ENDPOINT + "inet-jdk/update_avatar/" + id,
      avatar,
      {
        headers: HEADERS,
      }
    );
    return resp;
  }

  static async putTelephone(tel, id) {
    const HEADERS = getHeaders();
    let resp;
    resp = await axios.put(
      AJAX_ENDPOINT + "inet-jdk/update_phone/" + id,
      null,
      { params: { new_phone: tel }, headers: HEADERS }
    );
    return resp;
  }

  static async putCity(citta, id) {
    console.log("citta: ", citta);
    const HEADERS = getHeaders();
    let resp;
    resp = await axios.put(
      AJAX_ENDPOINT + "inet-jdk/update_citta/" + id,
      null,
      { params: { citta: citta }, headers: HEADERS }
    );
    return resp;
  }

  static async putCap(cap, id) {
    const HEADERS = getHeaders();
    let resp;
    resp = await axios.put(
      AJAX_ENDPOINT + "inet-jdk/update_cap/" + id,
      null,
      { params: { cap: cap }, headers: HEADERS }
    );
    return resp;
  }

  static async login(token, email, password) {
    const resp = await axios.post(
      AJAX_ENDPOINT + "api/v1/auth/authenticate",
      { email, password },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return resp.data;
  }

  static async getToken(email) {
    const resp = await axios.get(AJAX_ENDPOINT + "inet-jdk/get/token/" + email);
    return resp.data;
  }

  static async getByEmail(token, email) {
    const resp = await axios.get(
      AJAX_ENDPOINT + "inet-jdk/getByEmail/" + email,
      /* { headers: HEADERS } */
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return resp.data;
  }

  static async getByEmailWithoutToken(email) {
    const HEADERS = getHeaders();
    const resp = await axios.get(
      AJAX_ENDPOINT + "inet-jdk/getByEmail/" + email,
      {
        headers: HEADERS,
      }
    );
    return resp.data;
  }

  static async getInfoAgencies() {
    const HEADERS = getHeaders();
    const resp = await axios.get(AJAX_ENDPOINT + "inet-jdk/info-agencies", {
      headers: HEADERS,
    });
    return resp.data;
  }

  static async adminUpdate(id,user) {
    const HEADERS = getHeaders();
    const resp = await axios.put(
      AJAX_ENDPOINT + "inet-jdk/admin/update/" + id,
      user,
      { headers: HEADERS }
    );
    console.log("response", resp); 
    return resp;
  }

  static async logout() {
    const HEADERS = getHeaders();
    const resp = await axios.put(AJAX_ENDPOINT + "api/v1/auth/logout", null, {
      headers: HEADERS,
    });
    return resp.data;
  }
}
