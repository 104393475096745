import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
import * as React from "react";

function QuestionSelectMultiple(props) {
    const [answer, setAnswer] = React.useState([]);
  
    const handleChange = (event) => {
      setAnswer(event.target.value);
      props.onAnswerChange(event.target.value);
    };
  
    return (
      <Box sx={{ minWidth: 120 }}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Risposta</InputLabel>
          <Select
            multiple
            labelId="demo-simple-select-label"
            className={`question-answerselectbox querstion-answer_${props.q.id}`}
            value={answer}
            label="Risposta"
            input={<OutlinedInput label="Tag" />}
            renderValue={(selected) => selected.join(", ")}
            onChange={handleChange}
          >
            {props.q.questionAnswers.map((ans, i) => (
              <MenuItem key={ans} value={ans}>
                <Checkbox checked={answer.indexOf(ans) > -1} />
                <ListItemText primary={ans} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    );
  }

  export default QuestionSelectMultiple;