import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import TextField from "@mui/material/TextField";
import jQuery from "jquery";
import { clone, debounce } from 'lodash';
import Moment from 'moment';
import React, { useCallback, useEffect, useState } from "react";
import { AJAX_ENDPOINT, reclone } from "../commons/Constants";
import CVUpdateLingueForm from './CVUpdateLingueForm';
import Box from "@mui/material/Box";
import { Button, Grid } from '@mui/material';
import { useGlobalState } from '../store/state';
import { CVMadreLinguaData } from '../datasource/CVMadreLingua';



function stripHtml(html) {
  let tmp = document.createElement("DIV");
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || "";
}

function truncate( str, n, useWordBoundary ){
  if (str.length <= n) { return str; }
  const subString = str.substr(0, n-1); // the original check
  return (useWordBoundary 
    ? subString.substr(0, subString.lastIndexOf(" ")) 
    : subString) + "...";
};

function CVMadreLingua(props) {
  const [madre, setMadre] = useState([]);
  const [touched, setTouched] = useState(false);

  const state = useGlobalState();

  const [lockSorting, setLockSorting] = useState(false);

  const newMadreLingua = () => {
    madre.push({
       wpUserId: state.user()?.wpUserId,
       id: Math.random(),
       dummy: true,
       descrizione: ""
     });
     refreshState();
   };

   const deleteLinguaMadre = async(i) => {
    if (window.confirm('Vuoi eliminare l\'elemento selezionato?')){
    const splitId = madre.splice(i, 1);
    setTouched(true);
    await CVMadreLinguaData.delete(splitId[0].id);
    refreshState();   
  }
};


  const sortUp = (i) => {
    if (i>0 && !lockSorting){
      let tmpM = madre[i-1];
      madre[i-1]=madre[i];
      madre[i] = tmpM;
      setTouched(true);
      refreshState();
    }
  };

  const sortDown = (i) => {
    if (i<madre.length-1 && !lockSorting){

      let tmpM = madre[i+1];
      madre[i+1]=madre[i];
      madre[i] = tmpM;
      setTouched(true);
      refreshState();
    }
  }


  const refreshState = () => {
    setMadre(clone(madre));
    //setLockSorting(false);
  }

  const _refreshState = () => {
    setMadre(clone(madre));
    setLockSorting(false);
  }

  const refreshStateDebounced = useCallback(
    debounce(refreshState, 30)
  , [madre]);

  const refreshStateDebouncedLarge = useCallback(
    debounce(_refreshState, 5000)
  , [madre]);


  const _refreshStateDebounced = () => {
    setMadre(clone(madre));
  }
 
  const apiCall = async () => {    //  setCount(2);
    await state.getMadreLingua();
    let madre = state.madreLingua();
    madre = reclone(madre)
    setMadre(madre);
   };

 
  const apiCallSave = async () => {
    try{
      const modifiedMadreLing = madre.filter((o)=> o.touched===true);
      let promises = [];
      for (let i = 0; i < modifiedMadreLing.length; i++) {
        modifiedMadreLing[i].touched = false;
        promises.push(CVMadreLinguaData.put(modifiedMadreLing[i]));
      }
      await Promise.all(promises);
      state.getMadreLingua();
      props.onMessage("Salvataggio effettuato", "success");

    }catch(e){
      console.error(e);
      props.onCardSkimPrev(document.querySelector("#skimprev-esplav"));
      props.onMessage("Errore nel salvataggio", "error");
    }
  };

  useEffect(() => {
    apiCall();
  }, []);


  return (
    <div className="JDKReactApp-header visible" >
      <h4>Controlla le tue informazioni sulle lingue conosciute</h4>
    
        {madre.length > 0 ? (
          <section className="formcontainer customscrollbar" style={{height: '100%', width: '100%'}}>
            <h4>Madrelingua:</h4>
            {madre.map((o, i) => {
              const key = o.id;
              return (
                <Accordion className="lingue-accordion" key={i}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <div className="accordion-header-jdk">
                      <div className="accordion-header-item">
                        <div className="aheader">#</div>
                        {i + 1}
                      </div>
                      <div className="accordion-header-item">
                        <div className="aheader">Lingua</div>
                        {o.madrelingua}
                      </div>
                      <div className="accordion-header-item descrizione">
                        <div className="aheader">Azioni</div>
                        <span className="accordion-action" title="Sposta su" onClick={(e) => { e.preventDefault(); e.stopPropagation(); sortUp(i) }}><ArrowDropUpIcon /></span>
                        <span className="accordion-action" title="Sposta giù" onClick={(e) => { e.preventDefault(); e.stopPropagation(); sortDown(i) }}><ArrowDropDownIcon /></span>
                        <span className="accordion-action" title="Elimina definitiviamente" onClick={(e) => { e.preventDefault(); e.stopPropagation(); deleteLinguaMadre(i) }}><DeleteForeverIcon /></span>
                      </div>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <TextField
                      required
                      onChange={(e) => {
                        o.madrelingua = e.target.value;
                        o.touched = true;
                      }}
                      name="madrelingua"
                      label="Madrelingua"
                      defaultValue={o.madrelingua}
                      key={key}
                    />
                  </AccordionDetails>
                </Accordion>
              );
            })}
          </section>) :
          <div>Nessuna lingua madre aggiunta ...</div>
        }
      <nav>
        <Button
          variant="outlined"
          color="primary"
          onClick={(e) => {
            e.preventDefault();
            props.onCardSkimPrev(e.target);
          }}
        >
          Indietro
        </Button>{" "}
        &nbsp;
          <Button
            variant="outlined"
            color="primary"
            className="btn btn-success"
            onClick={(e) => {
              e.preventDefault();
              newMadreLingua();
            }}
          >
            Aggiungi lingua
        </Button>{" "}
        <Button
          variant="outlined"
          color="primary"
          onClick={(e) => {
            e.preventDefault();
            props.onCardSkim(e.target);
            apiCallSave();
          }}
        >
          Avanti
        </Button>
      </nav>
    </div>
  )
}


export default CVMadreLingua;
