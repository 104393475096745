import React, { useEffect, useState } from 'react';
import jQuery from "jquery";
import { DataGrid } from '@mui/x-data-grid';
import { AJAX_ENDPOINT } from "../commons/Constants";
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import FavoriteIcon from '@mui/icons-material/Favorite';
import NavigationIcon from '@mui/icons-material/Navigation';
import { green } from '@mui/material/colors';
import { useNavigate } from 'react-router';
import MUIDataTable from "mui-datatables";
import { Button } from '@mui/material';

function DocsList(props) {
  const [docs, setDocs] = useState([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  //const [docsOrig, setDocsOrig] = useState([]);
  const [docNotFoundLabel, setDocNotFoundLabel] = useState("Caricando...");
  let navigate = useNavigate();


  const navigateToAddDocument = () => {
    navigate("new");
  };
  const fabStyle = {
    position: 'absolute',
    bottom: 16,
    right: 16,
  };

  const fabGreenStyle = {
    color: 'common.white',
    bgcolor: green[600] + '!important',
    '&:hover': {
      bgcolor: green[600] + '!important',
    },
  };


  const apiCall = (tableState) => {
    const page = tableState?.page ? tableState.page : 0;
    const rowsPerPage = tableState?.rowsPerPage ? tableState.rowsPerPage : 10;
    setPage(page);
    setRowsPerPage(rowsPerPage);
    jQuery
      .ajax({
        url: AJAX_ENDPOINT,
        data: {
          action: "jdk_inet_get_docs",
          page,
          rowsPerPage,
        },
        dataType: "json",
      })
      .done(function (response) {
        // setDocsOrig(response);
        setCount(parseInt(response.count));
       // alert(parseInt(response.count));
       if (response.results.length > 0) {
        setDocs(response.results.map(o => props.isAdmin ? 
          [o.date_doc, o.subject, o.dest ? o.dest : "Tutti i dipendenti", o.department,o.sender,  o.url, o.id, o.can_delete]
          : [o.date_doc, o.subject, o.department,o.sender,  o.url, o.id, o.can_delete]));
       }
        if (response.results.length === 0 && response.count === 0) {
          setDocNotFoundLabel("Nessun documento trovato");
        }
        console.log(docs);
      }).fail(function () {
        setDocs(null);
        //  setDocsOrig(null);
        setDocNotFoundLabel("Nessun documento trovato, errore AJAX");
      });
  };

  useEffect(() => { apiCall(); }, [props]);


  const columns = [
    //{ field: 'id', headerName: 'ID', width: 70 },
    { field: 'date_taken', name: 'Data', width: 170 },
    { field: 'display_name', name: 'Oggetto', width: 170 }
  ];
  if (props.isAdmin){
    columns.push({ name: "A"});
  }
  columns.push({ name: "Area" });
  columns.push({ name: "Da" });
  columns.push({
      name: "Azioni", options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
                  <Button
            variant="outlined"
            color="primary" className="btn btn-success" onClick={() => {
                console.log(value, tableMeta);
                window.open(value, "about:blank");
              }}>
                Vedi
              </Button>&nbsp;
              {props.isAdmin && tableMeta.rowData[props.isAdmin ? 7 : 6] ? (
                    <Button
            variant="outlined"
            color="primary" className="btn btn-success" onClick={() => {
                  // const row = docsOrig[tableMeta.rowIndex];
                  const rowid = tableMeta.rowData[props.isAdmin ? 6 : 5];
                  if (window.confirm('Sei sicuro/a di voler eliminare il documento "'+tableMeta.rowData[1]+'"?')) {
                  console.log(tableMeta.rowData, rowid);
                  jQuery
      .ajax({
        url: AJAX_ENDPOINT,
        data: {
          action: "jdk_inet_delete_doc",
          id: rowid,
          file_url: value
        },
        dataType: "json",
      })
      .done(function (response) {
        // setDocsOrig(response);
        apiCall();
        
      }).fail(function () {
        apiCall();
      });
    }
                  //window.open(value,"about:blank");
                }}>
                  Elimina
                </Button>
              ) : ""}
            </>

          )
        }
      }
    });
    columns.push(
    {
      name: "ID",
      options: {
        display: false,
      }
    });

    columns.push(
      {
        name: "can_delete",
        options: {
          display: false,
        }
      });

  const fixPageControls = () => {
    const totPages = Math.ceil(count/rowsPerPage);
    if (page+1 >= totPages) {
    jQuery("#pagination-next").attr("disabled", true).css("pointer-events","none");
    }else{
      jQuery("#pagination-next").attr("disabled", false).css("pointer-events","all");
    }
    jQuery(".MuiTablePagination-displayedRows").html(page+1 + " di " + totPages);
  }



  //console.log(props.q.question_answers);
  return (
    <div style={{ height: 'auto', width: '100%' }}>

      <h4>Lista documenti</h4>
      {docs && docs.length > 0 ? (
        <>
          <h6>Fai click sulle azioni per consultare il dettaglio {props.isAdmin? "o eliminare il documento" : ""}</h6>
          {
            //<div style={{ margin: '0 auto', height: 300, minWidth: 430, maxWidth:430 }}>
          }
          <div style={{ width: '100%' }}>
            <MUIDataTable
              //onRowClick={(r)=>{window.location.hash="docs/"+r.row.id}}
              data={docs}
              columns={columns}
              options={{
                search: false,
                fixedHeader: false,
                selectableRows: false,
                selectableRowsOnClick: true,
                displayRowCheckbox: false,
                onRowClick: (r) => { alert(r); },
                customToolbarSelect: () => { },
                responsive: "simple",
                serverSide: true,
                rowsPerPageOptions: [1,2,10, 20, 50, 100],
                count: {count},
                onTableChange: (action, tableState) => {
                  console.log(action,tableState);
                  if (action === "changePage" || action === "changeRowsPerPage") {
                    apiCall(tableState);
                  }
                  if (action === "propsUpdate") {
                    fixPageControls();
                  }
                },
                onTableInit: (action, tableState) => {
                  console.log(action,tableState);
                  fixPageControls();
                }
              }}
            />
          </div>
        </>
      ) : <div>{docNotFoundLabel}

      </div>
      }
      {props.isAdmin ? (
        <Fab sx={{ ...fabStyle, ...fabGreenStyle }}
          className="JDK-React-App-Fab"
          onClick={() => { navigateToAddDocument() }}
          aria-label="add">
          <AddIcon />
        </Fab>
      ) : ""}
      {
        //questionaries.map((o, i) => { return JSON.stringify(o) })
      }
    </div>
  );
}

export default DocsList;