import axios from "axios";
import {
  AJAX_ENDPOINT,
  FAKE_USER_ID,
  getHeaders,
  reclone,
} from "../commons/Constants";

export class QuestionOptions {
  static async get(id) {
    const HEADERS = getHeaders();
    const resp = await axios.get(
      AJAX_ENDPOINT + "inet-jdk/question-option/get-options/" + id,
      {
        headers: HEADERS,
      }
    ); // 10 -12
    if (resp.data.sesso === "M") {
      resp.data.sesso = "Maschile";
    }
    return resp.data;
  }

  static async put(questionOpt) {
    const HEADERS = getHeaders();
    questionOpt = reclone(questionOpt);
    let resp;
    if (!questionOpt.id ) {
      delete questionOpt.id;
      resp = await axios.post(
        AJAX_ENDPOINT + "inet-jdk/question-option/create",
        questionOpt,
        {
          headers: HEADERS,
        }
      );
    } else {
      resp = await axios.put(
        AJAX_ENDPOINT + "inet-jdk/question-option/update/" + questionOpt.id,
        questionOpt,
        {
          headers: HEADERS,
        }
      );
    }
    return resp;
  }

  static async delete(id) {
    const HEADERS = getHeaders();
    const resp = await axios.delete(
      AJAX_ENDPOINT + "inet-jdk/question-option/deleteById/" + id,
      {
        headers: HEADERS,
      }
    );
    return resp;
  }

  static async deleteByListId(id) {
    const HEADERS = getHeaders();
    const resp = await axios.delete(
      AJAX_ENDPOINT + "inet-jdk/question-option/deleteByListId",
      {
        params: { ids: id },
        headers: HEADERS,
      }
    );
    return resp;
  }
}
