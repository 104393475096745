import React, { useEffect, useState } from 'react';
import jQuery from "jquery";
import { DataGrid } from '@mui/x-data-grid';
import {AJAX_ENDPOINT} from "../commons/Constants";

function CVLog(props) {
  const [cvs, setCVs] = useState([]);
  const [pageSize, setPageSize] = React.useState(5);
  const [cvsNotFoundLabel, setCvsNotFoundLabel] = useState("Caricando...");


  const apiCall = () => {
    jQuery
      .ajax({
        url: AJAX_ENDPOINT,
        data: {
          action: "jdk_inet_get_cvlog",
        },
        dataType: "json",
      })
      .done(function (response) {
         setCVs(response);
         if (response.length === 0) {
          setCvsNotFoundLabel("Nessun CV trovato");
        }
         console.log(response);
      }).fail(function (){
         setCVs(null);
         setCvsNotFoundLabel("Nessun CV trovato, errore AJAX");
      });
  };
  
   useEffect(() =>{apiCall();},[props]);



   const columns = [
  //  { field: 'id', headerName: 'ID', width: 70 },
    { field: 'date_taken', headerName: 'Data', width: 170 },
    { field: 'display_name', headerName: 'Utente', width: 170 }
  ];
  //console.log(props.q.question_answers);
  return (
   
    <div style={{  height: 'auto', width: '90%' }}>
      <h4>Lista curriculum scaricabili</h4>
      {  cvs && cvs.length > 0 ? (
      <>
      <h6>Fai click su una riga per scaricare</h6>
      <div style={{ margin: '0 auto', height: 300, width: 430 }}>
        <DataGrid
        onRowClick={(r)=>{
        //  console.log(r); 
          window.location=r.row.cv_url;
        }}
        rows={cvs}
        columns={columns}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        pagination
        rowsPerPageOptions={[5, 10, 50, { value: -1, label: '*' }]}
      />
      </div>
      </>
          ) : (
            <div>{cvsNotFoundLabel}</div>
          )}
    {
      //questionaries.map((o, i) => { return JSON.stringify(o) })
    }
    </div>
  );
}

  export default CVLog;