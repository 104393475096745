import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import jQuery from "jquery";
import { clone, debounce } from "lodash";
import Moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { AJAX_ENDPOINT, newProject, reclone } from "../commons/Constants";
import CVUpdateEspForm from "./CVUpdateEspForm";
import { useGlobalState } from "../store/state";
import { Button } from "@mui/material";
import { EspLav } from "../datasource/EspLav";

function stripHtml(html) {
  let tmp = document.createElement("DIV");
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || "";
}

function truncate(str, n, useWordBoundary) {
  if (str.length <= n) {
    return str;
  }
  const subString = str.substr(0, n - 1); // the original check
  return (
    (useWordBoundary
      ? subString.substr(0, subString.lastIndexOf(" "))
      : subString) + "..."
  );
}

function CVEsperienzeLavorative(props) {
  // const [count, setCount] = React.useState(0);
  const [esp, setEsp] = useState([]);
  const [lockSorting, setLockSorting] = useState(false);
  const [touched, setTouched] = useState(false);
  const state = useGlobalState();

  const newEsp = () => {
    // debugger;
    esp.push({
      id: Math.random(),
      wpUserId: state.user()?.wpUserId,
      dummy: true,
      mansioni: "Clicca l'accordion arrow per compilare",
      progettiJson: [],
    });
    refreshState();
  };

  const deleteItem = async (i) => {
    const splitId = esp.splice(i, 1);
    setTouched(true);
    await EspLav.delete(splitId[0].id);
    refreshState();
  };

  const sortUp = (i) => {
    if (i > 0 && !lockSorting) {
      let tmp = esp[i - 1];
      esp[i - 1] = esp[i];
      esp[i] = tmp;
      setTouched(true);
      refreshState();
    }
  };

  const sortDown = (i) => {
    if (i < esp.length - 1 && !lockSorting) {
      let tmp = esp[i + 1];
      esp[i + 1] = esp[i];
      esp[i] = tmp;
      setTouched(true);
      refreshState();
    }
  };

  const refreshState = () => {
    setEsp(clone(esp));
    //setLockSorting(false);
  };

  const _refreshState = () => {
    setEsp(clone(esp));
    setLockSorting(false);
  };

  const refreshStateDebounced = useCallback(debounce(refreshState, 30), [esp]);

  const refreshStateDebouncedLarge = useCallback(
    debounce(_refreshState, 15 * 1000),
    [esp]
  );

  const _refreshStateDebounced = () => {
    setEsp(clone(esp));
  };

  const apiCall = async () => {
    await state.getEspLav();
    let esp = state.espLav();
    esp = reclone(esp);

    /*      esp.forEach(o=>{
      o.progetti = o.progettiJson;
      if (o.progetti ===null){
        o.progetti = [newProject(o)];
      }
    }); */
    setEsp(esp);
  };

  const isTouched = () => touched || esp.findIndex((o) => o.touched) !== -1;

  const apiCallSave = async () => {
    try {
      const modifiedEspLav = esp.filter((o) => o.touched === true);

      const mapEspLav = modifiedEspLav.map((e) => {
        const progettiJsonS = JSON.stringify(e.progettiJson);
        return { ...e, progettiJson: progettiJsonS };
      });

      let promises = [];
      for (let i = 0; i < mapEspLav.length; i++) {
        mapEspLav[i].touched = false;
        promises.push(EspLav.put(mapEspLav[i]));
      }
      await Promise.all(promises);
      state.getEspLav();
      props.onMessage("Salvataggio effettuato", "success");
    } catch (e) {
      console.error(e);
      props.onCardSkimPrev(document.querySelector("#skimprev-esplav"));
      props.onMessage("Errore nel salvataggio", "error");
    }
  };

  useEffect(() => {
    // console.log(props);
    apiCall();
  }, []);

  return (
    <div className="JDKReactApp-header visible">
      <h3>Controlla le tue esperienze lavorative</h3>
      {esp.length > 0 ? (
        <section className="formcontainer customscrollbar">
          {esp.map((o, i) => {
            const key = o.id; //JSON.stringify(o);//Math.random()+""+Date.now();
            //console.log('key',key);
            o.mansioniPlainText = "";
            o?.progettiJson.forEach((p) => {
              o.mansioniPlainText += /*p.ruolo+"@"+*/ p.progetto + " ";
            });

            //console.log('mansioni',o.mansioni);
            o.mansioniPlainText = truncate(o.mansioniPlainText, 37, true)
              .replaceAll("\n-", " , ")
              .replaceAll("\n -", " , ");
            // console.log('mansioniPlainText',o.mansioniPlainText);

            return (
              <Accordion key={i}>
                <AccordionSummary
                  onClick={(e) => {
                    refreshState();
                  }}
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <div className="accordion-header-jdk">
                    <div className="accordion-header-item">
                      <div className="aheader">#</div>
                      {i + 1}
                    </div>
                    <div className="accordion-header-item">
                      <div className="aheader">Datore di lavoro</div>
                      {o.datoreLavoro}
                      <div>
                        {" "}
                        <em className="date_range">
                          {Moment(o.dataInizio, "YYYY-MM-DD").isValid()
                            ? Moment(o.dataInizio, "YYYY-MM-DD").format(
                                "MM/YYYY"
                              )
                            : "Conferma data inizio"}
                          -{" "}
                          {o.lavoroQui === "1" ||
                          o.lavoroQui === 1 ||
                          o.lavoroQui === true ||
                          !o.dataFine
                            ? "attuale"
                            : Moment(o.dataFine, "YYYY-MM-DD").isValid()
                            ? Moment(o.dataFine, "YYYY-MM-DD").format("MM/YYYY")
                            : "Conferma data fine"}
                        </em>{" "}
                      </div>
                    </div>
                    <div className="accordion-header-item">
                      <div className="aheader">Città</div>
                      {o.citta}
                    </div>
                    <div className="accordion-header-item">
                      <div className="aheader">Qualifica</div>
                      {o.qualifica}
                    </div>
                    <div className="accordion-header-item mansioni">
                      <div className="aheader">Progetti</div>
                      {o.mansioniPlainText}
                    </div>
                    <div className="accordion-header-item mansioni">
                      <div className="aheader">Azioni</div>

                      <span
                        className="accordion-action"
                        title="Sposta su"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          sortUp(i);
                        }}
                      >
                        <ArrowDropUpIcon />
                      </span>
                      <span
                        className="accordion-action"
                        title="Sposta giù"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          sortDown(i);
                        }}
                      >
                        <ArrowDropDownIcon />
                      </span>
                      <span
                        className="accordion-action"
                        title="Elimina definitiviamente"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          deleteItem(i);
                        }}
                      >
                        <DeleteForeverIcon />
                      </span>
                    </div>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <CVUpdateEspForm
                    onProgettiChange={(id, value, refresh, idToScroll) => {
                      const idx = esp.findIndex((v) => v.id === id);
                      esp[idx].progettiJson = value;
                      // console.log(esp[idx].progetti, value);
                      esp[idx].touched = true;
                      if (refresh) {
                        refreshState();
                        //console.log('refresh');
                        if (idToScroll) {
                          setTimeout(() => {
                            //console.log('scroll to #'+idToScroll);
                            try {
                              document
                                .getElementById(idToScroll)
                                .scrollIntoView();
                            } catch (idNotRedndered) {
                              console.log("not rendered id #" + idToScroll);
                            }
                          }, 500);
                        }
                      } else {
                        refreshStateDebouncedLarge(); //to update 'sava e vai avanti' every 15 secs after change
                      }
                    }}
                    onMansioniChange={(id, value, plainText) => {
                      const idx = esp.findIndex((v) => v.id === id);
                      if (esp[idx].mansioni_touched && plainText === "") {
                        return;
                      }
                      // console.log(idx,value,plainText);
                      esp[idx].mansioni = value;
                      esp[idx].touched = true;
                      esp[idx].mansioni_touched = true;
                      esp[idx].mansioniPlainText = plainText;
                      esp[idx].mansioniPlainText = truncate(plainText, 37, true)
                        .replaceAll("\n-", " , ")
                        .replaceAll("\n -", " , ");

                      //setLockSorting(true);
                      refreshState();
                    }}
                    onLavoroQuiChange={(id, value) => {
                      const idx = esp.findIndex((v) => v.id === id);
                      esp[idx].lavoroQui = value;
                      esp[idx].touched = true;
                      refreshState();
                    }}
                    onDataFineChange={(id, value) => {
                      const idx = esp.findIndex((v) => v.id === id);
                      esp[idx].dataFine = Moment(value).format("YYYY-MM-DD");
                      esp[idx].touched = true;
                      refreshState();
                    }}
                    onDataInizioChange={(id, value) => {
                      const idx = esp.findIndex((v) => v.id === id);
                      esp[idx].dataInizio = Moment(value).format("YYYY-MM-DD");
                      esp[idx].touched = true;
                      refreshState();
                    }}
                    onDatoreChange={(id, value) => {
                      const idx = esp.findIndex((v) => v.id === id);
                      esp[idx].datoreLavoro = value;
                      esp[idx].touched = true;
                      refreshState();
                    }}
                    onQualificaChange={(id, value) => {
                      const idx = esp.findIndex((v) => v.id === id);
                      esp[idx].qualifica = value;
                      esp[idx].touched = true;
                      refreshState();
                    }}
                    onCittaChange={(id, value) => {
                      const idx = esp.findIndex((v) => v.id === id);
                      esp[idx].citta = value;
                      esp[idx].touched = true;
                      refreshState();
                    }}
                    o={o}
                    key={key}
                  ></CVUpdateEspForm>
                </AccordionDetails>
              </Accordion>
            );
          })}
        </section>
      ) : (
        <div>Nessuna esperienza lavorativa aggiunta ...</div>
      )}
      <nav>
        <Button
          variant="outlined"
          color="primary"
          onClick={(e) => {
            e.preventDefault();
            props.onCardSkimPrev(e.target);
          }}
        >
          Indietro
        </Button>{" "}
        &nbsp;
        <Button
          variant="outlined"
          color="primary"
          className="btt-add"
          onClick={(e) => {
            e.preventDefault();
            newEsp();
          }}
        >
          Aggiungi esperienza
        </Button>{" "}
        &nbsp;
        <Button
          variant="outlined"
          color="primary"
          onClick={(e) => {
            e.preventDefault();
            props.onCardSkim(e.target);
            apiCallSave();
          }}
        >
          {isTouched() ? "Salva e vai avanti" : "Avanti"}
        </Button>
      </nav>
    </div>
  );
}

export default CVEsperienzeLavorative;
