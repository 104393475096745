import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import {
  Checkbox,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import Box from "@mui/material/Box";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { QuestionOptions } from "../../datasource/QuestionOptions";
import { useGlobalState } from "../../store/state";
import Step from "./StepComponents";

function CardQuestion(props) {
  const state = useGlobalState();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const [options, setOptions] = useState([]);
  const [currentStep, setCurrentStep] = useState(0);
  const initialValues = {
    questiontext: "",
    questionType: "",
    questionAnswers: "",
    questionGroup: "",
    orderNum: 0,
    questionaryId: id,
    questionEvents: null,
  };
  const [formData, setFormData] = useState([initialValues]);

  const goToNextStep = () => {
    if (currentStep + 1 >= formData.length) {
      setFormData((prev) => [...prev, initialValues]);
    }
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const goToBackStep = () => {
    const newStepIndex = Math.max(currentStep - 1, 0);
    setCurrentStep(newStepIndex);
    /* setCurrentStep((prevStep) => prevStep - 1); */
  };

  const handleFieldChange = (index, field, value) => {
    // Funzione per gestire i cambiamenti nei campi del modulo
    setFormData((prevData) =>
      prevData.map((item, i) =>
        i === index ? { ...item, [field]: value } : item
      )
    );
  };

  const handleStepChange = (index, fieldName, value) => {
    console.log(formData);
    handleFieldChange(index, fieldName, value);
    // Puoi fare altre azioni in base al cambio di passo, se necessario
  };

  const apiCall = async () => {
    console.log("id : ", id);
    await state.getQuestionOpt(id);
    let questions = state.questionOpt();
    if (typeof questions === "string") {
      questions = JSON.parse(questions);
    }

    setOptions(questions);

    console.log("la lista domande del questionario sono: ", questions);
  };

  useEffect(() => {
    apiCall();
  }, [id]);

  //const {questions} = props
  //ho creato un paio di domande finchè non torna disponibile il db
  /*const questions = [
    {
      id: 107,
      questiontext: "Quali?",
      questionType: "multiiple",
      questionAnswers: [
        {
          "team leader": "team leader",
          "capo progetto": "capo progetto",
          "trainer": "trainer",
          "altro": "altro"
        }
      ],
      questionGroup: "prova",
      orderNum: 3,
      questionaryId: 16,
      questionEvents: {
        "event1": "description1",
        "event2": "description2"
      }
    },
    {
      id: 108,
      questiontext: "Cosa ti piace dell'ambiente di lavoro in cui ti trovi?",
      questionType: "open",
      questionAnswers: null,
      questionGroup: "prova",
      orderNum: 2,
      questionaryId: 16,
      questionEvents: {
        "event1": "description1",
        "event2": "description2"
      }
    }
  ]*/

  const finishQuestionaries = async () => {
    setLoading(true);
    const apiCalls = formData?.map(async (e) => {
      let formattedJsonString;
      if (e?.questionType === "multiple" || e?.questionType === "choice") {
        const values = e?.questionAnswers
          .split(",")
          .map((value) => value.trim());

        // Wrapping the array of strings
        const arrayData = [...values];

        // Convert the array to a JSON string
        formattedJsonString = JSON.stringify(arrayData, null, 2).replace(
          /\n/g,
          ""
        );

        console.log(formattedJsonString);
      }
      try {
        const questionOpt = {
          questiontext: e.questiontext,
          questionAnswers: formattedJsonString ? formattedJsonString : null,
          questionType: e.questionType,
          questionGroup: e.questionGroup,
          orderNum: e.orderNum,
          questionaryId: e.questionaryId,
          questionEvents: e.questionEvents,
        };
        const response = await QuestionOptions.put(questionOpt);
        return response;
      } catch (error) {
        console.error("Error during API call for question", e, error);
        return null; // Handle errors as needed
      }
    });
    const responses = await Promise.all(apiCalls);
    if (responses) {
      setLoading(false);
      navigate("/questionnaire");
    }
    console.log("All API Responses", responses);
  };

  /*   const apiCallSave = async () => {
    console.log("il save");
    try {
      const modifiedQuestion = options?.filter((o) => {
        console.log("Filtered question", o);
        return o.touched === true;
      });

      const apiCalls = modifiedQuestion?.map(async (e) => {
        let formattedJsonString;
        if (e?.questionType === "multiple") {
          const values = e?.questionAnswers
            .split(",")
            .map((value) => value.trim());
          // Create an object with key-value pairs
          const obj = {};
          values.forEach((value) => {
            obj[value] = value;
          });
          // Convert the object to a JSON string
          const jsonString = JSON.stringify(obj, null, 2).replace(/\n/g, "");
          // Add quotes around keys in the resulting string
          formattedJsonString = jsonString.replace(/"([^"]+)":/g, '"$1": ');
        }
        try {
          const questionOpt = {
            id: e.id,
            questiontext: e.questiontext,
            questionAnswers: formattedJsonString
              ? formattedJsonString
              : e.questionAnswers,
            questionType: e.questionType,
            questionGroup: e.questionGroup,
            orderNum: e.orderNum,
            questionaryId: e.questionaryId,
            questionEvents:
              '{"event1": "description1", "event2": "description2"}',
          };
          const response = await QuestionOptions.put(questionOpt);
          return response;
        } catch (error) {
          console.error("Error during API call for question", e, error);
          return null; // Handle errors as needed
        }
      });
      const responses = await Promise.all(apiCalls);
      console.log("All API Responses", responses);

      state.getQuestionOpt();
    } catch (error) {
      console.error("Error during API calls", error);
      setApiError(true);
    }
  };

  const deleteQuestion = async (id) => {
    await QuestionOptions.delete(id);
    navigate("/questionnaire");
  }; */

  return (
    <div>
      <h2>Crea Questionario</h2>
      <section className="formcontainer customscrollbar">
        <Box
        /*        component="form"
          sx={{
            "& .MuiTextField-root": { m: 1, width: "25ch" },
          }}
          autoComplete="off" */
        >
          {/* {options && options.length > 0 ? (
            <section
              className="formcontainer customscrollbar"
              style={{ height: "100%", width: "100%" }}
            >
              <h4>Elenco domande</h4>
              {options?.map((o, i) => {
                const key = o.id;
                console.log("opss", o);
                return (
                  <Grid>
                    <h4>{`Domana: ${o.id}`}</h4>
                    
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={(e) => {
                        e.preventDefault();
                        deleteQuestion(o.id);
                      }}
                    >
                      Elimina domanda
                    </Button>
                    <TextField
                      key={o.id}
                      onChange={(e) => {
                        o.questiontext = e.target.value;
                        o.touched = true;
                      }}
                      name="questiontext"
                      label="questiontext"
                      defaultValue={o.questiontext}
                    />
                    {o.questionType === "multiiple" ? (
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Risposta
                        </InputLabel>
                        {o.questionAnswers?.map((e) => {
                          const names = Object.keys(e);
                          // Convert to JSON string
                          const jsonString = JSON.stringify(
                            names,
                            null,
                            2
                          ).replace(/[\[\]"']/g, "");
                          return (
                            <TextField
                              key={encodeURIComponent}
                              onChange={(e) => {
                                o.questionAnswers = e.target.value;
                                o.touched = true;
                              }}
                              name="questionAnswers"
                              label="questionAnswers"
                              defaultValue={jsonString}
                            />
                          );
                        })}
                      </FormControl>
                    ) : (
                      <TextField
                        key={o.id}
                        onChange={(e) => {
                          o.questionAnswers = e.target.value;
                          o.touched = true;
                        }}
                        name="questionAnswers"
                        label="questionAnswers"
                        defaultValue={o.questionAnswers}
                      />
                    )}

                    <TextField
                      key={o.id}
                      onChange={(e) => {
                        o.questionType = e.target.value;
                        o.touched = true;
                      }}
                      name="questionType"
                      label="questionType"
                      defaultValue={o.questionType}
                    />
                    <TextField
                      key={o.id}
                      onChange={(e) => {
                        o.questionGroup = e.target.value;
                        o.touched = true;
                      }}
                      name="questionGroup"
                      label="questionGroup"
                      defaultValue={o.questionGroup}
                    />
                    <TextField
                      key={o.id}
                      onChange={(e) => {
                        o.orderNum = e.target.value;
                        o.touched = true;
                      }}
                      name="orderNum"
                      label="orderNum"
                      defaultValue={o.orderNum}
                    />
                  </Grid>
                );
              })}
              <Button
                variant="outlined"
                color="primary"
                className="btn btn-success"
                onClick={() => {
                  window.location.reload();
                  navigate("/questionnaire");
                }}
              >
                Indietro
              </Button>
              &nbsp;
              <Button
                variant="outlined"
                color="primary"
                onClick={(e) => {
                  e.preventDefault();
                  apiCallSave();
                }}
              >
                Salva
              </Button>
            </section>
          ) : ( */}
          <div>
            <div style={{ marginBottom: "100px" }}>
              <Step
                length={formData.length}
                stepIndex={currentStep}
                formData={formData[currentStep]}
                handleFieldChange={(index, field, value) =>
                  handleStepChange(index, field, value)
                }
              />
              <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                <Button
                  color="primary"
                  variant="outlined"
                  disabled={currentStep === 0}
                  onClick={goToBackStep}
                >
                  Indietro
                </Button>
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={goToNextStep}
                >
                  Avanti
                </Button>
              </div>
              <Button
                disabled={
                  !(currentStep + 1 >= formData.length) || formData.length === 1
                }
                style={{ marginTop: "15px" }}
                className="btn-questionaries questionaries-button"
                onClick={() => finishQuestionaries()}
              >
                {loading ? (
                  <CircularProgress size={28} />
                ) : (
                  "Termina questionario"
                )}
              </Button>
            </div>
            {/* 
                  <Grid>
                    <h4>{`Domanda: ${options.id}`}</h4>
                    
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={(e) => {
                        e.preventDefault();
                        deleteQuestion(options.id)
                      }}
                    >
                      Elimina domanda
                    </Button>
                    <TextField
                      key={options.id}
                      onChange={(e) => {
                        options.questiontext = e.target.value;
                        options.touched = true;
                      }}
                      name="questiontext"
                      label="questiontext"
                      defaultValue={options.questiontext}
                    />
                    {options.questionType === "multiiple"
                      ? (<FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Risposta</InputLabel>
                        {options.questionAnswers?.map((e) => {
                          const names = Object.keys(e);
                          // Convert to JSON string
                          const jsonString = JSON.stringify(names, null, 2).replace(/[\[\]"']/g, '');
                          return (<TextField
                            key={encodeURIComponent}
                            onChange={(e) => {
                              options.questionAnswers = e.target.value;
                              options.touched = true;
                            }}
                            name="questionAnswers"
                            label="questionAnswers"
                            defaultValue={jsonString}
                          />)
                        })}
                      </FormControl>
                      )
                      : (
                        <TextField
                          key={options.id}
                          onChange={(e) => {
                            console.log("target ",e.target.value );
                            options.questionAnswers = e.target.value;
                            options.touched = true;
                          }}
                          name="questionAnswers"
                          label="questionAnswers"
                          defaultValue={options.questionAnswers}
                        />
                      )
                    }

                    <TextField
                      key={options.id}
                      onChange={(e) => {
                        options.questionType = e.target.value;
                        options.touched = true;
                      }}
                      name="questionType"
                      label="questionType"
                      defaultValue={options.questionType}
                    />
                    <TextField
                      key={options.id}
                      onChange={(e) => {
                        options.questionGroup = e.target.value;
                        options.touched = true;
                      }}
                      name="questionGroup"
                      label="questionGroup"
                      defaultValue={options.questionGroup}
                    />
                    <TextField
                      key={options.id}
                      onChange={(e) => {
                        options.orderNum = e.target.value;
                        options.touched = true;
                      }}
                      name="orderNum"
                      label="orderNum"
                      defaultValue={options.orderNum}
                    />
                  </Grid>
              
          */}
          </div>
          {/* )}  */}
        </Box>
      </section>
    </div>
  );
}

export default CardQuestion;
