import React from "react";
import { BrowserRouter, useNavigate } from "react-router-dom";
import "./App.scss";
import { useGlobalState } from "./store/state";
import MainLayout from "./MainLayout";

const App = (props) => {
  const state = useGlobalState();
  const userId = parseInt(localStorage.getItem("userId"), 10);
  const token = localStorage.getItem("auth_token");

  React.useEffect(() => {
    console.log(userId);
    if (userId && token) {
      state.setUserId(userId);
      state.setToken(token);
      state.setAuthenticated(true);
    }
  }, []);

  return (
    <BrowserRouter>
      <MainLayout></MainLayout>
    </BrowserRouter>
  );
};

export default App;
