import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import TextField from "@mui/material/TextField";
import jQuery from "jquery";
import { clone, debounce } from 'lodash';
import Moment from 'moment';
import React, { useCallback, useEffect, useState } from "react";
import { AJAX_ENDPOINT, reclone } from "../commons/Constants";
import CVUpdateLingueForm from './CVUpdateLingueForm';
import Box from "@mui/material/Box";
import { Button, Grid } from '@mui/material';
import { useGlobalState } from '../store/state';
import { CVLingueData } from '../datasource/CVLingue';



function stripHtml(html) {
  let tmp = document.createElement("DIV");
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || "";
}

function truncate( str, n, useWordBoundary ){
  if (str.length <= n) { return str; }
  const subString = str.substr(0, n-1); // the original check
  return (useWordBoundary 
    ? subString.substr(0, subString.lastIndexOf(" ")) 
    : subString) + "...";
};

function CVLingue(props) {
  // const [count, setCount] = React.useState(0);
  const [esp, setEsp] = useState([]);
  const [touched, setTouched] = useState(false);

  const state = useGlobalState();

  const [lockSorting, setLockSorting] = useState(false);

  const newEsp = () => {
    esp.push({
      wpUserId: state.user()?.wpUserId,
      id: Math.random(),
      dummy: true,
      descrizione: ""
    });
    refreshState();
  };



  const deleteItem = async(i) => {
      if (window.confirm('Vuoi eliminare l\'elemento selezionato?')){
      const splitId = esp.splice(i, 1);
      setTouched(true);
      await CVLingueData.delete(splitId[0].id);
      refreshState();   
    }
  };


  const sortUp = (i) => {
    if (i>0 && !lockSorting){
      let tmp = esp[i-1];
      esp[i-1]=esp[i];
      esp[i] = tmp;
      setTouched(true);
      refreshState();
    }
  };

  const sortDown = (i) => {
    if (i<esp.length-1 && !lockSorting){
      let tmp = esp[i+1];
      esp[i+1]=esp[i];
      esp[i] = tmp;
      setTouched(true);
      refreshState();
    }
  }


  const refreshState = () => {
    setEsp(clone(esp));
    //setLockSorting(false);
  }

  const _refreshState = () => {
    setEsp(clone(esp));
    setLockSorting(false);
  }

  const refreshStateDebounced = useCallback(
    debounce(refreshState, 30)
  , [esp]);

  const refreshStateDebouncedLarge = useCallback(
    debounce(_refreshState, 5000)
  , [esp]);


  const _refreshStateDebounced = () => {
    setEsp(clone(esp));
  }
 
  const apiCall = async () => {    //  setCount(2);
    await state.getLingue();
    let esp = state.lingue();
    esp = reclone(esp)
    setEsp(esp);
   };

 
  const apiCallSave = async () => {
    try{
      const modifiedLing = esp.filter((o)=> o.touched===true);
      let promises = [];
      for (let i = 0; i < modifiedLing.length; i++) {
        modifiedLing[i].touched = false;
        promises.push(CVLingueData.put(modifiedLing[i]));
      }
      await Promise.all(promises);
      state.getLingue();
      props.onMessage("Salvataggio effettuato", "success");

    }catch(e){
      console.error(e);
      props.onCardSkimPrev(document.querySelector("#skimprev-esplav"));
      props.onMessage("Errore nel salvataggio", "error");
    }
  };

  useEffect(() => {
    apiCall();
  }, []);


  return (
    <div className="JDKReactApp-header visible" >
      <h4>Controlla le tue informazioni sulle lingue conosciute</h4>
      {esp.length > 0 ? (
        <section className="formcontainer customscrollbar" style={{height: '100%', width: '100%'}}>
          <h4>Altre lingue:</h4>
          {esp.map((o, i) => {
            const key = o.id;
            return (
              <Accordion className="lingue-accordion" key={i}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <div className="accordion-header-jdk">
                    <div className="accordion-header-item">
                      <div className="aheader">#</div>
                      {i + 1}
                    </div>
                    <div className="accordion-header-item">
                      <div className="aheader">Lingua</div>
                      {o.lingua}
                    </div>
                    <div className="accordion-header-item descrizione">
                      <div className="aheader">Azioni</div>
                      <span className="accordion-action" title="Sposta su" onClick={(e) => { e.preventDefault(); e.stopPropagation(); sortUp(i) }}><ArrowDropUpIcon /></span>
                      <span className="accordion-action" title="Sposta giù" onClick={(e) => { e.preventDefault(); e.stopPropagation(); sortDown(i) }}><ArrowDropDownIcon /></span>
                      <span className="accordion-action" title="Elimina definitiviamente" onClick={(e) => { e.preventDefault(); e.stopPropagation(); deleteItem(i) }}><DeleteForeverIcon /></span>
                    </div>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <CVUpdateLingueForm
                    onCertificazioneChange={(id, value, plainText) => {
                      const idx = esp.findIndex((v) => v.id === id);
                      // console.log(idx,value,plainText);
                      esp[idx].certificazione = value;
                      esp[idx].touched = true;
                      //setLockSorting(true);
                      refreshState();
                    }}
                    onAscoltoChange={(id, value) => {
                      const idx = esp.findIndex((v) => v.id === id);
                      esp[idx].ascoltoLiv = value;
                      esp[idx].touched = true;
                      refreshState();
                    }}
                    onLetturaChange={(id, value) => {
                      const idx = esp.findIndex((v) => v.id === id);
                      esp[idx].letturaLiv = value;
                      esp[idx].touched = true;
                      refreshState();
                    }}
                    onInterazioneChange={(id, value) => {
                      const idx = esp.findIndex((v) => v.id === id);
                      esp[idx].interazioneLiv = value;
                      esp[idx].touched = true;
                      refreshState();
                    }}
                    onProdOraleChange={(id, value) => {
                      const idx = esp.findIndex((v) => v.id === id);
                      esp[idx].prodOraleLiv = value;
                      esp[idx].touched = true;
                      refreshState();
                    }}
                    onProdScrittaChange={(id, value) => {
                      const idx = esp.findIndex((v) => v.id === id);
                      esp[idx].prodScrittaLiv = value;
                      esp[idx].touched = true;
                      refreshState();
                    }}
                    onLinguaChange={(id, value) => {
                      const idx = esp.findIndex((v) => v.id === id);
                      esp[idx].lingua = value;
                      esp[idx].touched = true;
                      refreshState();
                    }}
                    o={o}
                    key={key}
                  ></CVUpdateLingueForm>
                </AccordionDetails>
              </Accordion>
            );
          })}
        </section>) :
        <div>Nessuna lingua  aggiunta ...</div>
      }
      <nav>
        <Button
          variant="outlined"
          color="primary"
          onClick={(e) => {
            e.preventDefault();
            props.onCardSkimPrev(e.target);
          }}
        >
          Indietro
        </Button>{" "}
        &nbsp;
        <Button
          variant="outlined"
          color="primary"
          className="btn btn-success"
          onClick={(e) => {
            e.preventDefault();
            newEsp();
          }}
        >
          Aggiungi lingua
        </Button>{" "}
        &nbsp;
        <Button
          variant="outlined"
          color="primary"
          onClick={(e) => {
            e.preventDefault();
            props.onCardSkim(e.target);
            apiCallSave();
          }}
        >
          Avanti
        </Button>
      </nav>
    </div>
  )
}


export default CVLingue;
