import axios from "axios";
import {
  AJAX_ENDPOINT,
  FAKE_USER_ID,
  getHeaders,
  reclone,
} from "../commons/Constants";
import { clone } from "lodash";

export class CVCompetenzeDigitali {
  static async get(id) {
    const HEADERS = getHeaders();
    const resp = await axios.get(
      AJAX_ENDPOINT + "inet-jdk/competenza/get-competenza/" + id,
      {
        headers: HEADERS,
      }
    );
    if (resp.data.sesso === "M") {
      resp.data.sesso = "Maschile";
    }
    return resp.data;
  }

  static async put(competenza) {
    const HEADERS = getHeaders();
    competenza = reclone(competenza);

    let resp;
    if (competenza.id < 1) {
      delete competenza.id;
      resp = await axios.post(
        AJAX_ENDPOINT + "inet-jdk/competenza/create",
        competenza,
        {
          headers: HEADERS,
        }
      );
    } else {
      resp = await axios.put(
        AJAX_ENDPOINT + "inet-jdk/competenza/update/" + competenza.id,
        competenza,
        {
          headers: HEADERS,
        }
      );
    }
    return resp;
  }

  static async delete(id) {
    const HEADERS = getHeaders();
    const resp = await axios.delete(
      AJAX_ENDPOINT + "inet-jdk/competenza/deleteById/" + id,
      {
        headers: HEADERS,
      }
    );
    return resp;
  }
}
