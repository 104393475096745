export const AJAX_ENDPOINT = "https://jdk-intranet-be.rack2.jdk.it/";
//export const AJAX_ENDPOINT = "https://www2.jdk.it/wp-admin/admin-ajax.php?devkey=skdv35_jnweHOccuNusaaybeubiBBBsvbBKdsJKKAX@J";
export const newProject = (o)=>{
   // console.log('o',o);
    return {
      progetto: '',
      data_inizio: o.data_inizio,
      data_fine: o.data_fine,
      lavoro_qui: o.lavoro_qui,
      cliente: '',
      scopo: '',
      tecnologie: '',
      ruolo: ''
    };
};

/* const token = localStorage.getItem('auth_token')

export const HEADERS = {
  'Content-Type': 'application/json', 
  'Authorization': `Bearer ${token}`, 
}

console.log("token:", localStorage.getItem('auth_token')); */

export const getHeaders = () => {
  const token = localStorage.getItem('auth_token');

  return {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${token}`,
  };
};

export const reclone = (obj) => JSON.parse(JSON.stringify(obj));


export const FAKE_USER_ID = 120;

export const ROLE_ADMIN = "ADMIN";
export const ROLE_USER = "USER";
export const ROLE_SUPER_ADMIN = "SUPER_ADMIN";