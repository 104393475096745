import Box from "@mui/material/Box";
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import ListSubheader from '@mui/material/ListSubheader';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from "@mui/material/TextField";
import React, { useEffect, useState } from "react";

//import { registerLocale } from 'react-datepicker';
//registerLocale('it', it);

function CVUpdateLingueForm(props) {
  // const [count, setCount] = React.useState(0);
  const [lingua, setLingua] = useState(null);

  const [ascolto_liv, setAscoltoLiv] = useState(null);
  const [lettura_liv, setLetturaLiv] = useState(null);
  const [interazione_liv, setInterazioneLiv] = useState(null);
  const [prod_orale_liv, setProdOraleLiv] = useState(null);
  const [prod_scritta_liv, setProdScrittaLiv] = useState(null);
  const [certificazione, setCertificazione] = useState(null);



/*
  const [childKey, setChildKey] = useState(0);
  
  useEffect(() => {
    setChildKey(prev => prev + 1);
  },[props.key]); */

  useEffect(() => {
    setLingua(props.o.lingua);
   //console.log('useEffect',props.o);
    setAscoltoLiv(props.o.ascoltoLiv);
    setLetturaLiv(props.o.letturaLiv);
    setInterazioneLiv(props.o.interazioneLiv);
    setProdOraleLiv(props.o.prodOraleLiv);
    setProdScrittaLiv(props.o.prodScrittaLiv);
    setCertificazione(props.o.certificazione);
   
  }, [props]);

  return (
    <Box
      sx={{
        "& .MuiTextField-root": { m: 1, width: "25ch" },
      }}
      
      autoComplete="off"
    >
     
      {lingua!==null ?
      <TextField
        required
        onChange={(e)=>props.onLinguaChange(props.o.id, e.target.value)}
        name="lingua"
        label="Lingua"
        placeholder="Inglese"
        defaultValue={lingua}
      />
:<div></div>}

<br/>
<h5>Comprensione:</h5>
{ascolto_liv!==null ?
  <FormControl sx={{ m: 1, minWidth: 190 }}>
        <InputLabel htmlFor="grouped-select">Livello ascolto</InputLabel>
        <Select
          onChange={(e)=>props.onAscoltoChange(props.o.id, e.target.value)}
        defaultValue={ascolto_liv} id="grouped-select" label="Livello ascolto">
          <ListSubheader>Utente base</ListSubheader>
          <MenuItem value={'A1'}>A1</MenuItem>
          <MenuItem value={'A2'}>A2</MenuItem>
          <ListSubheader>Utente intermedio</ListSubheader>
          <MenuItem value={'B1'}>B1</MenuItem>
          <MenuItem value={'B2'}>B2</MenuItem>
          <ListSubheader>Utente avanzato</ListSubheader>
          <MenuItem value={'C1'}>C1</MenuItem>
          <MenuItem value={'C2'}>C2</MenuItem>
        </Select>
      </FormControl>: <div></div> }
     
      {lettura_liv!==null ?

      <FormControl sx={{ m: 1, minWidth: 190 }}>
        <InputLabel htmlFor="grouped-select2">Livello lettura</InputLabel>
        <Select 
          onChange={(e)=>props.onLetturaChange(props.o.id, e.target.value)}
        defaultValue={lettura_liv} id="grouped-select2" label="Livello lettura">
          <ListSubheader>Utente base</ListSubheader>
          <MenuItem value={'A1'}>A1</MenuItem>
          <MenuItem value={'A2'}>A2</MenuItem>
          <ListSubheader>Utente intermedio</ListSubheader>
          <MenuItem value={'B1'}>B1</MenuItem>
          <MenuItem value={'B2'}>B2</MenuItem>
          <ListSubheader>Utente avanzato</ListSubheader>
          <MenuItem value={'C1'}>C1</MenuItem>
          <MenuItem value={'C2'}>C2</MenuItem>
        </Select>
      </FormControl> : <div></div> }
      <br/>
<h5>Parlato:</h5>
{interazione_liv!==null ?

      <FormControl sx={{ m: 1, minWidth: 190 }}>
        <InputLabel htmlFor="grouped-select3">Livello interazione orale</InputLabel>
        <Select 
          onChange={(e)=>props.onInterazioneChange(props.o.id, e.target.value)}
        defaultValue={interazione_liv} id="grouped-select3" label="Livello interazione orale">
          <ListSubheader>Utente base</ListSubheader>
          <MenuItem value={'A1'}>A1</MenuItem>
          <MenuItem value={'A2'}>A2</MenuItem>
          <ListSubheader>Utente intermedio</ListSubheader>
          <MenuItem value={'B1'}>B1</MenuItem>
          <MenuItem value={'B2'}>B2</MenuItem>
          <ListSubheader>Utente avanzato</ListSubheader>
          <MenuItem value={'C1'}>C1</MenuItem>
          <MenuItem value={'C2'}>C2</MenuItem>
        </Select>
      </FormControl> : <div></div> }

      {prod_orale_liv!==null ?

      <FormControl sx={{ m: 1, minWidth: 190 }}>
        <InputLabel htmlFor="grouped-select4">Livello produzione orale</InputLabel>
        <Select   onChange={(e)=>props.onProdOraleChange(props.o.id, e.target.value)}
        defaultValue={prod_orale_liv} id="grouped-select4" label="Livello produzione orale">
          <ListSubheader>Utente base</ListSubheader>
          <MenuItem value={'A1'}>A1</MenuItem>
          <MenuItem value={'A2'}>A2</MenuItem>
          <ListSubheader>Utente intermedio</ListSubheader>
          <MenuItem value={'B1'}>B1</MenuItem>
          <MenuItem value={'B2'}>B2</MenuItem>
          <ListSubheader>Utente avanzato</ListSubheader>
          <MenuItem value={'C1'}>C1</MenuItem>
          <MenuItem value={'C2'}>C2</MenuItem>
        </Select>
      </FormControl> : <div></div> }
      <br/>
<h5>Produzione scritta:</h5>

{prod_scritta_liv!==null ?

<FormControl sx={{ m: 1, minWidth: 190 }}>
        <InputLabel htmlFor="grouped-select5">Livello produzione scritta</InputLabel>
        <Select         onChange={(e)=>props.onProdScrittaChange(props.o.id, e.target.value)}
 defaultValue={prod_scritta_liv} id="grouped-select5" label="Livello produzione scritta">
          <ListSubheader>Utente base</ListSubheader>
          <MenuItem value={'A1'}>A1</MenuItem>
          <MenuItem value={'A2'}>A2</MenuItem>
          <ListSubheader>Utente intermedio</ListSubheader>
          <MenuItem value={'B1'}>B1</MenuItem>
          <MenuItem value={'B2'}>B2</MenuItem>
          <ListSubheader>Utente avanzato</ListSubheader>
          <MenuItem value={'C1'}>C1</MenuItem>
          <MenuItem value={'C2'}>C2</MenuItem>
        </Select>
      </FormControl> : <div></div> }


        { (certificazione !== null || props.o.dummy) ? (
          <div className="mui-rte-holder"><h6>Eventuale certificiazione conseguita:</h6>
         <Box sx={{ width: '75%' }}>
                <TextField
        required
        style ={{width: '100%'}}
        multiline
        onChange={(e)=>props.onCertificazioneChange(props.o.id, e.target.value, e.target.value)}
        name="dettagli"
        label="Inserisci qui il titolo dell'eventuale certificazione"
        defaultValue={certificazione}
      />
      </Box>
<br/>
          <br/>
          </div>
        ) : (
          <div></div>
        )}
    </Box>
  );
}

export default CVUpdateLingueForm;
